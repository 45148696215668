import axios from "axios";

// const request = require('request-promise');
import config from "./apiconfig";
const { User } = require("../model/user").default;
const SHA224 = require("sha224");
const cors = require("cors");
cors({ origin: true });
const apiURL = window.location.origin.includes("3000")
  ? window.location.origin.replace("3000", "3001")
  : "https://photo-dev-app.onrender.com";
// const apiURL =
//   "https://us-east-2.aws.data.mongodb-api.com/app/data-rowzy/endpoint/data/v1/action/";

async function signup(name, email, phone, password, usertype) {
  var user = new User({
    name: name,
    email: email,
    phone: phone,
    password: password,
    usertype: usertype,
  });

  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/signup/`, {
        name: user.name,
        email: user.email,
        phone: user.phone,
        password: SHA224(user.password).toString("hex"),
        usertype: user.usertype,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function login(email, password) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/login/`, {
        email: email,
        password: SHA224(password).toString("hex"),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function validsignup(email, password) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/validsignup/`, {
        email: email,
        password: SHA224(password).toString("hex"),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function forgetPassword(email) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/forgetPassword/`, {
        email: email,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function resetPassword(password, resetToken) {
  return new Promise((resolve, reject) => {
    axios

      .post(`${apiURL}/userExpress/resetPassword/`, {
        password: SHA224(password).toString("hex"),
        // password: password,
        token: resetToken,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function update_user(param) {
  return new Promise((resolve, reject) => {
    var request_body = {};
    request_body["user_id"] = param.user_id;

    if (param.name) {
      request_body["name"] = param.name;
    }
    if (param.email) {
      request_body["email"] = param.email;
    }
    if (param.phone) {
      request_body["phone"] = param.phone;
    }
    axios
      .put(`${apiURL}/userExpress/update_user/`, request_body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// //find user or users by id, name, email or phone number
// router.post("/find_user", jsonParser, async function (req, res, next) {
//   if (req.body.user_id) {
//     request_body["_id"] = new mongo.ObjectId(req.body.user_id);
//   }

//   //request body only have req.body.searchTerm
//   if (req.body.searchTerm) {
//     var searchTerm = req.body.searchTerm;
//     request_body = {
//       $or: [
//         { name: { $regex: searchTerm, $options: "i" } },
//         { email: { $regex: searchTerm, $options: "i" } },
//         { phone: { $regex: searchTerm, $options: "i" } },
//       ],
//     };
//   }

//   await UserModel.find(request_body)
//     .then((response) => {
//       console.log("response", response);
//       res.status(200).json(response);
//       return res;
//     })
//     .catch((err) => {
//       console.log("err", err);
//       res.status(500).json(err);
//     });
// });

function find_user(param) {
  return new Promise((resolve, reject) => {
    var request_body = {};
    if (param.user_id) {
      request_body["user_id"] = param.user_id;
    }
    if (param.searchTerm) {
      request_body["searchTerm"] = param.searchTerm;
    }

    axios
      .post(`${apiURL}/userExpress/find_user/`, request_body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// create_lab_staff_user
async function create_lab_staff_user(lab_id, name, email, phone) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/create_lab_staff_user/`, {
        lab_id: lab_id,
        name: name,
        email: email,
        phone: phone,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// find_staff_user;
async function find_staff_users(lab_id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/find_staff_users/`, {
        lab_id: lab_id,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// delete_lab_staff_user;
async function delete_lab_staff_user(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/delete_lab_staff_user/`, {
        user_id: user_id,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default {
  signup: signup,
  login: login,
  validsignup: validsignup,
  forgetPassword: forgetPassword,
  resetPassword: resetPassword,
  update_user: update_user,
  find_user: find_user,
  create_lab_staff_user: create_lab_staff_user,
  find_staff_users: find_staff_users,
  delete_lab_staff_user: delete_lab_staff_user,
};
