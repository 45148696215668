import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveBarCanvas } from "@nivo/bar";
import Loading from "../../general/loading/loading";
import order_api from "../../../api/order_api";
import globalStore from "../../../store/globalStore";
import item_api from "../../../api/item_api";
import { useNavigate } from "react-router-dom";

export default function Ownerstatus() {
  const navigate = useNavigate();
  const [dataTypes, setDataTypes] = useState(["By Chemical", "By Service"]);
  const [currentDataType, setCurrentDataType] = useState("By Service");
  const [dataTypesKeys, setDataTypesKeys] = useState([
    ["Develope", "Scan", "Print"],
  ]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState(7);

  const datas = [
    {
      Date: "Oct 01",
      c41: 184,
      c41Color: "hsl(163, 70%, 50%)",
      e6: 52,
      e6Color: "hsl(55, 70%, 50%)",
      bw: 175,
      bwColor: "hsl(258, 70%, 50%)",
    },
    {
      Date: "Oct 02",
      c41: 70,
      c41Color: "hsl(163, 70%, 50%)",
      e6: 32,
      e6Color: "hsl(55, 70%, 50%)",
      bw: 124,
      bwColor: "hsl(258, 70%, 50%)",
    },

    {
      Date: "Oct 03",
      c41: 91,
      c41Color: "hsl(163, 70%, 50%)",
      e6: 83,
      e6Color: "hsl(55, 70%, 50%)",
      bw: 136,
      bwColor: "hsl(258, 70%, 50%)",
    },
    {
      Date: "Oct 04",
      c41: 184,
      c41Color: "hsl(163, 70%, 50%)",
      e6: 52,
      e6Color: "hsl(55, 70%, 50%)",
      bw: 175,
      bwColor: "hsl(258, 70%, 50%)",
    },
    {
      Date: "Oct 05",
      c41: 70,
      c41Color: "hsl(163, 70%, 50%)",
      e6: 32,
      e6Color: "hsl(55, 70%, 50%)",
      bw: 124,
      bwColor: "hsl(258, 70%, 50%)",
    },

    {
      Date: "Oct 06",
      c41: 91,
      c41Color: "hsl(163, 70%, 50%)",
      e6: 83,
      e6Color: "hsl(55, 70%, 50%)",
      bw: 136,
      bwColor: "hsl(258, 70%, 50%)",
    },
    {
      Date: "Oct 07",
      c41: 184,
      c41Color: "hsl(163, 70%, 50%)",
      e6: 52,
      e6Color: "hsl(55, 70%, 50%)",
      bw: 175,
      bwColor: "hsl(258, 70%, 50%)",
    },
    {
      Date: "Oct 08",
      c41: 70,
      c41Color: "hsl(163, 70%, 50%)",
      e6: 32,
      e6Color: "hsl(55, 70%,  50%)",
      bw: 124,
      bwColor: "hsl(258, 70%, 50%)",
    },
  ];

  useEffect(() => {
    if (isLoading) {
      if (currentDataType === "By Chemical") {
        item_api
          .GetItems(globalStore.getState().session.user.lab_id, "develope")
          .then((res) => {
            console.log("res", res);
            var chemicals = [];
            res.map((item) => {
              // if (
              //   item.chamical.includes("black") ||
              //   item.chamical.includes("Black")
              // ) {
              //   item.chamical = "B&W";
              // }
              chemicals.push(item.chamical);
            });
            var tempDataTypesKeys = dataTypesKeys;
            tempDataTypesKeys.push(chemicals);
            tempDataTypesKeys.map((item, index) => {
              item.map((item2, index2) => {
                if (
                  item2.includes("black") ||
                  item2.includes("Black") ||
                  item2.includes("B&W")
                ) {
                  item[index2] = "B&W";
                }
              });
            });
            console.log("tempDataTypesKeys", tempDataTypesKeys);
            setDataTypesKeys(tempDataTypesKeys);

            order_api
              .RevenueBarChartData(
                globalStore.getState().session.user.lab_id,
                currentDataType,
                dateRange,
                chemicals
              )
              .then((res) => {
                console.log("res", res.data);
                // setData(res.data);
                // loop through data and change date format to "Oct 01"
                let tempData = [];
                res.data.forEach((element) => {
                  let tempElement = {};
                  // tempElement.Date = element.Date.slice(5, 10);
                  //month in string
                  let month = element.Date.slice(5, 7);
                  let day = element.Date.slice(8, 10);
                  let monthString = "";
                  switch (month) {
                    case "01":
                      monthString = "Jan";
                      break;
                    case "02":
                      monthString = "Feb";
                      break;
                    case "03":
                      monthString = "Mar";
                      break;
                    case "04":
                      monthString = "Apr";
                      break;
                    case "05":
                      monthString = "May";
                      break;
                    case "06":
                      monthString = "Jun";
                      break;
                    case "07":
                      monthString = "Jul";
                      break;
                    case "08":
                      monthString = "Aug";
                      break;
                    case "09":
                      monthString = "Sep";
                      break;
                    case "10":
                      monthString = "Oct";
                      break;
                    case "11":
                      monthString = "Nov";
                      break;
                    case "12":
                      monthString = "Dec";
                      break;
                    default:
                      break;
                  }
                  tempElement.Date = monthString + " " + day;

                  tempElement.c41 = element.c41;
                  tempElement.e6 = element.e6;
                  tempElement.bw = element.bw;
                  tempData.push(tempElement);
                });
                setData(tempData);

                setIsLoading(false);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        order_api
          .RevenueBarChartData(
            globalStore.getState().session.user.lab_id,
            currentDataType,
            dateRange
          )
          .then((res) => {
            console.log("res", res.data);
            // setData(res.data);
            // loop through data and change date format to "Oct 01"
            let tempData = [];
            res.data.forEach((element) => {
              let tempElement = {};
              // tempElement.Date = element.Date.slice(5, 10);
              //month in string
              let month = element.Date.slice(5, 7);
              let day = element.Date.slice(8, 10);
              let monthString = "";
              switch (month) {
                case "01":
                  monthString = "Jan";
                  break;
                case "02":
                  monthString = "Feb";
                  break;
                case "03":
                  monthString = "Mar";
                  break;
                case "04":
                  monthString = "Apr";
                  break;
                case "05":
                  monthString = "May";
                  break;
                case "06":
                  monthString = "Jun";
                  break;
                case "07":
                  monthString = "Jul";
                  break;
                case "08":
                  monthString = "Aug";
                  break;
                case "09":
                  monthString = "Sep";
                  break;
                case "10":
                  monthString = "Oct";
                  break;
                case "11":
                  monthString = "Nov";
                  break;
                case "12":
                  monthString = "Dec";
                  break;
                default:
                  break;
              }
              tempElement.Date = monthString + " " + day;

              tempElement.Develope = element.Develope;
              tempElement.Scan = element.Scan;
              tempElement.Print = element.Print;
              tempData.push(tempElement);
            });
            setData(tempData);

            setIsLoading(false);
          });
      }
    }
  }, [isLoading, dataTypesKeys]);

  const MyResponsiveBar = ({ data /* see data tab */ }) => (
    <ResponsiveBar
      data={data}
      keys={
        currentDataType === dataTypes[1] ? dataTypesKeys[0] : dataTypesKeys[1]
      }
      indexBy="Date"
      margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
      padding={0.3}
      layout="vertical"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      // colors={{ scheme: "nivo" }}
      colors={{ scheme: "set2" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      // fill={[
      //   {
      //     match: {
      //       id: "",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "sandwich",
      //     },
      //     id: "lines",
      //   },
      // ]}
      borderRadius={3}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Date",
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Revenue",
        legendPosition: "middle",
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      // legends={[
      //   {
      //     dataFrom: "keys",
      //     anchor: "bottom-right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 120,
      //     translateY: 0,
      //     itemsSpacing: 2,
      //     itemWidth: 100,
      //     itemHeight: 20,
      //     itemDirection: "left-to-right",
      //     itemOpacity: 0.85,
      //     symbolSize: 20,
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemOpacity: 1,
      //         },
      //       },
      //     ],
      //   },
      // ]}
      role="application"
      ariaLabel="Weekly Revenue Information"
      barAriaLabel={(e) =>
        e.indexValue +
        " " +
        e.id +
        " " +
        e.value +
        " " +
        "Revenue" +
        " " +
        "Date" +
        " " +
        e.data.Date
      }
      legends={[
        {
          dataFrom: "keys",
          anchor: "top",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: -28,
          itemWidth: 163,
          itemHeight: 10,
          itemsSpacing: 2,
          symbolSize: 20,
          itemDirection: "left-to-right",
        },
      ]}
      // enableGridY
    />
  );
  return (
    <div className="h-72 text-dsblue-100">
      {/* choose date range */}
      <div className="flex justify-between">
        <div className="flex gap-2 justify-start items-center">
          <p className="text-dsblue-100 text-lg font-bold">Revenue</p>
          <p className="text-dsblue-100 text-lg font-bold">{currentDataType}</p>
          {/* make select for currentDataType */}
          {/* <select
            className="border border-dsblue-100 rounded-md px-2 py-1"
            onChange={(e) => {
              setCurrentDataType(e.target.value);
              setIsLoading(true);
            }}
            value={currentDataType}
          >
            {dataTypes.map((dataType) => (
              <option value={dataType}>{dataType}</option>
            ))}
          </select> */}
        </div>
        <div className="flex gap-3 justify-end items-center">
          <p className="text-dsblue-100 text-lg font-bold">Date Range</p>
          <select
            className="border border-dsblue-100 rounded-md px-2 py-1"
            onChange={(e) => {
              setDateRange(e.target.value);
              setIsLoading(true);
            }}
          >
            <option value={7}>Last 7 Days</option>
            <option value={14}>Last 14 Days</option>
            <option value={30}>Last 30 Days</option>
          </select>
          {/* go to status page button */}
          {/* <button
            className="hover:bg-dsblue-100 rounded-lg px-2 py-1 hover:text-white flex justify-center items-center gap-1 border border-dsblue-100 text-dsblue-100"
            onClick={() => {
              navigate("/status");
            }}
          >
            More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
              />
            </svg>
          </button> */}
        </div>
      </div>
      {isLoading ? <Loading></Loading> : <MyResponsiveBar data={data} />}
    </div>
  );
}
