import React, { useEffect, useState, Fragment } from "react";
import CustomerLayout from "../../component/costumer/customerLayout";
import OrderForm from "../customer/ExpertOrderForm/ExpertOrderForm";
import newbie from "../../asset/img/newbie.png";
import filmRoll from "../../asset/img/filmRoll.png";
import camera from "../../asset/img/camera.png";
import { useNavigate } from "react-router-dom";
import OrderHistoryTable from "../../component/costumer/orderHistoryTable/orderHistoryTable";
import ShopOrderHistoryTable from "../../component/shop/orderHistoryTable/orderHistoryTable";
import globalStore from "../../store/globalStore";
import ShopLayout from "../../component/shop/shopLayout";
import config from "../../config";
import shop_api from "../../api/shop_api";
import { CircularProgress } from "@mui/material";
import Loading from "../../component/general/loading/loading";
import Ownerstatus from "../../component/shop/ownerstatus/ownerstatus";

export default function Dashboard() {
  // const [shops, setShops] = useState([]);
  const [currentShop, setCurrentShop] = useState({});

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      isLoading &&
      !config.usertype.customer.includes(
        globalStore.getState().session.user.usertype
      )
    ) {
      //get shop info
      shop_api
        .GetShopById(globalStore.getState().session.user.lab_id)
        .then((res) => {
          // console.log("res", res);
          setCurrentShop(res);
          setIsLoading(false);
        });
    } else if (
      isLoading &&
      config.usertype.customer.includes(
        globalStore.getState().session.user.usertype
      )
    ) {
      setIsLoading(false);
    }
  }, []);

  const beginnerBenefits = [
    "Fast and easy",
    "Preset order form",
    "Click and go",
  ];
  const expertBenefits = [
    "More film options",
    "Push or Pull",
    "Higher resolution",
  ];

  const Card = (level) => {
    return (
      <button
        className="bg-white rounded-lg border border-dsblue-40 shadow-sm flex flex-col  justify-center pb-8 hover:scale-105"
        onClick={() => {
          // navigator to order form page
          navigate("/" + level + "Order");
        }}
      >
        <p className="p-8 text-center text-dsblue-90">
          {level === "beginner" ? "Beginner Start" : "Expert Start"}
        </p>
        <div className="grid grid-cols-3 justify-center">
          <div className="flex justify-center">
            <img
              className="w-1/2 object-contain"
              src={level === "beginner" ? newbie : camera}
              alt="dev"
            ></img>
          </div>
          <div className=" col-span-2">
            {level === "beginner"
              ? beginnerBenefits.map((benefit) => (
                  <div className="flex gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <p className="text-sm text-dsblue-80">{benefit}</p>
                  </div>
                ))
              : expertBenefits.map((benefit) => (
                  <div className="flex gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <p className="text-sm text-dsblue-80">{benefit}</p>
                  </div>
                ))}
          </div>
        </div>
      </button>
    );
  };

  const customerView = () => {
    return (
      <CustomerLayout describtion="dashboard">
        {isLoading ? (
          <div>
            <Loading></Loading>
          </div>
        ) : (
          <div className=" flex flex-col gap-3">
            {/* <OrderForm></OrderForm> */}
            <div className="bg-white rounded-lg shadow-lg col-span-2 px-4 md:px-8 pt-2 md:pt-4 pb-4 md:pb-8">
              <p className="pb-8 text-dsblue-90">Order Form</p>
              <div className="grid grid-cols-1 md:grid-cols-2  gap-4 md:gap-8">
                {Card("beginner")}
                {Card("expert")}
              </div>
            </div>
            <OrderHistoryTable></OrderHistoryTable>
          </div>
        )}
      </CustomerLayout>
    );
  };

  const shopView = () => {
    return (
      <ShopLayout describtion="dashboard">
        {isLoading ? (
          <div>
            <Loading></Loading>
          </div>
        ) : (
          <div className=" flex flex-col gap-3">
            {/* <OrderForm></OrderForm> */}

            <div className="bg-white rounded-lg shadow-lg  px-4 md:px-8 pt-2 md:pt-4 pb-4 md:pb-8">
              <p className="text-center py-4 font-Gilroy-m text-lg">
                {currentShop.shopName}
              </p>
              <button
                className="bg-green-300 rounded-lg w-full"
                onClick={() => {
                  // navigator to order form page
                  navigate("/expertOrder");
                }}
              >
                {/* make order  */}
                <p className="py-4 text-white text-center">New Order +</p>
              </button>
            </div>
            <div className="bg-white rounded-lg shadow-lg  px-4 md:px-8 pt-2 md:pt-4 pb-4 md:pb-8">
              <Ownerstatus></Ownerstatus>
            </div>
            <ShopOrderHistoryTable></ShopOrderHistoryTable>
          </div>
        )}
      </ShopLayout>
    );
  };
  return (
    <div className="bg-white">
      <div>
        {config.usertype.customer.includes(
          globalStore.getState().session.user.usertype
        )
          ? customerView()
          : shopView()}
      </div>
    </div>
  );
}
