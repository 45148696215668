import React, { useEffect, useState, Fragment } from "react";
import order_api from "../../../api/order_api";
import globalStore from "../../../store/globalStore";
import { useNavigate } from "react-router-dom";
import config from "../../../config";

export default function OrderHistoryTable() {
  const [orderHistory, setOrderHistory] = useState([]);
  const [sort, setSort] = useState("descending");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //refresh order history
    if (isLoading) {
      order_api
        .OrderHistoryWithPagination(
          // globalStore.getState().user._id,
          globalStore.getState().session.user._id,
          sort,
          skip,
          limit
        )
        .then((res) => {
          setOrderHistory(res);
          setIsLoading(false);
          // globalStore.dispatch({
          //   type: "SET_ORDER_HISTORY",
          //   payload: res,
          // });
        });
    }
  }, [orderHistory]);

  const countOrderTotalPrice = (order) => {
    let total = 0;
    order.forEach((item) => {
      total += item.Price.totalPrice;
    });
    return total;
  };
  const getDate = (date) => {
    // 2023-08-07T16:23:08.710Z
    // need 08-07
    let month = date.slice(5, 7);
    let day = date.slice(8, 10);
    return month + "-" + day;
  };

  // const modal = (order) => {
  //   const [open, setOpen] = useState(false);
  //   const cancelButtonRef = useRef(null);
  //   return (
  //     <Transition.Root show={open} as={Fragment}>
  //       <Dialog
  //         as="div"
  //         className="relative z-30"
  //         initialFocus={cancelButtonRef}
  //         onClose={setOpen}
  //       >
  //         <Transition.Child
  //           as={Fragment}
  //           enter="ease-out duration-300"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           leave="ease-in duration-200"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0"
  //         >
  //           <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  //         </Transition.Child>

  //         <div className="fixed z-100 inset-0 overflow-y-auto">
  //           <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
  //             <Transition.Child
  //               as={Fragment}
  //               enter="ease-out duration-300"
  //               enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //               enterTo="opacity-100 translate-y-0 sm:scale-100"
  //               leave="ease-in duration-200"
  //               leaveFrom="opacity-100 translate-y-0 sm:scale-100"
  //               leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //             >
  //               <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
  //                 <div className="bg-white px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90">
  //                   <p className="text-xl font-Gilroy-m pt-8">
  //                     Choose your location to drop off your film!
  //                   </p>
  //                   <div className="grid grid-cols-1 sm:grid-cos-2 gap-3 py-8">
  //                     <button
  //                       className="p-4 border border-dsblue-90 rounded-xl"
  //                       onClick={() => {
  //                         setCurrentShop(item);
  //                         setOpen(false);
  //                       }}
  //                     >
  //                       <p className=" font-Gilroy-m py-2 text-lg">
  //                         {item.shopName}
  //                       </p>

  //                       <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-2">
  //                         <div>
  //                           <div className="">
  //                             <p className="text-left  pb-1">Address:</p>{" "}
  //                             <p className="text-sm py-2 text-left text-dsblue-60">
  //                               {item.shopAddress}
  //                             </p>
  //                           </div>
  //                           <div>
  //                             <p className="text-left pb-1">Phone:</p>
  //                             <div className="flex flex-col justify-start items-start text-dsblue-60">
  //                               {item.shopPhones?.map((phone) => {
  //                                 return <p>{phone}</p>;
  //                               })}
  //                             </div>
  //                           </div>
  //                         </div>
  //                         <div>
  //                           <div className="flex flex-col justify-start items-start text-dsblue-60 sm:pl-3">
  //                             {item.shopHours?.map((hour) => {
  //                               return (
  //                                 <div>
  //                                   {hour.date +
  //                                     ": " +
  //                                     hour.startTime +
  //                                     "-" +
  //                                     hour.endTime}
  //                                 </div>
  //                               );
  //                             })}
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 </div>
  //               </Dialog.Panel>
  //             </Transition.Child>
  //           </div>
  //         </div>
  //       </Dialog>
  //     </Transition.Root>
  //   );
  // };

  const statusBar = (status) => {
    var style = "";
    if (config.orderStatus[0] === status) {
      style =
        "border border-green-500 rounded-lg px-1 text-center text-green-500";
    } else if (config.orderStatus[1] === status) {
      style =
        "border border-yellow-800 rounded-lg px-1 text-center text-yellow-800 ";
    } else if (config.orderStatus[2] === status) {
      style =
        "border border-blue-500 rounded-lg px-1 text-center text-blue-500 ";
    } else if (config.orderStatus[3] === status) {
      style =
        "border border-purple-500 rounded-lg px-1 text-center text-purple-500  ";
    } else if (config.orderStatus[4] === status) {
      style =
        "border border-red-500 rounded-lg px-1 text-center text-red-500  ";
    } else {
      style =
        "border border-green-500 rounded-lg px-1 text-center text-green-500 ";
    }
    return (
      <div className={style}>
        <p className=" text-center ">{status}</p>
      </div>
    );
  };
  return (
    <div className="bg-white rounded-lg shadow-lg col-span-2  p-4 md:p-8 ">
      <div className="flex justify-between items-center pb-3">
        <p className=" text-dsblue-90">Order History</p>
        <button
          className="px-2 py-1 rounded-lg border border-dsblue-100 text-dsblue-100 hover:bg-dsblue-100 hover:text-white flex justify-center items-center"
          onClick={() => {
            //navigate to order history page

            navigate("/orderHistory");
          }}
        >
          <p>All History</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </button>
      </div>
      {/* {globalStore.getState().orderHistory.length} */}
      <table className="table-auto w-full">
        <thead className="bg-gray-50 rounded-lg pb-2 uppercase ">
          <tr className="">
            <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-left">
              Date
            </th>
            <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-left ">
              Status
            </th>
            <th className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm md:text-lg text-center">
              <p className="">Rolls </p>
            </th>
            <th className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm md:text-lg text-center">
              <p className="">Price</p>
            </th>
            {/* <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-center"></th> */}
            <th className="text-dsblue-90 md:px-4 text-xs sm:text-sm md:text-lg text-center ">
              <p className="hidden md:block">Lab</p>
            </th>

            <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-center"></th>
          </tr>
        </thead>
        <tbody>
          {orderHistory.data?.map((order) => (
            <tr className=" border-b ">
              <td className="text-dsblue-60 px-1 md:px-4 text-xs sm:text-sm  py-2">
                <p>{order.orderDate.split("-")[0]}</p>
                <p>{getDate(order.orderDate)}</p>
              </td>
              <td className="text-dsblue-60 px-1 md:px-4 text-xs sm:text-sm  py-2 ">
                <div className="">{statusBar(order.currentStatus)}</div>
              </td>
              {/* <td className="text-dsblue-60 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <p className=" text-center ">{order.order.length}</p>
              </td> */}
              <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <p className=" text-center">{order.order.length}</p>
              </td>{" "}
              <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <p className=" text-center">
                  {"$" + countOrderTotalPrice(order.order)}
                </p>
              </td>{" "}
              <td className="text-dsblue-60 md:px-4 text-xs sm:text-sm  py-2 text-center h-full">
                <p className=" text-center hidden md:block">
                  {order.store.shopName}
                </p>
              </td>
              <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <button
                  onClick={() => {
                    navigate("/orderHistory/" + order._id);
                  }}
                  className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white"
                >
                  <p className=" text-center">View</p>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
