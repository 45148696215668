var btn = "text-dsblue-60 flex-row";
var menuAdaptive =
  "grid grid-row-3 gap-3 md:gap-8 xl:gap-10 justify-center  pt-8 lg:pt-12   focus:bg-none";
// "flex  flex-col justify-center  pt-24 my-auto   gap-8 ";

let styles = {
  btn: btn,
  menuAdaptive: menuAdaptive,
};

module.exports = styles;
