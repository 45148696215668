import axios from "axios";
const cors = require("cors");
cors({ origin: true });
const apiURL = window.location.origin.includes("3000")
  ? window.location.origin.replace("3000", "3001")
  : "https://photo-dev-app.onrender.com";
// const apiURL =
//   "https://us-east-2.aws.data.mongodb-api.com/app/data-rowzy/endpoint/data/v1/action/";

async function GetItems(shopId, name) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/itemExpress/GetItems/`, {
        shopId: shopId,
        name: name,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// curl --location 'localhost:3001/itemExpress/deleteItem/' \
// --header 'Content-Type: application/json' \
// --data '{
//     "shopId": "644e7ebedc63924e5a3afbf6",
//     "name": "scan",
//     "_id": "654978995a6abf6c058ef1fc"
// }'
async function DeleteItem(shopId, name, _id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/itemExpress/deleteItem/`, {
        shopId: shopId,
        name: name,
        _id: _id,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// UpdateItems;
// curl --location 'localhost:3001/itemExpress/UpdateItems/' \
// --header 'Content-Type: application/json' \
// --data '{
//     "shopId": "644e7ebedc63924e5a3afbf6",

//     "name": "print",
//     "items": [
//     ]
// }'

async function UpdateItems(shopId, name, items) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/itemExpress/UpdateItems/`, {
        shopId: shopId,
        name: name,
        items: items,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default {
  GetItems: GetItems,
  DeleteItem: DeleteItem,
  UpdateItems: UpdateItems,
};
 