import React, { Fragment, useEffect, useState, useRef } from "react";
import ShopLayout from "../../../component/shop/shopLayout";
import shop_api from "../../../api/shop_api";
import globalStore from "../../../store/globalStore";
import Tooltip from "@mui/material/Tooltip";
import { usertype } from "../../../config";
import user_api from "../../../api/user_api";
import { Dialog, Transition, Switch } from "@headlessui/react";

export default function LabDetail() {
  const [shop, setShop] = useState({}); //
  const [isLoading, setIsLoading] = useState(false); //
  const [isEdditing, setIsEdditing] = useState(false);
  const [labs, setLabs] = useState([]);
  const [ownersLabs, setOwnersLabs] = useState([]);
  const [currentLab, setCurrentLab] = useState({});
  const [shop_id, setShop_id] = useState(
    usertype.labAdmin[1] === globalStore.getState().session.user.usertype ||
      usertype.lab[0] === globalStore.getState().session.user.usertype
      ? globalStore.getState().session.user.lab_id
      : ""
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [message, setMessage] = useState({
    mode: "",
    msg: "",
  });
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentStaff, setCurrentStaff] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [inputError, setInputError] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };
  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          //check if value is email or not
          if (!value) {
            stateObj[name] = "Please enter Email.";
          } else if (!value.includes("@" && ".")) {
            stateObj[name] = "Please enter a valid Email.";
          }
          break;
        case "name":
          if (!value) {
            stateObj[name] = "Please enter Name.";
          }
          break;
        case "phone":
          if (!value) {
            stateObj[name] = "Please enter Phone.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  useEffect(() => {
    if (shop_id !== "") {
      shop_api
        .GetShopById(shop_id)
        .then((res) => {
          //console.log("res", res);
          setIsLoading(true);
          setShop(res);
        })
        .catch((err) => {
          //console.log("err", err);
        });
    }
  }, [isEdditing]);

  useEffect(() => {
    if (
      usertype.labAdmin.includes(globalStore.getState().session.user.usertype)
    ) {
      user_api
        .find_staff_users(shop_id)
        .then((res) => {
          setStaffs(res);
        })
        .catch((err) => {
          //console.log("err", err);
        });
    }
  }, [shop_id]);

  const msgFunction = () => {
    // setTimeout(() => {
    //   setMessage({
    //     mode: mode,
    //     msg: msg,
    //   });
    // }, 3000);
    var style = "";
    if (message.mode === "success") {
      style = "bg-green-500";
    }
    if (message.mode === "error") {
      style = "bg-red-500";
    }
    if (message.mode === "warning") {
      style = "bg-yellow-500";
    }
    if (message.mode === "info") {
      style = "bg-blue-500";
    }
    return (
      <Transition
        show={message.msg !== ""}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={
            " absolute z-20 right-16 top-16 px-4 py-2 rounded-xl text-white " +
            style
          }
        >
          {message.msg}
        </div>
      </Transition>
    );
  };
  //   //console.log("shop", shop);
  const SearchSection = () => {
    const handleSearchOnChange = (e) => {
      setSearchTerm(e.target.value);
    };
    const handleSearch = (e) => {
      setSearchTerm("");

      shop_api
        .FindShop(searchTerm)
        .then((res) => {
          setLabs(res);
        })
        .catch((err) => {
          //console.log(err);
        });
    };
    const listOfLabs = () => {
      // return ();
      return (
        <div className="flex flex-col gap-3">
          {labs.map((item) => {
            return (
              <button
                className="p-4 border border-dsblue-90 rounded-xl"
                onClick={() => {
                  setShop_id(item._id);
                  setShop(item);
                  setLabs([]);
                }}
              >
                <p className=" font-Gilroy-m py-2 text-lg">{item.shopName}</p>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-2">
                  <div>
                    <div className="">
                      <p className="text-left  pb-1">Address:</p>{" "}
                      <p className="text-sm py-2 text-left text-dsblue-60">
                        {item.shopAddress}
                      </p>
                    </div>
                    <div>
                      <p className="text-left pb-1">Phone:</p>
                      <div className="flex flex-col justify-start items-start text-dsblue-60">
                        {item.shopPhones?.map((phone) => {
                          return <p>{phone}</p>;
                        })}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col justify-start items-start text-dsblue-60 sm:pl-3">
                      {item.shopHours?.map((hour) => {
                        return (
                          <div>
                            {hour.date +
                              ": " +
                              hour.startTime +
                              "-" +
                              hour.endTime}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      );
    };

    return (
      <div className=" flex flex-col gap-3">
        <div>
          <p className="text-2xl font-Gilroy-m text-dsblue-100">
            Find a lab to see detail.
          </p>
        </div>
        <div className="flex justify-start items-center gap-3">
          <Tooltip title="Lab name , phone, address">
            <input
              className="border border-dsblue-90 rounded-md px-2 py-1"
              type="text"
              value={searchTerm}
              placeholder="Search for lab"
              onChange={(e) => {
                handleSearchOnChange(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(e);
                }
              }}
            ></input>
          </Tooltip>
          <button
            className={
              searchTerm === ""
                ? "hidden"
                : "bg-dsblue-90 text-white px-3 py-1 rounded-md"
            }
            onClick={() => {
              handleSearch();
            }}
            disabled={searchTerm === ""}
          >
            Search
          </button>
        </div>
        {shop_id === "" || searchTerm !== "" ? <div>{listOfLabs()}</div> : null}
      </div>
    );
  };

  const modal = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                <div className="bg-white px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90">
                  {/* field for name, email, and phone. need "create" and "cancel" button */}
                  <div className="flex flex-col justify-center items-center gap-1">
                    <div className="flex justify-between items-center w-full">
                      <button
                        className=" rounded-lg bg-dsblue-20 text-white px-2 py-1 hover:bg-dsblue-100 text-xs"
                        onClick={() => {
                          setOpen(false);
                          setCurrentStaff({
                            name: "",
                            email: "",
                            phone: "",
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                      <p>Create New Staff</p>
                      <button
                        className=" rounded-lg bg-dsblue-20 text-white px-2 py-1 hover:bg-dsblue-100 "
                        onClick={() => {
                          user_api
                            .create_lab_staff_user(
                              shop_id,
                              currentStaff.name,
                              currentStaff.email,
                              currentStaff.phone
                            )
                            .then((res) => {
                              //console.log("res", res);
                              if (res.message) {
                                // msgFunction(res.message, "error");
                                setMessage({
                                  mode: "error",
                                  msg: res.message,
                                });
                                setOpen(false);

                                setTimeout(() => {
                                  setMessage({
                                    mode: "",
                                    msg: "",
                                  });
                                }, 3000);
                              } else {
                                setOpen(false);
                                user_api
                                  .find_staff_users(
                                    globalStore.getState().session.user.lab_id
                                  )
                                  .then((res) => {
                                    setStaffs(res);
                                    setMessage({
                                      mode: "success",
                                      msg: "Staff created",
                                    });
                                    setTimeout(() => {
                                      setMessage({
                                        mode: "",
                                        msg: "",
                                      });
                                    }, 3000);
                                  });
                              }
                              setCurrentStaff({
                                name: "",
                                email: "",
                                phone: "",
                              });
                            });
                        }}
                      >
                        Create
                      </button>
                    </div>

                    <div className="flex justify-center items-center gap-3 w-full pt-8">
                      <div className="flex justify-between items-center gap-3  w-full divide-x-1">
                        <div className="w-full">
                          {" "}
                          <p className="text-left pb-1">Name:</p>
                          <input
                            type="name"
                            name="name"
                            className="text-sm p-2 text-left text-dsblue-60 w-full border border-dsblue-60 rounded-lg"
                            value={currentStaff.name}
                            onBlur={validateInput}
                            onChange={(e) => {
                              setCurrentStaff({
                                ...currentStaff,
                                name: e.target.value,
                              });
                            }}
                          ></input>
                          <div
                            className={
                              " text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                            }
                          >
                            {inputError.name && (
                              <span className="">{inputError.name}</span>
                            )}
                          </div>
                        </div>

                        <div className="w-full pl-3">
                          <p className="text-left pb-1">Email:</p>
                          <input
                            type="email"
                            name="email"
                            className="text-sm p-2 text-left text-dsblue-60 w-full border border-dsblue-60 rounded-lg"
                            value={currentStaff.email}
                            onBlur={validateInput}
                            onChange={(e) => {
                              setCurrentStaff({
                                ...currentStaff,
                                email: e.target.value,
                              });
                            }}
                          ></input>
                          <div
                            className={
                              " text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                            }
                          >
                            {inputError.email && (
                              <span className="">{inputError.email}</span>
                            )}
                          </div>
                        </div>

                        <div className="w-full pl-3">
                          <p className="text-left pb-1">Phone:</p>
                          <input
                            type="phone"
                            name="phone"
                            className="text-sm p-2 text-left text-dsblue-60 w-full border border-dsblue-60 rounded-lg"
                            value={currentStaff.phone}
                            onBlur={validateInput}
                            onChange={(e) => {
                              setCurrentStaff({
                                ...currentStaff,
                                phone: e.target.value,
                              });
                            }}
                          ></input>
                          <div
                            className={
                              " text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                            }
                          >
                            {inputError.phone && (
                              <span className="">{inputError.phone}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  return (
    <ShopLayout describtion="labDetail">
      <div className="flex flex-wrap flex-col gap-3">
        {globalStore.getState().session.user.usertype ===
        usertype.labAdmin[0] ? (
          <div>{SearchSection()}</div>
        ) : null}
        {shop_id ? (
          <div className="p-4 border border-dsblue-90 rounded-xl ">
            <div className=" flex justify-between items-start">
              <p className=" font-Gilroy-m py-2 text-lg text-dsblue-100">
                {shop.shopName}
              </p>
              {isEdditing ? (
                <div className="flex justify-center items-center gap-3">
                  <button
                    className=" rounded-lg bg-dsblue-20 text-white px-2 py-1 hover:bg-dsblue-100"
                    onClick={() => {
                      setIsEdditing(false);
                      shop_api.UpdateShop(shop._id, shop).then((res) => {
                        //console.log("res", res);
                        setShop(res);
                      });
                    }}
                  >
                    Save
                  </button>
                  <button
                    className=" rounded-lg bg-dsblue-20 text-white px-2 py-1 hover:bg-dsblue-100"
                    onClick={() => {
                      setIsEdditing(false);
                      shop_api
                        .GetShopById(globalStore.getState().session.user.lab_id)
                        .then((res) => {
                          //console.log("res", res);
                        });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    className={
                      usertype.lab.includes(
                        globalStore.getState().session.user.usertype
                      )
                        ? "hidden"
                        : " rounded-lg bg-dsblue-20 text-white px-2 py-1 hover:bg-dsblue-100"
                    }
                    onClick={() => {
                      setIsEdditing(true);
                    }}
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-2">
              <div>
                <div className="">
                  <p className="text-left  pb-1">Address:</p>{" "}
                  <textarea
                    className={
                      isEdditing
                        ? "text-sm p-2 text-left text-dsblue-60 w-full border border-dsblue-60 rounded-lg"
                        : "text-sm p-2 text-left text-dsblue-60 w-full"
                    }
                    value={shop.shopAddress}
                    disabled={!isEdditing}
                    onChange={(e) => {
                      setShop({ ...shop, shopAddress: e.target.value });
                    }}
                  >
                    {/* {shop.shopAddress} */}
                  </textarea>
                </div>
                <div>
                  <div className="flex justify-start items-center gap-3">
                    <p className="text-left pb-1">Phone:</p>
                  </div>
                  <div className="flex flex-col justify-start items-start text-dsblue-60 gap-3 divide-y-1 ">
                    {shop.shopPhones?.map((phone, index) => {
                      return (
                        <div className="w-full pt-2">
                          <div
                            className={
                              isEdditing
                                ? "flex justify-between items-center gap-3 w-full"
                                : "flex justify-start items-center gap-3"
                            }
                          >
                            <div className="flex justify-start items-center gap-3">
                              <p className="text-sm text-dsblue-100">
                                {index + 1}.
                              </p>
                              <input
                                className="px-2"
                                value={phone}
                                disabled={!isEdditing}
                                onChange={(e) => {
                                  var tmp = shop.shopPhones;
                                  tmp[index] = e.target.value;
                                  setShop({ ...shop, shopPhones: tmp });
                                }}
                              ></input>
                            </div>
                            {isEdditing ? (
                              <button
                                className=" rounded-lg  text-red-200 px-2 border border-red-200 hover:border-red-500 hover:text-red-500 text-xs"
                                onClick={() => {
                                  var tmp = shop.shopPhones;
                                  tmp.splice(index, 1);
                                  setShop({ ...shop, shopPhones: tmp });
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}

                    {isEdditing ? (
                      <button
                        className=" rounded-lg bg-dsblue-20 text-white px-2  hover:bg-dsblue-100 text-xs"
                        onClick={() => {
                          var tmp = shop.shopPhones;
                          tmp.push("");
                          setShop({ ...shop, shopPhones: tmp });
                        }}
                      >
                        Add Phone
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col justify-start items-start text-dsblue-60 sm:pl-3">
                  {shop.shopHours?.map((hour) => {
                    return (
                      <div className="flex flex-col justify-center items-center gap-3">
                        {isEdditing ? (
                          <div className="grid grid-cols-4 gap-3 w-full">
                            <p className=" col-span-2">{hour.date}:</p>
                            <input
                              className="px-2"
                              value={hour.startTime}
                              onChange={(e) => {
                                var tmp = shop.shopHours;
                                tmp[index].startTime = e.target.value;
                                setShop({ ...shop, shopHours: tmp });
                              }}
                            ></input>
                            {/* <p> to </p> */}
                            <input
                              className="px-2"
                              value={hour.endTime}
                              onChange={(e) => {
                                var tmp = shop.shopHours;
                                tmp[index].endTime = e.target.value;
                                setShop({ ...shop, shopHours: tmp });
                              }}
                            ></input>
                          </div>
                        ) : (
                          <div>
                            {hour.date +
                              ": " +
                              hour.startTime +
                              "-" +
                              hour.endTime}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {staffs.length > 0 &&
        usertype.labAdmin.includes(
          globalStore.getState().session.user.usertype
        ) ? (
          <div>
            {" "}
            <div className="p-4 border border-dsblue-90 rounded-xl">
              <div className="flex flex-col justify-start items-start gap-3">
                <div className="flex justify-between items-center w-full border-b-2 pb-2">
                  <p className="text-left pb-1">Staffs:</p>
                  <button
                    className=" rounded-lg bg-dsblue-20 text-white px-2 py-1 hover:bg-dsblue-100 text-xs flex justify-center items-center gap-1"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-3 h-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    <p className=" hidden md:block">Create New Staff</p>
                  </button>
                </div>
                <div className="flex flex-col justify-start items-start text-dsblue-60 w-full divide-y-1">
                  {staffs.map((staff) => {
                    return (
                      <div className="w-full  py-2 flex flex-col gap-3">
                        <div className="flex justify-between items-start gap-3">
                          <div className="flex justify-start items-center gap-3">
                            <p className="text-sm text-dsblue-100">
                              Staff Name:
                            </p>
                            <p>{staff.name}</p>
                          </div>
                          <div>
                            <button
                              className=" rounded-lg bg-red-200 text-white px-2 py-1 hover:bg-red-500 text-xs"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you wish to delete this Staff?"
                                  )
                                ) {
                                  user_api
                                    .delete_lab_staff_user(staff._id)
                                    .then((res) => {
                                      //console.log("res", res);
                                      // setStaffs(res);
                                      user_api
                                        .find_staff_users(shop_id)
                                        .then((res) => {
                                          setStaffs(res);
                                          // msgFunction(
                                          //   "Staff deleted",
                                          //   "success"
                                          // );
                                          setMessage({
                                            mode: "success",
                                            msg: "Staff deleted",
                                          });
                                          setTimeout(() => {
                                            setMessage({
                                              mode: "",
                                              msg: "",
                                            });
                                          }, 3000);
                                        });
                                    });
                                }
                              }}
                            >
                              Delete this Staff
                            </button>
                          </div>
                        </div>
                        <div className="flex justify-between items-start gap-3 flex-wrap ">
                          <div className="flex justify-start items-center gap-3 flex-wrap">
                            <p className="text-sm text-dsblue-100">Email:</p>
                            <p>{staff.email}</p>
                          </div>
                          <div className="flex justify-start items-center gap-3 flex-wrap">
                            <p className="text-sm text-dsblue-100">Phone:</p>
                            <p>{staff.phone}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : shop_id !== "" &&
          usertype.labAdmin.includes(
            globalStore.getState().session.user.usertype
          ) ? (
          <div>
            <button
              className=" rounded-lg bg-dsblue-20 text-white px-2 py-1 hover:bg-dsblue-100 text-xs flex justify-center items-center gap-1"
              onClick={() => {
                setOpen(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-3 h-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              <p className=" hidden md:block">Create New Staff</p>
            </button>
          </div>
        ) : null}
        {msgFunction()}
        {modal}
      </div>
    </ShopLayout>
  );
}
