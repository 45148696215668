import React, { useEffect, useState, Fragment } from "react";
import order_api from "../../../api/order_api";
import globalStore from "../../../store/globalStore";
import { useNavigate } from "react-router-dom";
import { Menu, Transition, Disclosure } from "@headlessui/react";
import config from "../../../config";

export default function OrderHistoryTable() {
  const [orderHistory, setOrderHistory] = useState([]);
  const [sort, setSort] = useState("descending");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(9);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    from: "",
    to: "",
  });
  const [statusFilter, setStatusFilter] = useState([]);
  const [labFilter, setLabFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");

  useEffect(() => {
    //refresh order history
    if (isLoading) {
      order_api
        .OrderHistoryWithPaginationForShop(
          // globalStore.getState().user._id,
          globalStore.getState().session.user.lab_id,
          sort,
          skip,
          limit,
          statusFilter,
          dateFilter.from,
          dateFilter.to,
          userFilter
        )
        .then((res) => {
          // console.log("res", res);
          setOrderHistory(res);
          setIsLoading(false);
          // globalStore.dispatch({
          //   type: "SET_ORDER_HISTORY",
          //   payload: res,
          // });
        });
    }
  }, [orderHistory]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const countOrderTotalPrice = (order) => {
    let total = 0;
    order.forEach((item) => {
      total += item.Price.totalPrice;
    });
    return total;
  };
  const getDate = (date) => {
    // 2023-08-07T16:23:08.710Z
    // need 08-07
    let month = date.slice(5, 7);
    let day = date.slice(8, 10);
    return month + "-" + day;
  };

  const handleDropDownSelection = (order, status) => {
    //update order status
    // console.log("order", order);

    order_api.UpdateOrderStatusById(order, status).then(async (res) => {
      // console.log("res", res);
      //refresh order history
      await order_api
        .OrderHistoryWithPaginationForShop(
          // globalStore.getState().user._id,
          globalStore.getState().session.user.lab_id,
          sort,
          skip,
          limit
        )
        .then((res) => {
          // console.log("res", res);
          setOrderHistory(res);
        });
    });
  };

  const dropdown = (order) => {
    return config.orderStatus.map((sta, index) => {
      return (
        <Menu.Item>
          {({ active }) => (
            <a
              className={classNames(
                active ? " text-violet-100" : "text-dsblue-100",
                "block px-4 py-2 text-sm"
              )}
              onClick={(e) => handleDropDownSelection(order, sta)}
            >
              {sta}
            </a>
          )}
        </Menu.Item>
      );
    });
  };

  const changeStatusView = (order) => {
    const statusBar = (status) => {
      var style = "";
      if (config.orderStatus[0] === status) {
        style =
          "border border-green-500 rounded-lg px-1 text-center text-green-500";
      } else if (config.orderStatus[1] === status) {
        style =
          "border border-yellow-800 rounded-lg px-1 text-center text-yellow-800 ";
      } else if (config.orderStatus[2] === status) {
        style =
          "border border-blue-500 rounded-lg px-1 text-center text-blue-500 ";
      } else if (config.orderStatus[3] === status) {
        style =
          "border border-purple-500 rounded-lg px-1 text-center text-purple-500  ";
      } else if (config.orderStatus[4] === status) {
        style =
          "border border-red-500 rounded-lg px-1 text-center text-red-500  ";
      } else {
        style =
          "border border-green-500 rounded-lg px-1 text-center text-green-500 ";
      }
      return (
        <div className={style}>
          <p className=" text-left ">{status}</p>
        </div>
      );
    };
    return (
      <Menu as="div" className="relative  text-left w-full mt-2">
        <div className=" w-full">
          <div className=" ">
            <div className=" flex justify-start">
              <Menu.Button className="">
                <div className="">
                  {/* <p className="border border-green-500 rounded-lg px-1 text-center text-green-500">
                    {order.currentStatus}
                  </p> */}

                  <div className="">{statusBar(order.currentStatus)}</div>
                </div>
              </Menu.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left absolute z-20 left-0 mt-2 w-full rounded-md shadow-lg bg-white  ring-opacity-5 focus:outline-none overflow-y-auto">
            <div className="py-1 ">{dropdown(order)}</div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-lg col-span-2  p-4 md:p-8  overflow-y-auto h-full">
      <div className="flex justify-between items-center pb-3">
        <p className=" text-dsblue-90">Order History</p>
        <button
          className="px-2 py-1 rounded-lg border border-dsblue-100 text-dsblue-100 hover:bg-dsblue-100 hover:text-white flex justify-center items-center"
          onClick={() => {
            //navigate to order history page

            navigate("/orderHistory");
          }}
        >
          <p>All History</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </button>
      </div>
      {/* {globalStore.getState().orderHistory.length} */}
      <table className="table-auto w-full">
        <thead className="bg-gray-50 rounded-lg pb-2 uppercase ">
          <tr className="">
            <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-left">
              Date
            </th>
            <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-left ">
              Status
            </th>
            <th className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm md:text-lg text-center">
              <p className="">Rolls </p>
            </th>
            <th className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm md:text-lg text-center">
              <p className="">Price</p>
            </th>
            {/* <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-center"></th> */}
            <th className="text-dsblue-90 md:px-4 text-xs sm:text-sm md:text-lg text-center ">
              <p className="hidden md:block">Lab</p>
            </th>

            <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-center"></th>
          </tr>
        </thead>
        <tbody>
          {orderHistory.data?.map((order) => (
            <tr className=" border-b ">
              <td className="text-dsblue-60 px-1 md:px-4 text-xs sm:text-sm  py-2">
                <p>{order.orderDate.split("-")[0]}</p>
                <p>{getDate(order.orderDate)}</p>
              </td>
              <td className="text-dsblue-60 px-1 md:px-4 text-xs sm:text-sm  py-2 text-left">
                {/* <p className="border border-green-500 rounded-lg px-1 text-center text-green-500">
                  {order.currentStatus}
                </p> */}
                {changeStatusView(order)}
              </td>
              {/* <td className="text-dsblue-60 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <p className=" text-center ">{order.order.length}</p>
              </td> */}
              <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <p className=" text-center">{order.order.length}</p>
              </td>{" "}
              <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <p className=" text-center">
                  {"$" + countOrderTotalPrice(order.order)}
                </p>
              </td>{" "}
              <td className="text-dsblue-60 md:px-4 text-xs sm:text-sm  py-2 text-center h-full">
                <p className=" text-center hidden md:block">
                  {order.store.shopName}
                </p>
              </td>
              <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <button
                  onClick={() => {
                    navigate("/orderHistory/" + order._id);
                  }}
                  className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white"
                >
                  <p className=" text-center">View</p>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
