import React, { useState, useEffect, Fragment, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginPhoto from "../../asset/img/login.jpg";
import { Dialog, Transition } from "@headlessui/react";
import styles from "./StylingForUseraccess";
import accessBG from "../../asset/img/accessBG.png";
import accessBG_VERTICAL from "../../asset/img/accessBG_vertical.png";
import user_api from "../../api/user_api";

export default function Resetpassword() {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState({
    resetToken: location.pathname.split("/")[2],
    password: "",
    confirm_password: "",
  });
  const [inputError, setInputError] = useState({
    password: "",
    confirm_password: "",
  });

  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  // const [message_style, setMessage_style] = useState();

  var loadScreen = <progress class="progress max-w-sm "></progress>;

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (user.confirm_password && value !== user.confirm_password) {
            stateObj["confirm_password"] =
              "Password does not match.";
          } else if (value.length < 8) {
            stateObj[name] = "Must be at least 8 characters.";
          } else if (value.length > 20) {
            stateObj[name] = "Must be less than 20 characters.";
          } //have capital letter, number and special character
          else if (!/[A-Z]/.test(value)) {
            stateObj[name] =
              "Must contain at least one capital letter.";
          } else if (!/[0-9]/.test(value)) {
            stateObj[name] = "Must contain at least one number.";
          } else {
            stateObj["confirm_password"] = user.confirm_password
              ? ""
              : inputError.confirm_password;
          }
          break;

        case "confirm_password":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (user.password && value !== user.password) {
            stateObj[name] = "Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  //   console.log(window.location.href.split("/").slice(0, 3).join("/"));
  const handleSubmit = async (event) => {
    event.preventDefault();
    user_api.resetPassword(user.password, user.resetToken).then((res) => {
      setMessage(res.message.toString());
      setLoading(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
      // console.log(res.message);
    });
  };

  const handleMessage = (event) => {
    setMessage("");
  };
  const handleKeyDowm = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  var message_style = <div></div>;
  if (message === "Password updated") {
    message_style = (
      <div>
        <div class="pt-2 h-12 flex max-w-sm alert alert-success">
          <div className="justify-start">
            <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
              {message + " "}
            </p>
          </div>
          <button onClick={handleMessage} className="flex-none pt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        {loadScreen}
      </div>
    );
  } else if (message !== "") {
    message_style = (
      <div class="pt-2 h-12 flex max-w-sm alert alert-error">
        <div className="justify-start">
          <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
            {message + " "}
          </p>
        </div>
        <button onClick={handleMessage} className="flex-none pt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    );
  }

  const modal = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-100 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-sm lg:max-w-3xl w-full">
                <div className="bg-white px-10 pt-5 pb-4 sm:pb-4 text-dsblue-90">
                  <div className="grid sm:grid-cols-1 md:grid-cols-2 w-full h-full gap-3">
                    <div className="flex justify-center items-center">
                      <img src={LoginPhoto}></img>
                    </div>
                    <div className="py-8">
                      <div>
                        <p
                          className={
                            "text-left sm:pb-1 md:pb-4 lg:pb-8 font-Gilroy-b text-dsblue-100 " +
                            styles.title_size_dynamic
                          }
                        >
                          Reset Password Here
                          <p className={styles.Atag_size_dynamic}>
                            {message_style}
                          </p>
                        </p>
                      </div>
                      <form
                        className="flex flex-col"
                        onSubmit={handleSubmit}
                        onKeyDown={(e) => {
                          handleKeyDowm(e);
                        }}
                      >
                        <div className="flex flex-col pt-4">
                          <label for="username" className={styles.input_lable}>
                            Enter your new password
                          </label>
                          <input
                            type="password"
                            name="password"
                            onChange={onInputChange}
                            onBlur={validateInput}
                            // placeholder="username"
                            className={styles.input_box}
                          />
                          <div className={styles.input_error}>
                            {inputError.password && (
                              <span className="">{inputError.password}</span>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col pt-4">
                          <label for="username" className={styles.input_lable}>
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            name="confirm_password"
                            onChange={onInputChange}
                            onBlur={validateInput}
                            // placeholder="username"
                            className={styles.input_box}
                          />
                          <div className={styles.input_error}>
                            {inputError.confirm_password && (
                              <span className="">
                                {inputError.confirm_password}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-end pt-6">
                          <button
                            className={styles.btn_size_dynamic + " w-full"}
                            type="submit"
                            disabled={
                              inputError.password !== "" ||
                              inputError.confirm_password !== ""
                            }
                          >
                            Save Password
                          </button>
                        </div>
                        <p className="text-right pt-6 flex justify-end items-center">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.725 3.22509L8.25 1.75009L0 10.0001L8.25 18.2501L9.725 16.7751L2.95 10.0001L9.725 3.22509Z"
                              fill="#6F4FF9"
                            />
                          </svg>

                          <a
                            href="/login"
                            className={" text-violet-90 lg:text-lg pl-2"}
                          >
                            Back to Login
                          </a>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
  return (
    <div className="bg-white  w-full h-screen ">
      <img
        alt="accessBG"
        src={accessBG}
        className="h-screen w-full object-contain hidden md:block"
      ></img>
      <img
        alt="accessBG_VERTICAL"
        src={accessBG_VERTICAL}
        className="h-screen w-full object-contain block md:hidden "
      ></img>
      {modal}
    </div>
  );
}
