import axios from "axios";
const cors = require("cors");
cors({ origin: true });
const apiURL = window.location.origin.includes("3000")
  ? window.location.origin.replace("3000", "3001")
  : "https://photo-dev-app.onrender.com";
// const apiURL =
//   "https://us-east-2.aws.data.mongodb-api.com/app/data-rowzy/endpoint/data/v1/action/";

async function GetShops(shopId, name) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/shopExpress/GetShops/`, {
        shopId: shopId,
        name: name,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// GetShopById;
async function GetShopById(_id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/shopExpress/GetShopById/`, {
        _id: _id,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// FindShop
async function FindShop(searchTerm) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/shopExpress/FindShop/`, {
        searchTerm: searchTerm,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//UpdateShop
async function UpdateShop(id, newObject) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${apiURL}/shopExpress/UpdateShop/`, {
        _id: id,
        newObject: newObject,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default {
  GetShops: GetShops,
  GetShopById: GetShopById,
  FindShop: FindShop,
  UpdateShop: UpdateShop,
};
