var Atag_size_dynamic = " pt-2 lg:text-sm text-xs ";
var text_size_dynamic = " lg:text-lg  text-sm";
var title_size_dynamic = " lg:text-1xl xl:text-2xl text-sm";

var btn_size_dynamic =
  " bg-dsblue-80 hover:bg-dsblue-100 text-white font-bold py-2 px-3 rounded-lg lg:text-xl text-lg font-Gilroy-m ";
var input_box =
  " border bg-white border-dsblue-90 rounded-lg w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline" +
  text_size_dynamic;
var input_lable =
  "pb-2 font-Gilroy-m text-dsblue-90 text-left" + text_size_dynamic;
var input_error =
  " text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg";

let styles = {
  Atag_size_dynamic: Atag_size_dynamic,
  text_size_dynamic: text_size_dynamic,
  title_size_dynamic: title_size_dynamic,
  btn_size_dynamic: btn_size_dynamic,
  input_box: input_box,
  input_lable: input_lable,
  input_error: input_error,
};

module.exports = styles;
