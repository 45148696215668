import "./App.css";
import React from "react";
import { Provider } from "react-redux";
import PubRoutes from "./MyRoutes/pubRoutes";
import globalStore from "./store/globalStore";
import { PersistGate } from "redux-persist/integration/react";
import { NextUIProvider } from "@nextui-org/react";

function App() {
  return (
    <Provider store={globalStore}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      {/* <PubRoutes /> */}
      {/* </PersistGate> */}
      <NextUIProvider>
        <PubRoutes />
      </NextUIProvider>
    </Provider>
  );
}

export default App;
