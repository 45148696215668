import React, { useEffect, useState } from "react";
import ShopLayout from "../../../component/shop/shopLayout";

import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import Ownerstatus from "../../../component/shop/ownerstatus/ownerstatus";
import order_api from "../../../api/order_api";
import globalStore from "../../../store/globalStore";

export default function BusinessStatus() {
  const [YTDRevenue, setYTDRevenue] = useState(0);
  const [YTDNumberOfOrders, setYTDNumberOfOrders] = useState(0);
  const [YTDNumberOfRolls, setYTDNumberOfRolls] = useState(0);
  const [YTDRevenueOfDevelop, setYTDRevenueOfDevelop] = useState(0);
  const [YTDRevenueOfScan, setYTDRevenueOfScan] = useState(0);
  const [YTDRevenueOfPrint, setYTDRevenueOfPrint] = useState(0);
  //MTD
  const [MTDRevenue, setMTDRevenue] = useState(0);
  const [MTDNumberOfOrders, setMTDNumberOfOrders] = useState(0);
  const [MTDNumberOfRolls, setMTDNumberOfRolls] = useState(0);
  const [MTDRevenueOfDevelop, setMTDRevenueOfDevelop] = useState(0);
  const [MTDRevenueOfScan, setMTDRevenueOfScan] = useState(0);
  const [MTDRevenueOfPrint, setMTDRevenueOfPrint] = useState(0);
  const [pieData, setPieData] = useState([]);
  const [PIEdateRange, setPIEDateRange] = useState("yearly");
  const [PIEdateRangeList, setPIEDateRangeList] = useState([
    "yearly",
    "monthly",
    "weekly",
  ]);
  //Daily

  useEffect(() => {
    if (globalStore.getState().session.user.lab_id) {
      order_api
        .NumberOfRollsCategoryByChemical(
          globalStore.getState().session.user.lab_id,
          PIEdateRange
        )
        .then((res) => {
          console.log(res);
          setPieData(res);
        });
    }
  }, [PIEdateRange]);
  useEffect(() => {
    if (globalStore.getState().session.user.lab_id) {
      order_api
        .YTDRevenue(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setYTDRevenue(res);
        });
      order_api
        .YTDNumberOfOrders(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setYTDNumberOfOrders(res);
        });
      order_api
        .YTDNumberOfRolls(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setYTDNumberOfRolls(res);
        });
      order_api
        .YTDRevenueOfDevelop(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setYTDRevenueOfDevelop(res);
        });
      order_api
        .YTDRevenueOfScan(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setYTDRevenueOfScan(res);
        });
      order_api
        .YTDRevenueOfPrint(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setYTDRevenueOfPrint(res);
        });
      order_api
        .MTDRevenue(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setMTDRevenue(res);
        });
      order_api
        .MTDNumberOfOrders(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setMTDNumberOfOrders(res);
        });
      order_api
        .MTDNumberOfRolls(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setMTDNumberOfRolls(res);
        });
      order_api
        .MTDRevenueOfDevelop(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setMTDRevenueOfDevelop(res);
        });
      order_api
        .MTDRevenueOfScan(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setMTDRevenueOfScan(res);
        });
      order_api
        .MTDRevenueOfPrint(globalStore.getState().session.user.lab_id)
        .then((res) => {
          setMTDRevenueOfPrint(res);
        });
    }
  }, []);

  var data1 = [
    {
      id: "css",
      label: "css",
      value: 594,
      color: "hsl(30, 70%, 50%)",
    },
    {
      id: "python",
      label: "python",
      value: 75,
      color: "hsl(350, 70%, 50%)",
    },
    {
      id: "scala",
      label: "scala",
      value: 281,
      color: "hsl(210, 70%, 50%)",
    },
    {
      id: "sass",
      label: "sass",
      value: 495,
      color: "hsl(292, 70%, 50%)",
    },
    {
      id: "stylus",
      label: "stylus",
      value: 100,
      color: "hsl(334, 70%, 50%)",
    },
  ];
  const MyResponsivePie = ({ data /* see data tab */ }) => (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "ruby",
          },
          id: "dots",
        },
        {
          match: {
            id: "c",
          },
          id: "dots",
        },
        {
          match: {
            id: "go",
          },
          id: "dots",
        },
        {
          match: {
            id: "python",
          },
          id: "dots",
        },
        {
          match: {
            id: "scala",
          },
          id: "lines",
        },
        {
          match: {
            id: "lisp",
          },
          id: "lines",
        },
        {
          match: {
            id: "elixir",
          },
          id: "lines",
        },
        {
          match: {
            id: "javascript",
          },
          id: "lines",
        },
      ]}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 10,
          itemWidth: 120,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );

  const currentMonth = () => {
    var date = new Date();
    var month = date.getMonth() + 1;
    //format month in like Jan, Feb, Mar
    switch (month) {
      case 1:
        month = "Jan.";
        break;
      case 2:
        month = "Feb.";
        break;
      case 3:
        month = "Mar.";
        break;
      case 4:
        month = "Apr.";
        break;
      case 5:
        month = "May.";
        break;
      case 6:
        month = "Jun.";
        break;
      case 7:
        month = "Jul.";
        break;
      case 8:
        month = "Aug.";
        break;
      case 9:
        month = "Sep.";
        break;
      case 10:
        month = "Oct.";
        break;
      case 11:
        month = "Nov.";
        break;
      case 12:
        month = "Dec.";
        break;

      default:
        break;
    }

    return month;
  };

  const dateRange = () => {
    // first day of month - today
    // in format like Aug. 1 - Aug. 31
    var date = new Date();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var firstDay = new Date(year, month - 1, 1);
    var lastDay = new Date(year, month, 0);
    var firstDayString = firstDay.toDateString().slice(4);
    var lastDayString = lastDay.toDateString().slice(4);
    var dateRange = firstDayString + " - " + lastDayString;
    return dateRange;
  };

  return (
    <div>
      <ShopLayout describtion="businessStatus">
        <p className=" text-2xl font-Gilroy-m text-gray-700">Business Status</p>

        {/* <div className="flex justify-start items-start flex-wrap gap-3"> */}

        <div className="flex flex-wrap justify-between  ">
          <div className=" pr-4 py-4 w-1/2">
            <p className="text-2xl font-Gilroy-m text-gray-700">Year to date</p>
            <div className="grid grid-cols-2 gap-3 py-3 text-dsblue-100">
              <div className="p-2 rounded-lg shadow-lg flex flex-col justify-center items-center gap-3 divide-y-1">
                <div className="flex justify-start items-center gap-3 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                    />
                  </svg>
                  <div>
                    <p>${YTDRevenue}</p>
                    <p>Revenue YTD</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 pt-2 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                    />
                  </svg>

                  <div>
                    <p>{YTDNumberOfOrders}</p>
                    <p>#Order YTD</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 pt-2 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0 1 18 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0 1 18 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 0 1 6 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"
                    />
                  </svg>

                  <div>
                    <p>{YTDNumberOfRolls}</p>
                    <p>#Rolls YTD</p>
                  </div>
                </div>
              </div>
              <div className="p-2 rounded-lg shadow-lg flex flex-col justify-center items-center gap-3 divide-y-1">
                <div className="flex justify-start items-center gap-3 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.75 3.104v5.714a2.25 2.25 0 0 1-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 0 1 4.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0 1 12 15a9.065 9.065 0 0 0-6.23-.693L5 14.5m14.8.8 1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0 1 12 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
                    />
                  </svg>

                  <div>
                    <p>${YTDRevenueOfDevelop}</p>
                    <p>Develop YTD</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 pt-2 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
                    />
                  </svg>

                  <div>
                    <p>${YTDRevenueOfScan}</p>
                    <p>Scan YTD</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 pt-2 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
                    />
                  </svg>

                  <div>
                    <p>${YTDRevenueOfPrint}</p>
                    <p>Print YTD</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pl-4 py-4 w-1/2">
            <p className="text-2xl font-Gilroy-m text-gray-700">
              {currentMonth() + " Status" + " (" + dateRange() + ")"}
            </p>
            <div className="grid grid-cols-2 gap-3 py-3 text-dsblue-100">
              <div className="p-2 rounded-lg shadow-lg flex flex-col justify-center items-center gap-3 divide-y-1">
                <div className="flex justify-start items-center gap-3 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                    />
                  </svg>
                  <div>
                    <p>${MTDRevenue}</p>
                    <p>Revenue</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 pt-2 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                    />
                  </svg>

                  <div>
                    <p>{MTDNumberOfOrders}</p>
                    <p>#Order</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 pt-2 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0 1 18 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0 1 18 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 0 1 6 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"
                    />
                  </svg>

                  <div>
                    <p>{MTDNumberOfRolls}</p>
                    <p>#Rolls</p>
                  </div>
                </div>
              </div>
              <div className="p-2 rounded-lg shadow-lg flex flex-col justify-center items-center gap-3 divide-y-1">
                <div className="flex justify-start items-center gap-3 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.75 3.104v5.714a2.25 2.25 0 0 1-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 0 1 4.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0 1 12 15a9.065 9.065 0 0 0-6.23-.693L5 14.5m14.8.8 1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0 1 12 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
                    />
                  </svg>

                  <div>
                    <p>${MTDRevenueOfDevelop}</p>
                    <p>Develop</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 pt-2 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
                    />
                  </svg>

                  <div>
                    <p>${MTDRevenueOfScan}</p>
                    <p>Scan</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 pt-2 w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-12 h-12"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
                    />
                  </svg>

                  <div>
                    <p>${MTDRevenueOfPrint}</p>
                    <p>Print</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* </div> */}
        <div className="grid grid-cols-3 gap-3 ">
          <div className="h-82 p-8 rounded-lg shadow-lg col-span-3">
            <Ownerstatus></Ownerstatus>
          </div>
        </div>

        <div className="grid grid-cols-1 text-dsblue-100 gap-3  py-6">
          <div className="  p-8 rounded-lg shadow-lg ">
            <div className="flex justify-between items-center">
              <p className="text-2xl font-Gilroy-m text-gray-700">
                Chemical Usage
              </p>
              {/* drop down  date range from PIEdateRangeList */}
              <div className="flex justify-between items-center">
                <div className="relative inline-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 12a2 2 0 100-4 2 2 0 000 4Zm0 2a4 4 0 110-8 4 4 0 010 8Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <select
                    className="border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                    onChange={(e) => {
                      setPIEDateRange(e.target.value);
                    }}
                  >
                    {PIEdateRangeList.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2">
              <div className="h-80">
                <MyResponsivePie data={pieData}></MyResponsivePie>
              </div>
              <div className="flex flex-col justify-center items-center">
                {pieData.map((item, index) => (
                  <div className="flex justify-between items-center py-2 text-dsblue-100 w-1/2">
                    <p>{item.id}</p>
                    <p>Total rolls: {item.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ShopLayout>
    </div>
  );
}
