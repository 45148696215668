import React, { useState, useEffect, Fragment, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginPhoto from "../../asset/img/login.jpg";
import { Dialog, Transition } from "@headlessui/react";
import styles from "./StylingForUseraccess";
import accessBG from "../../asset/img/accessBG.png";
import accessBG_VERTICAL from "../../asset/img/accessBG_vertical.png";
import user_api from "../../api/user_api";

export default function Forgetpassword() {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState({ email: "" });

  const [inputError, setInputError] = useState({
    email: "",
  });

  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  // const [message_style, setMessage_style] = useState();

  var loadScreen = <progress class="progress max-w-sm "></progress>;
  
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          //check if value is email or not
          if (!value) {
            stateObj[name] = "Please enter Email.";
          } else if (!value.includes("@" || ".")) {
            stateObj[name] = "Please enter a valid Email.";
          }

          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  //   console.log(window.location.href.split("/").slice(0, 3).join("/"));
  const handleSubmit = async (event) => {
    event.preventDefault();

    // async function signup(username, email, phone, password, usertype) {
    await user_api.forgetPassword(user.email).then((res) => {
      setMessage(res.message.toString());
      setLoading(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
      // console.log(res.message);
    });
  };

  const handleMessage = (event) => {
    setMessage("");
  };
  var message_style = <div></div>;
  if (message === "Email sent successfully") {
    message_style = (
      <div>
        <div class="pt-2 h-12 flex max-w-sm alert alert-success">
          <div className="justify-start">
            <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
              {message + " "}
            </p>
          </div>
          <button onClick={handleMessage} className="flex-none pt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        {loadScreen}
      </div>
    );
  } else if (message !== "") {
    message_style = (
      <div class="pt-2 h-12 flex max-w-sm alert alert-error">
        <div className="justify-start">
          <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
            {message + " "}
          </p>
        </div>
        <button onClick={handleMessage} className="flex-none pt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    );
  }

  const modal = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-100 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-sm lg:max-w-3xl w-full">
                <div className="bg-white px-10 pt-5 pb-4 sm:pb-4 text-dsblue-90">
                  <div className="grid sm:grid-cols-1 md:grid-cols-2 w-full h-full gap-3">
                    <div className="flex justify-center items-center">
                      <img src={LoginPhoto}></img>
                    </div>
                    <div className="py-8">
                      <div>
                        <p
                          className={
                            "text-left sm:pb-1 md:pb-4 lg:pb-8 font-Gilroy-b text-dsblue-100 " +
                            styles.title_size_dynamic
                          }
                        >
                          Forget Password?
                          <p className={styles.Atag_size_dynamic}>
                            {message_style}
                          </p>
                        </p>
                      </div>
                      <form className="flex flex-col" onSubmit={handleSubmit}>
                        <div className="flex flex-col pt-4">
                          <label for="email" className={styles.input_lable}>
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            onChange={onInputChange}
                            onBlur={validateInput}
                            placeholder="Enter your email"
                            className={styles.input_box}
                          />
                          <div className={styles.input_error}>
                            {inputError.email && (
                              <span className="">{inputError.email}</span>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-end pt-4">
                          <button
                            className={styles.btn_size_dynamic + " w-full"}
                            type="submit"
                          >
                            Email Me
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
  return (
    <div className="bg-white  w-full h-screen ">
      <img
        alt="accessBG"
        src={accessBG}
        className="h-screen w-full object-contain hidden md:block"
      ></img>
      <img
        alt="accessBG_VERTICAL"
        src={accessBG_VERTICAL}
        className="h-screen w-full object-contain block md:hidden "
      ></img>
      {modal}
    </div>
  );
}
