import React from "react";

export default function SimpleOrderForm() {
  return (
    <div>
      <div className="flex justify-start flex-col items-start text-dsblue-100">
        <p className="text-2xl font-Gilroy-m">Simple Order Form</p>{" "}
        <div className="text-dsblue-100">
          <p>This is for you to quickly make simple order</p>
          <p>And in this order form, </p>
        </div>
      </div>
      <div className="">
        <div></div>
      </div>
    </div>
  );
}
