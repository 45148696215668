import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { connect } from "react-redux";
import Login from "../pages/login/login";
import Signup from "../pages/signup/signup";
import Dashboard from "../pages/dashboard/dashboard";
import Forgetpassword from "../pages/forgetpassword/forgetpassword";
import Resetpassword from "../pages/resetpassword/resetpassword";
import Profile from "../pages/profile/profile";
import ExpertOrderForm from "../pages/customer/ExpertOrderForm/ExpertOrderForm";
import OrderHistoryPage from "../pages/customer/orderHistoryPage/orderHistoryPage";
import OrderDetail from "../pages/customer/OrderDetail/OrderDetail";
import ShopOrderDetail from "../pages/shop/OrderDetail/OrderDetail";
import ShopOrderHistoryPage from "../pages/shop/ShopOrderHistoryPage/ShopOrderHistoryPage";
import globalStore from "../store/globalStore";
import ShopExpertOrderForm from "../pages/shop/ShopExpertOrderForm/ShopExpertOrderForm";
import ManageServices from "../pages/shop/manageServices/manageServices";
import config, { usertype } from "../config";
import NoAccess from "../pages/noAccess/noAccess";
import LabDetail from "../pages/shop/labDetail/labDetail";
import CustomerLayout from "../component/costumer/customerLayout";
import LabAction from "../pages/shop/labAction/labAction";
import ToPrint from "../component/general/toPrint/toPrint";
import SimpleOrderForm from "../pages/customer/BegeinnerOrderForm/orderForm";
import BusinessStatus from "../pages/shop/businessStatus/businessStatus";
const pubRoutes = ({ authenticated, checked, user }) => {
  // const nav = useNavigate();
  return (
    <Router basename={process.env.PORT}>
      {checked && (
        <Routes>
          {/* <Route
            path="/"
            caseSensitive={false}
            element={<UserAccessPage name="login" />}
          />
          <Route
            path="/login"
            caseSensitive={false}
            element={<UserAccessPage name="login" />}
          /> */}
          <Route path="/" caseSensitive={false} element={<Login></Login>} />
          <Route
            path="/login"
            caseSensitive={false}
            element={<Login></Login>}
          />
          <Route
            path="/signup"
            caseSensitive={false}
            element={<Signup></Signup>}
          />

          <Route
            path="/forgotpassword"
            caseSensitive={false}
            element={<Forgetpassword> </Forgetpassword>}
          />

          <Route
            path="/resetpassword/:token"
            caseSensitive={false}
            element={<Resetpassword></Resetpassword>}
          />

          <Route
            exact
            path="/dashboard"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                <Dashboard></Dashboard>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/profile"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                <Profile></Profile>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/beginnerOrder"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                <div>
                  <CustomerLayout>
                    <SimpleOrderForm></SimpleOrderForm>
                  </CustomerLayout>
                </div>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/expertOrder"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                {/* <ExpertOrderForm></ExpertOrderForm> */}
                {usertype.customer.includes(user.usertype) ? (
                  <ExpertOrderForm></ExpertOrderForm>
                ) : (
                  <ShopExpertOrderForm></ShopExpertOrderForm>
                )}
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/orderHistory"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                {/* <ExpertOrderForm></ExpertOrderForm> */}
                {usertype.customer.includes(user.usertype) ? (
                  <OrderHistoryPage></OrderHistoryPage>
                ) : (
                  //redirect to lab order history
                  <ShopOrderHistoryPage></ShopOrderHistoryPage>
                )}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/orderHistory/:id"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                {usertype.customer.includes(user.usertype) ? (
                  <OrderDetail></OrderDetail>
                ) : (
                  //redirect to lab order history
                  <ShopOrderDetail></ShopOrderDetail>
                )}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/toprint/:id"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                {usertype.customer.includes(user.usertype) ? (
                  <NoAccess name="accessDeny"></NoAccess>
                ) : (
                  //redirect to lab order history
                  <ToPrint></ToPrint>
                )}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/orderHistory/accessDeny"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                <NoAccess name="accessDeny"></NoAccess>
              </PrivateRoute>
            }
          />
          {/* Route for manage services */}
          <Route
            exact
            path="/manageServices"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                {usertype.customer.includes(user.usertype) ? (
                  //redirect to services
                  // (window.location.href = "/services")
                  <NoAccess name="accessDeny"></NoAccess>
                ) : (
                  <ManageServices></ManageServices>
                )}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/services"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                {/* <ManageServices></ManageServices> */}
                {!usertype.customer.includes(user.usertype) ? (
                  //redirect to services
                  // (window.location.href = "/services")

                  <NoAccess name="accessDeny"></NoAccess>
                ) : (
                  <ManageServices></ManageServices>
                )}
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/labDetail"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                {/* <ManageServices></ManageServices> */}
                {usertype.customer.includes(user.usertype) ? (
                  <NoAccess name="accessDeny"></NoAccess>
                ) : (
                  <LabDetail></LabDetail>
                )}
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/labAction"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                {/* <ManageServices></ManageServices> */}
                {usertype.customer.includes(user.usertype) ? (
                  <NoAccess name="accessDeny"></NoAccess>
                ) : (
                  <LabAction></LabAction>
                )}
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/businessStatus"
            caseSensitive={false}
            element={
              <PrivateRoute authenticated={authenticated}>
                {/* <ManageServices></ManageServices> */}
                {!usertype.labAdmin.includes(user.usertype) ? (
                  <NoAccess name="accessDeny"></NoAccess>
                ) : (
                  <BusinessStatus></BusinessStatus>
                )}
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/*"
            caseSensitive={false}
            element={<NoAccess name="404"></NoAccess>}
          />

          {/* <Route path="*" element={<NotFound></NotFound>} /> */}
        </Routes>
      )}
    </Router>
  );
};

const mapState = ({ session }) => ({
  checked: session.checked,
  authenticated: session.authenticated,
  user: session.user,
});
export default connect(mapState)(pubRoutes);
