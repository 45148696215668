import React, { useEffect, useState, Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Barcode from "react-barcode";
import { QRNormal } from "react-qrbtf";
import { useNavigate } from "react-router-dom";
import order_api from "../../../api/order_api";
import globalStore from "../../../store/globalStore";
import config from "../../../config";

export default function ToPrint() {
  const navigate = useNavigate();
  const order_id = window.location.href.split("/").pop().split("#")[0];
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const componentRef = React.useRef(null);

  const [access, setAccess] = useState(0);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      // After print, close the tab
      navigate("/orderHistory/" + order_id);
    },
  });
  useEffect(() => {
    if (isLoading) {
      // var access = false;
      if (
        config.usertype.labAdmin[0] !==
        globalStore.getState().session.user.usertype
      ) {
        order_api
          .ShopHaveAccessToOrder(
            order_id,
            globalStore.getState().session.user.lab_id
          )
          .then((res) => {
            // console.log("res", res);
            if (res.status === true) {
              // access = true;
              setAccess(1);
              //   console.log("access", access);
            } else {
              setAccess(-1);
            }
          })
          .catch((err) => {
            // console.log("err", err);
            setAccess(-1);
          });
      } else if (
        config.usertype.labAdmin[0] ===
        globalStore.getState().session.user.usertype
      ) {
        // access = true;
        setAccess(1);
      }
    }
  }, []);
  useEffect(() => {
    if (access === 1) {
      order_api.OrderHistoryById(order_id).then((res) => {
        // console.log("res", res);
        setOrder(res);
        setIsLoading(false);
      });
    } else if (access === -1) {
      navigate("/orderHistory/accessDeny");
    }
  }, [access]);

  useEffect(() => {
    if (order.order) {
      handlePrint();
    }
  }, [order]);

  const totalOrderPrice = () => {
    let total = 0;
    order.order?.forEach((roll) => {
      total += roll.Price.totalPrice;
    });
    return total;
  };

  const combineOrSplit = () => {
    let differentOrders = [];
    let sameOrders = [[]];

    // [a, b, a, c, b, d, c, e, f, g] - order.order
    // [[a, a], [b, b], [c, c]] - sameOrders
    // [d, e, f, g] - differentOrders

    //sameOrders
    order.order?.forEach((roll) => {
      let isSame = false;
      sameOrders.forEach((sameOrder) => {
        if (JSON.stringify(sameOrder[0]) === JSON.stringify(roll)) {
          sameOrder.push(roll);
          isSame = true;
        }
      });
      if (!isSame) {
        sameOrders.push([roll]);
      }
    });
    sameOrders.shift();
    //differentOrders
    sameOrders.forEach((sameOrder, index) => {
      if (sameOrder.length < 2) {
        differentOrders.push(sameOrder[0]);
      }
    });

    console.log("sameOrders", sameOrders);
    console.log("differentOrders", differentOrders);
    const sameOrdersLength = order.order?.length - differentOrders.length;
    var currentIndex = 1;
    return (
      <div>
        {sameOrders.map((sameOrder, index) => {
          var roll = sameOrder[0];
          var result =
            sameOrder.length > 1 ? (
              <div className=" border-t-2 border-black text-xs">
                <div className="carousel-item relative text-black py-0.5  w-full place-items-start  ">
                  <div className="flex  flex-col px-1 w-full">
                    <div className=" pb-1 flex justify-between items-center">
                      <p className="text-xs font-Gilroy-m">
                        {sameOrder.length + " Rolls"}
                        {/* {currentIndex +
                          "-" +
                          (currentIndex + sameOrder.length - 1)} */}
                      </p>
                      <p>${roll.Price.totalPrice}</p>
                    </div>

                    <div>
                      <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                        <p className="">Develop detail:</p>
                        <p>$ {roll.info?.price}</p>
                      </div>
                      <div className=" flex justify-between items-center gap-0.5 pt-1 px-2">
                        <div className="flex justify-start flex-wrap gap-2">
                          <div className=" underline">{roll.info?.size} mm</div>

                          <div className=" underline">
                            {roll.info?.chamical === "Black And White"
                              ? "B&W"
                              : roll.info?.chamical}
                          </div>

                          <div className=" underline">
                            {roll.info?.exposure} photos
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    {roll.dev_addon?.push_pull || roll.dev_rush?.dev_rush ? (
                      <div className="pt-1">
                        <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                          <p className="">Develop add on detail:</p>
                          <p>$ {roll.dev_addon.price + roll.dev_rush.price}</p>
                        </div>
                        <div className=" flex justify-between items-center gap-2 pt-1 px-2">
                          <div className="flex justify-start flex-wrap gap-2">
                            {roll.dev_addon?.push_pull ? (
                              <div className=" underline">
                                {"$" +
                                  roll.dev_addon?.price +
                                  " " +
                                  roll.dev_addon?.pushOrPull}
                              </div>
                            ) : null}

                            {roll.dev_rush?.dev_rush ? (
                              <div className=" underline">
                                $ {roll.dev_rush?.price} Rush service
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {roll.scan?.scan ? (
                      <div className="pt-1">
                        <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                          <p className="">Scan detail:</p>
                          <p>$ {roll.scan.price + roll.scan_addon.price}</p>
                        </div>
                        <div className=" flex justify-between items-center gap-1 pt-1 px-2">
                          <div className="flex justify-start flex-wrap gap-1">
                            <div className=" underline">
                              {roll.scan?.scanRes}
                            </div>

                            {roll.scan_addon?.tiff ? (
                              <div className=" underline">
                                {roll.scan?.incloudTiff
                                  ? "Include Tiff file"
                                  : "$" +
                                    roll.scan_addon.price +
                                    " Add Tiff file"}
                              </div>
                            ) : null}
                          </div>
                          {/* <div>
                            <div className=" ">
                              $ {roll.scan.price + roll.scan_addon.price}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    ) : null}

                    {roll.print?.prints ? (
                      <div className="pt-1">
                        <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                          <p className="">Print detail:</p>
                          <p>
                            ${" "}
                            {roll.print.price +
                              roll.print_border.price +
                              roll.print_finish.price}
                          </p>
                        </div>
                        {/* <p className="px-2 py-1 rounded-lg bg-dsblue-20">
                          Print detail:
                        </p> */}
                        <div className=" flex justify-between items-center gap-1 pt-1 px-2">
                          <div className="flex justify-start flex-wrap gap-1">
                            <div className=" underline">
                              {roll.print?.prtintSize}
                            </div>
                            <div className=" underline">
                              {roll.print?.print_set} Set
                            </div>

                            {roll.print_border?.border !== null &&
                            roll.print_border?.border !== "" &&
                            roll.print_border?.border !== undefined &&
                            roll.print_border?.border ? (
                              <div className=" underline">Add border</div>
                            ) : null}
                            {roll.print_finish?.print_finish !== "" &&
                            roll.print_finish?.print_finish !== null &&
                            roll.print_finish?.print_finish !== undefined &&
                            roll.print_finish?.print_finish ? (
                              <div className=" underline">
                                {roll.print_finish?.print_finish} Finish
                              </div>
                            ) : null}
                          </div>
                          {/* <div>
                            <div className=" ">
                              $ {roll.print.price}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null;
          currentIndex = currentIndex + sameOrder.length;
          return result;
        })}
        {differentOrders.map((roll, index) => {
          //   roll = JSON.parse(roll);
          currentIndex = currentIndex + 1;
          return (
            <div className=" border-t-2 border-black text-xs">
              <div className="carousel-item relative text-black py-0.5  w-full place-items-start  ">
                <div className="flex  flex-col px-1 w-full">
                  <div className=" pb-1 flex justify-between items-center">
                    <p className="text-xs font-Gilroy-m">
                      {/* Roll {index + 2 + sameOrders.length} */}1 Rolls
                    </p>
                    <p>${roll.Price.totalPrice}</p>
                  </div>

                  <div>
                    <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                      <p className="">Develop detail:</p>
                      <p>$ {roll.info?.price}</p>
                    </div>
                    <div className=" flex justify-between items-center gap-0.5 pt-1 px-2">
                      <div className="flex justify-start flex-wrap gap-2">
                        <div className=" underline">{roll.info?.size} mm</div>

                        <div className=" underline">
                          {roll.info?.chamical === "Black And White"
                            ? "B&W"
                            : roll.info?.chamical}
                        </div>

                        <div className=" underline">
                          {roll.info?.exposure} photos
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  {roll.dev_addon?.push_pull || roll.dev_rush?.dev_rush ? (
                    <div className="pt-1">
                      <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                        <p className="">Develop add on detail:</p>
                        <p>$ {roll.dev_addon.price + roll.dev_rush.price}</p>
                      </div>
                      <div className=" flex justify-between items-center gap-2 pt-1 px-2">
                        <div className="flex justify-start flex-wrap gap-2">
                          {roll.dev_addon?.push_pull ? (
                            <div className=" underline">
                              {"$" +
                                roll.dev_addon?.price +
                                " " +
                                roll.dev_addon?.pushOrPull}
                            </div>
                          ) : null}

                          {roll.dev_rush?.dev_rush ? (
                            <div className=" underline">
                              $ {roll.dev_rush?.price} Rush service
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {roll.scan?.scan ? (
                    <div className="pt-1">
                      <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                        <p className="">Scan detail:</p>
                        <p>$ {roll.scan.price + roll.scan_addon.price}</p>
                      </div>
                      <div className=" flex justify-between items-center gap-1 pt-1 px-2">
                        <div className="flex justify-start flex-wrap gap-1">
                          <div className=" underline">{roll.scan?.scanRes}</div>

                          {roll.scan_addon?.tiff ? (
                            <div className=" underline">
                              {roll.scan?.incloudTiff
                                ? "Include Tiff file"
                                : "$" +
                                  roll.scan_addon.price +
                                  " Add Tiff file"}
                            </div>
                          ) : null}
                        </div>
                        {/* <div>
                            <div className=" ">
                              $ {roll.scan.price + roll.scan_addon.price}
                            </div>
                          </div> */}
                      </div>
                    </div>
                  ) : null}

                  {roll.print?.prints ? (
                    <div className="pt-1">
                      <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                        <p className="">Print detail:</p>
                        <p>
                          ${" "}
                          {roll.print.price +
                            roll.print_border.price +
                            roll.print_finish.price}
                        </p>
                      </div>
                      {/* <p className="px-2 py-1 rounded-lg bg-dsblue-20">
                          Print detail:
                        </p> */}
                      <div className=" flex justify-between items-center gap-1 pt-1 px-2">
                        <div className="flex justify-start flex-wrap gap-1">
                          <div className=" underline">
                            {roll.print?.prtintSize}
                          </div>
                          <div className=" underline">
                            {roll.print?.print_set} Set
                          </div>

                          {roll.print_border?.border !== null &&
                          roll.print_border?.border !== "" &&
                          roll.print_border?.border !== undefined &&
                          roll.print_border?.border ? (
                            <div className=" underline">Add border</div>
                          ) : null}
                          {roll.print_finish?.print_finish !== "" &&
                          roll.print_finish?.print_finish !== null &&
                          roll.print_finish?.print_finish !== undefined &&
                          roll.print_finish?.print_finish ? (
                            <div className=" underline">
                              {roll.print_finish?.print_finish} Finish
                            </div>
                          ) : null}
                        </div>
                        {/* <div>
                            <div className=" ">
                              $ {roll.print.price}
                            </div>
                          </div> */}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div ref={componentRef}>
      {" "}
      <div className="bg-white px-3 pb-2 sm:pb-2 ">
        <div className="">
          {/* include name, email, phone */}
          <div>
            <div className="flex justify-between items-center">
              <p className=" underline font-bold text-lg ">
                Total Price: ${totalOrderPrice()}
              </p>
              <p className=" underline font-bold text-lg ">
                Total Rolls: {order.order?.length}
              </p>
            </div>
            <p className="text-xs  ">
              Order Date: {order.orderDate?.split("T")[0]}
            </p>
            <p className="text-xs  ">Order# {order._id}</p>
          </div>
          <div className="flex items-center pt-1 ">
            <div className="flex flex-col justify-center gap-0.5">
              {/* <p className="text-lg ">{order.customer?.name}</p> */}
              {/* short bar code */}
              <Barcode
                textAlign={"left"}
                height={15}
                value={order.customer?.name}
              />
              <Barcode
                textAlign={"left"}
                height={15}
                value={order.customer?.phone}
              />
              <QRNormal
                value={window.location.origin + "/orderHistory/" + order._id}
                styles={{
                  svg: { width: "200px" },
                }}
                level="7%"
                size={100}
                opacity={100}
                // posType="roundRect"
                lineColor="#798998"
                posColor="#203B54"
                direction="loop"
              />
            </div>
          </div>

          {!isLoading ? combineOrSplit() : null}
          {/* {order.order?.map((roll, index) => {
            index = index + 1;
            return (
              <div className=" border-t-2 border-black text-xs">
                <div className="carousel-item relative text-black py-0.5  w-full place-items-start  ">
                  <div className="flex  flex-col px-1 w-full">
                    <div className=" pb-1 flex justify-between items-center">
                      <p className="text-xs font-Gilroy-m">Roll {index}</p>
                      <p>${roll.Price.totalPrice}</p>
                    </div>

                    <div>
                      <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                        <p className="">Develop detail:</p>
                        <p>$ {roll.info?.price}</p>
                      </div>
                      <div className=" flex justify-between items-center gap-0.5 pt-1 px-2">
                        <div className="flex justify-start flex-wrap gap-2">
                          <div className=" underline">{roll.info?.size} mm</div>

                          <div className=" underline">
                            {roll.info?.chamical === "Black And White"
                              ? "B&W"
                              : roll.info?.chamical}
                          </div>

                          <div className=" underline">
                            {roll.info?.exposure} photos
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    {roll.dev_addon?.push_pull || roll.dev_rush?.dev_rush ? (
                      <div className="pt-1">
                        <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                          <p className="">Develop add on detail:</p>
                          <p>$ {roll.dev_addon.price + roll.dev_rush.price}</p>
                        </div>
                        <div className=" flex justify-between items-center gap-2 pt-1 px-2">
                          <div className="flex justify-start flex-wrap gap-2">
                            {roll.dev_addon?.push_pull ? (
                              <div className=" underline">
                                {"$" +
                                  roll.dev_addon?.price +
                                  " " +
                                  roll.dev_addon?.pushOrPull}
                              </div>
                            ) : null}

                            {roll.dev_rush?.dev_rush ? (
                              <div className=" underline">
                                $ {roll.dev_rush?.price} Rush service
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {roll.scan?.scan ? (
                      <div className="pt-1">
                        <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg  border-t border-dsblue-100">
                          <p className="">Scan detail:</p>
                          <p>$ {roll.scan.price + roll.scan_addon.price}</p>
                        </div>
                        <div className=" flex justify-between items-center gap-1 pt-1 px-2">
                          <div className="grid grid-flow-row  gap-1">
                            <div className=" underline">
                              {roll.scan?.scanRes}
                            </div>

                            {roll.scan_addon?.tiff ? (
                              <div className=" underline">
                                {roll.scan?.incloudTiff
                                  ? "Include Tiff file"
                                  : "$" +
                                    roll.scan_addon.price +
                                    " Add Tiff file"}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {roll.print?.prints ? (
                      <div className="pt-1">
                        <div className="  flex justify-between items-center px-2 py-0.5 rounded-lg border border-dsblue-100">
                          <p className="">Print detail:</p>
                          <p>
                            ${" "}
                            {roll.print.price +
                              roll.print_border.price +
                              roll.print_finish.price}
                          </p>
                        </div>
                        <div className=" flex justify-between items-center gap-1 pt-1 px-2">
                          <div className="flex justify-start flex-wrap gap-1">
                            <div className=" underline">
                              {roll.print?.prtintSize}
                            </div>
                            <div className=" underline">
                              {roll.print?.print_set} Set
                            </div>

                            {roll.print_border?.border !== null &&
                            roll.print_border?.border !== "" &&
                            roll.print_border?.border !== undefined &&
                            roll.print_border?.border ? (
                              <div className=" underline">Add border</div>
                            ) : null}
                            {roll.print_finish?.print_finish !== "" &&
                            roll.print_finish?.print_finish !== null &&
                            roll.print_finish?.print_finish !== undefined &&
                            roll.print_finish?.print_finish ? (
                              <div className=" underline">
                                {roll.print_finish?.print_finish} Finish
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
    </div>
  );
}
