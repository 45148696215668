// import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { sessionReducer, sessionService } from "redux-react-session";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// import rootReducer from "./reducers";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

let mainReducer = function (state = {}, action) {
  switch (action.type) {
    case "SET_IS_lOGIN_FALSE":
      //console.log("CALLED SET_IS_lOGIN_FALSE");
      state.isLogin = false;
      return { ...state, isLogin: false };
    case "SET_IS_lOGIN_TRUE":
      //console.log("CALLED SET_IS_lOGIN_TRUE");
      return { ...state, isLogin: true };
    default:
      return state;
  }
};

let orderHistoryReducer = function (state = [], action) {
  switch (action.type) {
    case "SET_ORDER_HISTORY":
      //console.log("CALLED SET_ORDER_HISTORY");
      return action.payload;

    case "ADD_ONE_ORDER_HISTORY":
      //console.log("CALLED ADD_ONE_ORDER_HISTORY");
      return [...state, action.payload];

    default:
      return state;
  }
};

let defaultState = {
  main: {
    isLogin: false,
    loginType: "",
  },
  // component: {
  //   SelectStudents: {
  //     students: [],
  //   },
  //   CurrentClass: {
  //     classroom_id: 0,
  //     classroom_level: 0,
  //     classroom_name: "",
  //     classroom_code: "",
  //     classroom_title: "",
  //     classroom_duration: 0,
  //   },
  //   moreActionSwitch: {
  //     moreAction: false,
  //   },
  // },
};
const globalStore = configureStore(
  {
    reducer: {
      // persistedReducer,
      main: mainReducer,
      session: sessionReducer,
      orderHistory: orderHistoryReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
  },
  defaultState,

  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const validateSession = (session) => {
  // check if your session is still valid
  return true;
};

const options = {
  refreshOnCheckAuth: true,
  redirectPath: "/",
  driver: "COOKIES",
  validateSession,
};

sessionService.initSessionService(globalStore, options);
export default globalStore;
