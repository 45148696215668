import React, { useEffect, useState, Fragment, useRef } from "react";
import ShopLayout from "../../../component/shop/shopLayout";
import config from "../../../config";
import order_api from "../../../api/order_api";
import globalStore from "../../../store/globalStore";
import { Listbox } from "@headlessui/react";
import { Transition } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import Loading from "../../../component/general/loading/loading";
import photoDevImg from "../../../asset/img/login.jpg";
import { Menu, Disclosure } from "@headlessui/react";

import QrReader from "react-qr-reader";

export default function LabAction() {
  const [currentScanInput, setCurrentScanInput] = useState("");
  const [scanedID, setScanedID] = useState("");
  const [validId, setValidId] = useState(false);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentOrder, setCurrentOrder] = useState({});
  const [mode, setMode] = useState("");
  const [selected, setSelected] = useState(config.orderStatus[0]);
  const [onCameraScan1, setOnCameraScan1] = useState(false);
  const [onCameraScan2, setOnCameraScan2] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  // http://localhost:3000/orderHistory/653ffd392a913cedc61f5cd3
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  useEffect(() => {
    //   //console.log(currentScanInput);
    if (currentScanInput.length === 0) {
      setValidId(false);
      setCurrentScanInput("");
      setScanedID("");
    } else if (currentScanInput.includes("/orderHistory/")) {
      var tempID = currentScanInput.split("/")[4];
      // //console.log(tempID);
      //check if the input is a valid order id
      //if it is valid, then change the status
      //if it is not valid, then show error message
      order_api
        .ShopHaveAccessToOrder(
          tempID,
          globalStore.getState().session.user.lab_id
        )
        .then(async (res) => {
          if (res.status) {
            setValidId(true);
            setScanedID(tempID);
            await order_api.OrderHistoryById(tempID).then((orderDetail) => {
              //console.log(orderDetail);
              setCurrentOrder(orderDetail);
              setOpen(true);
              setIsLoading(false);
            });
          } else {
            setValidId(false);
            setOpen(true);
            setIsLoading(false);
          }
        });
    } else {
      order_api
        .ShopHaveAccessToOrder(
          currentScanInput,
          globalStore.getState().session.user.lab_id
        )
        .then(async (res) => {
          if (res.status) {
            setValidId(true);
            setScanedID(currentScanInput);
            await order_api
              .OrderHistoryById(currentScanInput)
              .then((orderDetail) => {
                //console.log(orderDetail);
                setCurrentOrder(orderDetail);
                setOpen(true);
                setIsLoading(false);
              });
          } else {
            setValidId(false);
            setOpen(true);
            setIsLoading(false);
          }
        });
    }
  }, [currentScanInput]);

  useEffect(() => {
    const update = async () => {
      if (mode === "next") {
        var nextStatus =
          config.orderStatus[
            config.orderStatus.indexOf(currentOrder.currentStatus) + 1
          ];
        await order_api
          .UpdateOrderStatusById(currentOrder, nextStatus)
          .then((res) => {
            setCurrentScanInput("");
            setScanedID("");
            setValidId(false);
            setIsLoading(true);
            setCurrentOrder({});
            setOpen(false);
            setMode("");
          })
          .catch((err) => {
            //console.log(err);
          });
      } else {
        await order_api
          .UpdateOrderStatusById(currentOrder, mode)
          .then((res) => {
            setCurrentScanInput("");
            setScanedID("");
            setValidId(false);
            setIsLoading(true);
            setCurrentOrder({});
            setOpen(false);
            setMode("");
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    };
    if (open === true) {
      setTimeout(() => {
        setOpen(false);
      }, 10000);
    } else if (!isLoading && !open) {
      update();
    }
  }, [currentScanInput, open]);

  const handleScan = async (scanData) => {
    setLoadingScan(true);
    //console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      //console.log(`loaded >>>`, scanData);
      setCurrentScanInput(scanData);

      setLoadingScan(false);
      if (onCameraScan1) {
        setMode("next");
        setOnCameraScan1(false);
      } else if (onCameraScan2) {
        setMode(selected);
        setOnCameraScan2(false);
      }
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  // const modalScan = () => {

  //   return (
  //     <Transition.Root show={open} as={Fragment}>
  //       <Dialog
  //         as="div"
  //         className="relative z-30"
  //         initialFocus={cancelButtonRef}
  //         onClose={setOpen}
  //       >
  //         <Transition.Child
  //           as={Fragment}
  //           enter="ease-out duration-300"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           leave="ease-in duration-200"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0"
  //         >
  //           <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  //         </Transition.Child>

  //         <div className="fixed z-100 inset-0 overflow-y-auto">
  //           <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
  //             <Transition.Child
  //               as={Fragment}
  //               enter="ease-out duration-300"
  //               enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //               enterTo="opacity-100 translate-y-0 sm:scale-100"
  //               leave="ease-in duration-200"
  //               leaveFrom="opacity-100 translate-y-0 sm:scale-100"
  //               leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //             >
  //               <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
  //                 <div className="bg-white px-3 md:px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90">
  //                 </div>
  //               </Dialog.Panel>
  //             </Transition.Child>
  //           </div>
  //         </div>
  //       </Dialog>
  //     </Transition.Root>
  //   );
  // };

  const modal = () => {
    const statusBar = (status) => {
      var style = "";
      if (config.orderStatus[0] === status) {
        style = "rounded-lg px-2 py-1 bg-green-500 text-white ";
      } else if (config.orderStatus[1] === status) {
        style = "rounded-lg px-2 py-1 bg-yellow-600 text-white ";
      } else if (config.orderStatus[2] === status) {
        style = "rounded-lg px-2 py-1 bg-blue-500 text-white ";
      } else if (config.orderStatus[3] === status) {
        style = "rounded-lg px-2 py-1 bg-purple-500 text-white ";
      } else if (config.orderStatus[4] === status) {
        style = "rounded-lg px-2 py-1 bg-red-500 text-white ";
      } else {
        style = "rounded-lg px-2 py-1 bg-green-500 text-white ";
      }
      // //console.log(status)
      return <p className={style + "w-full text-center"}>{status}</p>;
    };
    const targetStatus = (status) => {
      var result = "";
      if (mode === "next") {
        result = config.orderStatus[config.orderStatus.indexOf(status) + 1];
      } else if (mode === config.orderStatus[0]) {
        result = config.orderStatus[0];
      } else if (mode === config.orderStatus[1]) {
        result = config.orderStatus[1];
      } else if (mode === config.orderStatus[2]) {
        result = config.orderStatus[2];
      } else if (mode === config.orderStatus[2]) {
        result = config.orderStatus[3];
      } else if (mode === config.orderStatus[2]) {
        result = config.orderStatus[4];
      }
      //console.log(result);
      return result;
    };

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-100 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                  <div className="bg-white px-3 md:px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90">
                    {isLoading ? (
                      <Loading />
                    ) : validId ? (
                      <div className="pb-8">
                        {/* display order information and prev status and what will it change to  */}

                        <p className="text-lg font-Gilroy-m">
                          Order Information
                        </p>
                        <div className="grid grid-cols-1 gap-3 md:flex md:flex-wrap md:justify-between md:items-center py-8">
                          <p className="text-lg font-Gilroy-m">
                            {statusBar(currentOrder.currentStatus)}
                          </p>
                          <div className="flex justify-center items-center gap-1">
                            <svg
                              className=" rotate-90 md:rotate-0 w-6 h-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <svg
                              className=" rotate-90 md:rotate-0 w-6 h-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <svg
                              className=" rotate-90 md:rotate-0 w-6 h-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                          <p className="text-lg font-Gilroy-m">
                            {statusBar(
                              targetStatus(currentOrder.currentStatus)
                            )}
                          </p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                          <div className="flex justify-center rounded-xl py-8">
                            <img
                              src={photoDevImg}
                              alt="photoDevImg"
                              className="w-full object-contain"
                            />
                          </div>
                          <div className="flex flex-col justify-center gap-3 divide-y-1 rounded-xl border border-dsblue-100 p-4">
                            <div className="flex flex-col justify-start items-start gap-2">
                              <p className=" font-Gilroy-m px-2 py-1 rounded-xl bg-dsblue-80 text-white">
                                Order #:
                              </p>
                              <p className=" font-Gilroy-m w-full px-2 py-1 rounded-xl border border-dsblue-100 text-left">
                                {currentOrder._id}
                              </p>
                            </div>
                            <div className="flex flex-col justify-start items-start gap-2 pt-2">
                              <p className=" font-Gilroy-m px-2 py-1 rounded-xl bg-dsblue-80 text-white">
                                Customer Name:
                              </p>
                              <p className=" font-Gilroy-m w-full px-2 py-1 rounded-xl border border-dsblue-100 text-left">
                                {currentOrder.customer?.name}
                              </p>
                            </div>
                            <div className="flex flex-col justify-start items-start gap-2 pt-2">
                              <p className=" font-Gilroy-m px-2 py-1 rounded-xl bg-dsblue-80 text-white">
                                Customer Phone:
                              </p>
                              <p className=" font-Gilroy-m w-full px-2 py-1 rounded-xl border border-dsblue-100 text-left">
                                {currentOrder.customer?.phone}
                              </p>
                            </div>
                            <div className="flex flex-col justify-start items-start gap-2 pt-2">
                              <p className=" font-Gilroy-m px-2 py-1 rounded-xl bg-dsblue-80 text-white">
                                Order Date:
                              </p>
                              <p className=" font-Gilroy-m w-full px-2 py-1 rounded-xl border border-dsblue-100 text-left">
                                {currentOrder.orderDate?.split("T")[0]}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className="text-lg font-Gilroy-m">
                          Invalid Order ID
                        </p>
                        <p className="text-sm">
                          Please check the order ID and try again
                        </p>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const handleInput = (e, m) => {
    setCurrentScanInput(e.target.value);
    setMode(m);
  };

  const nextStatus = () => {
    return (
      <div className=" rounded-xl bg-dsblue-10 p-4 flex flex-col gap-4">
        <div className="text-lg ">
          <p className="text-lg font-Gilroy-m">Action - Next Status </p>
          <p className="text-sm">
            This section will change order status to next next status except the
            last one
          </p>
        </div>
        <div className="flex justify-start items-center gap-4">
          <p className="text-lg font-Gilroy-m">Order #:</p>
          <input
            // className="w-1/2"
            type="string"
            className="border border-dsblue-90 rounded-lg px-2 py-1 w-1/2"
            value={currentScanInput}
            onChange={(e) => {
              // setCurrentScanInput(e.target.value);
              handleInput(e, "next");
            }}
          ></input>
        </div>
        <div className="flex justify-start items-center gap-4">
          <button
            className="flex justify-start items-center gap-3 py-2 px-3 rounded-xl border border-dsblue-100 hover:bg-dsblue-100 hover:text-white"
            onClick={() => {
              setOnCameraScan1(!onCameraScan1);
              setOnCameraScan2(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
              />
            </svg>
            <p>{onCameraScan1 ? "Stop camera scan" : "Use camera scan"}</p>
          </button>
        </div>
        <div>
          <p className=" text-lg">Tips: to use this to change status </p>

          <p className="text-sm">Option 1: Use scanner scan</p>
          <p className="text-sm">Option 2: Use camera button scan</p>
        </div>
        {onCameraScan1 && (
          <>
            <QrReader
              facingMode={"environment"}
              // delay={1000}
              onError={handleError}
              onScan={handleScan}
              // chooseDeviceId={()=>selected}
              style={{ width: "300px" }}
            />
          </>
        )}
        {currentScanInput !== "" && <p>{currentScanInput}</p>}
      </div>
    );
  };
  const individualStatus = () => {
    const statusBar = (status) => {
      var style = "";
      if (config.orderStatus[0] === status) {
        style = "rounded-lg px-3 py-1 bg-green-500 text-white ";
      } else if (config.orderStatus[1] === status) {
        style = "rounded-lg px-3 py-1 bg-yellow-600 text-white ";
      } else if (config.orderStatus[2] === status) {
        style = "rounded-lg px-3 py-1 bg-blue-500 text-white ";
      } else if (config.orderStatus[3] === status) {
        style = "rounded-lg px-3 py-1 bg-purple-500 text-white ";
      } else if (config.orderStatus[4] === status) {
        style = "rounded-lg px-3 py-1 bg-red-500 text-white ";
      } else {
        style = "rounded-lg px-3 py-1 bg-green-500 text-white ";
      }
      // //console.log(status)
      return (
        <div className={"flex justify-center items-center " + style}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
          <p className={"w-full text-center"}>Change to {status}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      );
    };

    const handleDropDownSelection = (status) => {
      // setMode(status);
      setSelected(status);
    };

    const dropdown = () => {
      return config.orderStatus
        .filter((status) => status !== selected)
        .map((status, index) => {
          var style = "";
          if (config.orderStatus[0] === status) {
            style =
              "rounded-lg px-3 py-1 hover:bg-green-500 hover:text-white text-dsblue-100";
          } else if (config.orderStatus[1] === status) {
            style =
              "rounded-lg px-3 py-1 hover:bg-yellow-600 hover:text-white text-dsblue-100";
          } else if (config.orderStatus[2] === status) {
            style =
              "rounded-lg px-3 py-1 hover:bg-blue-500 hover:text-white text-dsblue-100";
          } else if (config.orderStatus[3] === status) {
            style =
              "rounded-lg px-3 py-1 hover:bg-purple-500 hover:text-white text-dsblue-100";
          } else if (config.orderStatus[4] === status) {
            style =
              "rounded-lg px-3 py-1 hover:bg-red-500 hover:text-white text-dsblue-100";
          } else {
            style =
              "rounded-lg px-3 py-1 hover:bg-green-500 hover:text-white text-dsblue-100";
          }
          return (
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? " text-white" : "text-dsblue-100",
                    "block px-4 text-sm w-full text-center " + style + " py-1"
                  )}
                  onClick={(e) => handleDropDownSelection(status)}
                >
                  {status}
                </button>
              )}
            </Menu.Item>
          );
        });
    };

    return (
      <div className=" rounded-xl bg-dsblue-10 p-4 flex flex-col gap-4">
        <div className=" rounded-xl bg-dsblue-10 p-4 flex flex-col gap-4">
          <div className="text-lg ">
            <div className="flex flex-col justify-start items-start gap-3 pb-2 ">
              <div className="flex justify-start items-center gap-4">
                <p className="text-lg font-Gilroy-m">{"Action - "}</p>
                <div>
                  <Menu as="div" className="relative text-left w-full">
                    <Menu.Button className="w-full hover:animate-bounce">
                      <div className="w-full ">{statusBar(selected)}</div>
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-left absolute z-20 left-0  w-full rounded-md shadow-lg bg-white  ring-opacity-5 focus:outline-none overflow-y-auto">
                        <div className="py-1 flex flex-col gap-3">
                          {dropdown()}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <p className="text-sm">
                This section will change order status to next next status except
                the last one
              </p>
            </div>
          </div>
          <div className="flex justify-start items-center gap-4">
            <p className="text-lg font-Gilroy-m">Order #:</p>
            <input
              // className="w-1/2"
              type="string"
              className="border border-dsblue-90 rounded-lg px-2 py-1 w-1/2"
              value={currentScanInput}
              onChange={(e) => {
                // setCurrentScanInput(e.target.value);

                handleInput(e, selected);
              }}
            ></input>
          </div>
          <div className="flex justify-start items-center gap-4">
            <button
              className="flex justify-start items-center gap-3 py-2 px-3 rounded-xl border border-dsblue-100 hover:bg-dsblue-100 hover:text-white"
              onClick={() => {
                setOnCameraScan2(!onCameraScan2);
                setOnCameraScan1(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                />
              </svg>
              <p>Use camera scan</p>
            </button>
          </div>
          <div>
            <p className=" text-lg">Tips: to use this to change status </p>

            <p className="text-sm">Option 1: Use scanner scan</p>
            <p className="text-sm">Option 2: Use camera button scan</p>
          </div>
        </div>{" "}
        {onCameraScan2 && (
          <>
            <QrReader
              facingMode={"environment"}
              // delay={1000}
              onError={handleError}
              onScan={handleScan}
              // chooseDeviceId={()=>selected}
              style={{ width: "300px" }}
            />
          </>
        )}
        {currentScanInput !== "" && <p>{currentScanInput}</p>}
      </div>
    );
  };

  return (
    <div>
      <ShopLayout describtion="action">
        <div className=" text-dsblue-100 flex flex-col gap-4">
          <p className=" text-lg w-full text-center text-white rounded-xl bg-dsblue-100 py-3">
            All Status action
          </p>
          <div>
            {/* this section will change the order to next status except the last one  */}
            {nextStatus()}
          </div>
          <p className=" text-lg w-full text-center text-white rounded-xl bg-dsblue-100 py-3">
            Individual Status action
          </p>
          <div>
            {/* this section have one box for each status to change */}
            {individualStatus()}
          </div>
        </div>
        {modal()}
      </ShopLayout>
    </div>
  );
}
