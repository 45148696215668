import React, { useEffect, useState, Fragment, useRef } from "react";
import CustomerLayout from "../../../component/costumer/customerLayout";
import NotFound from "../../../asset/img/not-found.png";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";

import order_api from "../../../api/order_api";
import config, { orderStatus } from "../../../config";
import UpdateOrderForm from "../UpdateOrderForm/UpdateOrderForm";
import globalStore from "../../../store/globalStore";
import { QRNormal } from "react-qrbtf";
import Loading from "../../../component/general/loading/loading";

// import { QRCodeSVG } from "qrcode.react";

export default function OrderDetail() {
  const navigate = useNavigate();
  const order_id = window.location.href.split("/").pop().split("#")[0];
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentRollIndex, setCurrentRollIndex] = useState(0);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (isLoading) {
      order_api
        .CustomerHaveAccessToOrder(
          order_id,
          globalStore.getState().session.user._id
        )
        .then(async (res) => {
          if (res.status === true) {
            order_api.OrderHistoryById(order_id).then((res) => {
              // console.log("res", res);
              setOrder(res);
              setIsLoading(false);
            });
          } else {
            navigate("/orderHistory/accessDeny");
          }
        })
        .catch((err) => {
          navigate("/orderHistory/accessDeny");
        });
    }
  }, []);

  const topInfo = () => {
    const getDate = (date) => {
      // 2023-08-07T16:23:08.710Z
      // need 2023-08-07

      let month = date.slice(5, 7);
      let day = date.slice(8, 10);
      let year = date.slice(0, 4);
      return year + "-" + month + "-" + day;
    };
    const countOrderTotalPrice = (order) => {
      let total = 0;
      order.forEach((item) => {
        total += item.Price.totalPrice;
      });
      return total;
    };

    const statusBar = (status) => {
      var style = "";
      if (config.orderStatus[0] === status) {
        style = "rounded-lg mb-4 py-4 bg-green-500 text-white ";
      } else if (config.orderStatus[1] === status) {
        style = "rounded-lg mb-4 py-4 bg-yellow-600 text-white ";
      } else if (config.orderStatus[2] === status) {
        style = "rounded-lg mb-4 py-4 bg-blue-500 text-white ";
      } else if (config.orderStatus[3] === status) {
        style = "rounded-lg mb-4 py-4 bg-purple-500 text-white ";
      } else if (config.orderStatus[4] === status) {
        style = "rounded-lg mb-4 py-4 bg-red-500 text-white ";
      } else {
        style = "rounded-lg mb-4 py-4 bg-green-500 text-white ";
      }
      return (
        <div className={style}>
          <p className=" text-center ">{status}</p>
        </div>
      );
    };

    return (
      <div>
        {statusBar(order.currentStatus)}
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-3">
            <div className=" rounded-lg bg-dsblue-90 text-white text-sm px-3 py-2">
              {getDate(order.orderDate)}
            </div>
            <div className=" rounded-lg bg-dsblue-90 text-white text-sm px-3 py-2">
              {order.order.length + " Roll"}
            </div>
          </div>
          <div className="flex justify-end items-center gap-3">
            <div className=" rounded-lg bg-dsblue-90 text-white text-sm px-3 py-2">
              {"$" + countOrderTotalPrice(order.order)}
            </div>
            {/* <div>
              <UpdateOrderForm></UpdateOrderForm>
            </div> */}
            {
              // if current stuatus is not config.orderStatus[0] then hide the cancel button
              config.orderStatus[0] !== order.currentStatus ? null : (
                <UpdateOrderForm></UpdateOrderForm>
              )
            }
          </div>
        </div>
      </div>
    );
  };

  const rollInfo = () => {
    const rollMaping = () => {
      return order.order.map((roll, index) => {
        index = index + 1;
        return (
          <div
            className="carousel-item relative text-dsblue-90 py-4  w-full place-items-start"
            id={"slide" + index}
          >
            <div className="flex  flex-col px-8 md:px-20 w-full">
              <div className=" pb-3 flex justify-between items-center">
                <p className="text-lg font-Gilroy-m">Roll {index}</p>
                <p>${roll.Price.totalPrice}</p>
              </div>

              <div>
                <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                  Develop detail:
                </p>
                <div className=" flex justify-between items-center gap-3 pt-3 ">
                  <div className="grid grid-flow-row md:flex gap-3">
                    <div className="badge text-white">{roll.info?.size} mm</div>

                    <div className="badge text-white">
                      {roll.info?.chamical}
                    </div>

                    <div className="badge text-white">
                      {roll.info?.exposure} photos
                    </div>
                  </div>
                  <div>
                    <div className=" text-dsblue-90">$ {roll.info?.price}</div>
                  </div>
                </div>
              </div>
              {roll.dev_addon?.push_pull || roll.dev_rush?.dev_rush ? (
                <div className="pt-3">
                  <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                    Develop add on detail:
                  </p>
                  <div className=" flex justify-between items-center gap-3 pt-3 ">
                    <div className="grid grid-flow-row md:flex  gap-3">
                      {roll.dev_addon?.push_pull ? (
                        <div className="badge text-white">
                          {"$" +
                            roll.dev_addon?.price +
                            " " +
                            roll.dev_addon?.pushOrPull}
                        </div>
                      ) : null}

                      {roll.dev_rush?.dev_rush ? (
                        <div className="badge text-white">
                          $ {roll.dev_rush?.price} Rush service
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <div className=" text-dsblue-90">
                        $ {roll.dev_addon.price + roll.dev_rush.price}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {roll.scan?.scan ? (
                <div className="pt-3">
                  <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                    Scan detail:
                  </p>
                  <div className=" flex justify-between items-center gap-3 pt-3 ">
                    <div className="grid grid-flow-row md:flex  gap-3">
                      <div className="badge text-white">
                        {roll.scan?.scanRes}
                      </div>

                      {roll.scan_addon?.tiff ? (
                        <div className="badge text-white">
                          {roll.scan?.incloudTiff
                            ? "Include Tiff file"
                            : "$" + roll.scan_addon.price + " Add Tiff file"}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <div className=" text-dsblue-90">
                        $ {roll.scan.price + roll.scan_addon.price}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {roll.print?.prints ? (
                <div className="pt-3">
                  <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                    Print detail:
                  </p>
                  <div className=" flex justify-between items-center gap-3 pt-3 ">
                    <div className="grid grid-flow-row md:flex  gap-3">
                      <div className="badge text-white">
                        {roll.print?.prtintSize}
                      </div>
                      <div className="badge text-white">
                        {roll.print?.print_set} Set
                      </div>

                      {/* {roll.print?.border !== null &&
                      roll.print?.border !== "" &&
                      roll.print?.border !== undefined ? (
                        <div className="badge text-white">Add border</div>
                      ) : null}
                      {roll.print?.print_finish !== "" &&
                      roll.print?.print_finish !== null &&
                      roll.print?.print_finish !== undefined &&
                      roll.print?.print_finish ? (
                        <div className="badge text-white">
                          {roll.print?.print_finish} Finish
                        </div>
                      ) : null} */}
                      {roll.print_border?.border !== null &&
                      roll.print_border?.border !== "" &&
                      roll.print_border?.border !== undefined &&
                      roll.print_border?.border ? (
                        <div className="badge text-white">Add border</div>
                      ) : null}
                      {roll.print_finish?.print_finish !== "" &&
                      roll.print_finish?.print_finish !== null &&
                      roll.print_finish?.print_finish !== undefined &&
                      roll.print_finish?.print_finish ? (
                        <div className="badge text-white">
                          {roll.print_finish?.print_finish} Finish
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <div className=" text-dsblue-90">
                        ${" "}
                        {roll.print.price +
                          roll.print_border.price +
                          roll.print_finish.price}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="absolute flex justify-between transform -translate-y-1/2 left-1 right-1 md:left-3 md:right-3 top-1/2 ">
              {/* <a href={"#slide" + (index - 1)} className="btn btn-xs btn-ghost">
                ❮
              </a>
              <a href={"#slide" + (index + 1)} className="btn btn-xs btn-ghost">
                ❯
              </a> */}
              {index < 2 ? (
                <a href="" className=""></a>
              ) : (
                <a
                  href={"#slide" + (index - 1)}
                  className="btn btn-xs md:btn-lg btn-ghost"
                >
                  ❮
                </a>
              )}
              {index === order.order.length ? (
                <a
                  href=""
                  // className="btn btn-xs btn-ghost"
                ></a>
              ) : (
                <a
                  href={"#slide" + (index + 1)}
                  className="btn btn-xs md:btn-lg btn-ghost"
                >
                  ❯
                </a>
              )}
            </div>
          </div>
        );
      });
    };
    return (
      <div className=" pt-4 mt-2">
        <div className="carousel w-full rounded-lg  bg-dsblue-10">
          {rollMaping()}
        </div>
        <div className=" flex justify-center">
          <div className="flex items-end justify-center w-full mt-4 py-2 gap-2 max-w-lg overflow-x-auto">
            {order.order.map((roll, index) => {
              index = index + 1;
              return (
                <a href={"#slide" + index} className="btn btn-xs">
                  {index}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const otherInfo = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-3 pt-4 gap-y-2 md:gap-y-0">
        <div
          className=" rounded-lg px-3 py-2 bg-dsblue-10 w-full max-h-[450px] overflow-y-auto"
          style={{
            wordBreak: "break-all",
          }}
        >
          {/* use textarea to display order note from store  */}
          <p className="text-left  pb-1 font-Gilroy-m text-dsblue-90">
            Lab Note:
          </p>{" "}
          {config.usertype.customer.includes(
            globalStore.getState().session.user.usertype
          ) ? (
            <p className="text-sm py-2 text-left text-dsblue-60 ">
              {order.shopNotes.length < 1 ? (
                <img
                  src={NotFound}
                  className="object-contain w-1/2 h-48 mx-auto"
                ></img>
              ) : (
                // loop through shop notes and list them in a dotted list
                <ul className="list-disc list-inside">
                  {order.shopNotes.map((note) => {
                    const isUrl = /^(ftp|http|https):\/\/[^ "]+$/.test(note);
                    return isUrl ? (
                      <li>
                        <a
                          href={note}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {note}
                        </a>
                      </li>
                    ) : (
                      <li style={{ wordBreak: "break-all" }}>{note}</li>
                    );
                  })}
                </ul>
              )}
            </p>
          ) : (
            <textarea
              value={order.shopNotes[0]}
              onChange={(e) => {
                // e.preventDefault();
                setOrder({ ...order, shopNotes: [e.target.value] });
              }}
              className="text-sm px-2 py-2 text-left text-dsblue-60 w-full h-48"
            ></textarea>
          )}
        </div>
        <div className=" rounded-lg px-3 py-2 bg-dsblue-10 col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4 md:divide-x divide-dsblue-80">
          {/* display shop info from order.store
           */}

          <div>
            <p className="text-left  pb-1 font-Gilroy-m text-dsblue-90 ">
              Lab:
            </p>{" "}
            <p className="text-sm py-2 text-left text-dsblue-60">
              {order.store.shopName}
            </p>
            <p className="text-left  pb-1 font-Gilroy-m text-dsblue-90">
              Address:
            </p>{" "}
            <p className="text-sm py-2 text-left text-dsblue-60">
              {order.store.shopAddress}
            </p>
            <p className="text-left  pb-1 font-Gilroy-m text-dsblue-90">
              Phone:
            </p>
            <div className="flex flex-col justify-start items-start text-dsblue-60">
              {order.store.shopPhones?.map((phone) => {
                return <p>{phone}</p>;
              })}
            </div>
          </div>

          <div className="flex justify-center bg-dsblue-10 ">
            <div className="flex flex-col justify-center">
              <p className="text-lg text-center">Order QRCode:</p>
              <p className="text-sm text-center">Scan to see detail</p>
              {/* <QRCodeSVG value={order_id} /> */}
              <QRNormal
                value={
                  // order._id
                  //current url + order id
                  window.location.href
                }
                // value="123"
                // className="pl-1.5"
                styles={{ svg: { width: "250px" } }}
                funcType="B"
                size={100}
                opacity={80}
                // posType="roundRect"
                lineColor="#798998"
                posColor="#203B54"
                direction="loop"
                // ></QRLine>
              ></QRNormal>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteOrder = () => {
    const handleDelete = () => {
      if (window.confirm("Are you sure you want to delete this order?")) {
        order_api.DeleteOrderById(order_id).then((res) => {
          // console.log("res", res);
          navigate("/orderHistory");
        });
      }
    };
    return (
      <button
        className={
          order.currentStatus !== config.orderStatus[0]
            ? "hidden"
            : "mt-4 py-4 bg-red-500 rounded-lg w-full text-white block"
        }
        //onclick to let user confirm to delete order
        //delete order from db
        //redirect to order history page
        onClick={(e) => {
          e.preventDefault();
          handleDelete();
          //   order_api.DeleteOrder(order_id).then((res) => {
          //     console.log("res", res);
          //     window.location.href = "/customer/orderHistory";
          //   });
        }}
      >
        Delete Order
      </button>
    );
  };
  return (
    <div>
      <CustomerLayout describtion="order">
        {isLoading ? (
          <div>
            <Loading></Loading>
          </div>
        ) : (
          <div>
            <div className=" overflow-auto ">
              <div>{topInfo()}</div>
              <div>{rollInfo()}</div>
              <div>{otherInfo()}</div>
              <div>{deleteOrder()}</div>
            </div>
          </div>
        )}
      </CustomerLayout>
    </div>
  );
}
