import React, { useState, useEffect } from "react";
import { sessionService } from "redux-react-session";
import globalStore from "../../../store/globalStore";
import styles from "./styles.js";
import film from "../../../asset/img/photography.png";
import config, { usertype } from "../../../config";
import user_api from "../../../api/user_api.js";
import { useNavigate } from "react-router-dom";

export default function Menu(props) {
  const navigate = useNavigate();
  const handleLogout = async () => {
    await sessionService.deleteSession();
    await sessionService.deleteUser();
    navigate("/login");
  };
  const currentPageStyle = {
    dashboard: "",
    profile: "",
    order: "",
    services: "",
    labDetail: "",
    action: "",
    businessStatus: "",
  };

  if (props.describtion === "dashboard") {
    currentPageStyle.dashboard = " text-dsblue-100";
  } else if (props.describtion === "profile") {
    currentPageStyle.profile = " text-dsblue-100";
  } else if (props.describtion === "order") {
    currentPageStyle.order = " text-dsblue-100";
  } else if (props.describtion === "services") {
    currentPageStyle.services = " text-dsblue-100";
  } else if (props.describtion === "labDetail") {
    currentPageStyle.labDetail = " text-dsblue-100";
  } else if (props.describtion === "action") {
    currentPageStyle.action = " text-dsblue-100";
  } else if (props.describtion === "businessStatus") {
    currentPageStyle.businessStatus = " text-dsblue-100";
  }

  return (
    <div className="lg:h-full">
      <div className="h-full grid content-between ">
        {/* <BigLogo link="/classrooms"></BigLogo> */}
        <ul className={styles.menuAdaptive + "rounded-none"}>
          <li className={styles.btn + currentPageStyle.dashboard}>
            <a href="/dashboard" className="flex justify-start items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke={
                    props.describtion === "dashboard" ? "#203B54" : "#798998"
                  }
                  d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
                />
              </svg>

              <p
                className={"pl-2 hidden lg:block " + currentPageStyle.dashboard}
              >
                Dashboad
              </p>
            </a>
          </li>
          <li className={styles.btn + currentPageStyle.order}>
            <a href="/orderHistory" className="flex justify-start items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke={props.describtion === "order" ? "#203B54" : "#798998"}
                  d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
                />
              </svg>

              <p className={"pl-2 hidden lg:block " + currentPageStyle.order}>
                Orders
              </p>
            </a>
          </li>

          {usertype.labAdmin.includes(
            globalStore.getState().session.user.usertype
          ) ? (
            <li className={styles.btn + currentPageStyle.businessStatus}>
              <a
                href={"/businessStatus"}
                className="flex justify-start items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke={
                      props.describtion === "businessStatus"
                        ? "#203B54"
                        : "#798998"
                    }
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                  />
                  <path
                    stroke={
                      props.describtion === "businessStatus"
                        ? "#203B54"
                        : "#798998"
                    }
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                  />
                </svg>
                <p
                  className={
                    "pl-2 hidden lg:block" + currentPageStyle.businessStatus
                  }
                >
                  Status
                </p>
              </a>
            </li>
          ) : null}

          <li className={styles.btn + currentPageStyle.services}>
            <a
              href={
                usertype.customer.includes(
                  globalStore.getState().session.user.usertype
                )
                  ? "/services"
                  : "/manageServices"
              }
              className="flex justify-start items-center"
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke={
                    props.describtion === "services" ? "#203B54" : "#798998"
                  }
                  d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"
                />
              </svg>
              <p className={"pl-2 hidden lg:block" + currentPageStyle.services}>
                Services
              </p>
            </a>
          </li>

          {!usertype.customer.includes(
            globalStore.getState().session.user.usertype
          ) ? (
            <li className={styles.btn + currentPageStyle.labDetail}>
              <a
                href={"/labDetail"}
                className="flex justify-start items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke={
                      props.describtion === "labDetail" ? "#203B54" : "#798998"
                    }
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                  />
                </svg>
                <p
                  className={
                    "pl-2 hidden lg:block" + currentPageStyle.labDetail
                  }
                >
                  Lab Detail
                </p>
              </a>
            </li>
          ) : null}

          {!usertype.customer.includes(
            globalStore.getState().session.user.usertype
          ) ? (
            <li className={styles.btn + currentPageStyle.action}>
              <a
                href={"/labAction"}
                className="flex justify-start items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke={
                      props.describtion === "action" ? "#203B54" : "#798998"
                    }
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                  />
                  <path
                    stroke={
                      props.describtion === "action" ? "#203B54" : "#798998"
                    }
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                  />
                </svg>
                <p className={"pl-2 hidden lg:block" + currentPageStyle.action}>
                  Lab Action
                </p>
              </a>
            </li>
          ) : null}

          <li className={styles.btn + currentPageStyle.profile}>
            <a href="/profile" className="flex justify-start items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke={
                    props.describtion === "profile" ? "#203B54" : "#798998"
                  }
                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                />
              </svg>

              <p className={"pl-2 hidden lg:block" + currentPageStyle.profile}>
                Profile
              </p>
            </a>
          </li>
          <li className={styles.btn}>
            <button
              className="flex justify-start items-center"
              onClick={handleLogout}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                />
              </svg>

              <p className="pl-2  hidden lg:block">Logout</p>
            </button>
          </li>
        </ul>
        <div className=" flex justify-center pb-8">
          <img className="w-20 xl:w-24 " src={film}></img>
        </div>
      </div>
    </div>
  );
}
