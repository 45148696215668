import React, { useEffect, useState, Fragment, useRef } from "react";
import { Transition } from "@headlessui/react";
import { Dialog } from "@headlessui/react";

export default function LearnOnChemical() {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const modal = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                <div className="bg-white px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90 flex flex-col gap-6">
                  <div className="flex flex-col justify-center items-center gap-1">
                    <p className=" font-Gilroy-m text-lg">
                      Look for word on Canister/lables:
                    </p>
                    <p className="px-1 rounded-md border border-dsblue-100">
                      PROCESS/process
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center gap-1">
                    <p className=" font-Gilroy-m text-lg">Disposable camera:</p>
                    <div className="flex  gap-3 justify-start items-center">
                      <p>If color negative film</p>
                      <p className="px-1 rounded-md border border-dsblue-100">
                        C-41
                      </p>
                    </div>
                    <div className="flex  gap-3 justify-start items-center">
                      <p>If black and white film</p>
                      <p className="px-1 rounded-md border border-dsblue-100">
                        Black and White
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
  return (
    <div>
      <button
        className="text-dsblue-100 border-2 border-dsblue-100 hover:bg-dsblue-100 hover:text-white rounded-md px-1 py-1 text-xs"
        onClick={() => {
          setOpen(true);
        }}
      >
        Find out on Chemical
      </button>
      {modal}
    </div>
  );
}
