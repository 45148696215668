import React, { useEffect, useState, Fragment, useRef } from "react";
import CustomerLayout from "../../../component/costumer/customerLayout";
import NotFound from "../../../asset/img/not-found.png";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";

import order_api from "../../../api/order_api";
import config, { orderStatus, usertype } from "../../../config";
import UpdateOrderForm from "../UpdateOrderForm/UpdateOrderForm";
import globalStore from "../../../store/globalStore";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import "./style.css";
import Barcode from "react-barcode";
import { QRNormal } from "react-qrbtf";

// import { QRCodeSVG } from "qrcode.react";
import ShopLayout from "../../../component/shop/shopLayout";
import isUrl from "is-url";

import { Menu, Disclosure } from "@headlessui/react";
import Loading from "../../../component/general/loading/loading";

export default function OrderDetail() {
  const navigate = useNavigate();
  const order_id = window.location.href.split("/").pop().split("#")[0];
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentRollIndex, setCurrentRollIndex] = useState(0);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState("");

  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = useRef(null);

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("old boring text");
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const [access, setAccess] = useState(0);

  useEffect(() => {
    if (isLoading) {
      // var access = false;
      if (
        config.usertype.labAdmin[0] !==
        globalStore.getState().session.user.usertype
      ) {
        order_api
          .ShopHaveAccessToOrder(
            order_id,
            globalStore.getState().session.user.lab_id
          )
          .then((res) => {
            //console.log("res", res);
            if (res.status === true) {
              // access = true;
              setAccess(1);
            } else {
              setAccess(-1);
            }
          })
          .catch((err) => {
            //console.log("err", err);
            setAccess(-1);
            // navigate("/orderHistory/accessDeny");
          });
      } else if (
        config.usertype.labAdmin[0] ===
        globalStore.getState().session.user.usertype
      ) {
        // access = true;
        setAccess(1);
      }
    }
  }, []);
  useEffect(() => {
    if (access === 1) {
      order_api.OrderHistoryById(order_id).then((res) => {
        //console.log("res", res);
        setOrder(res);
        setIsLoading(false);
      });
    } else if (access === -1) {
      navigate("/orderHistory/accessDeny");
    }
  }, [access]);

  const handleAfterPrint = React.useCallback(() => {
    //console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    //console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    //console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // pageStyle: `
    // @media print {
    //     .To-print {
    //         display: block !important;
    //     }
    // }
    // @media screen{
    //   .To-print{
    //     display: none;
    //   }
    // }
    // `,
    // content: () => <PrintOrder />,
  });
  const PrintOrder = () => {
    const totalOrderPrice = () => {
      let total = 0;
      order.order.forEach((roll) => {
        total += roll.Price.totalPrice;
      });
      return total;
    };
    return (
      // <Transition.Root show={open} as={Fragment}>
      //   <Dialog
      //     as="div"
      //     className="relative z-30"
      //     initialFocus={cancelButtonRef}
      //     onClose={setOpen}
      //   >
      //     <Transition.Child
      //       as={Fragment}
      //       enter="ease-out duration-300"
      //       enterFrom="opacity-0"
      //       enterTo="opacity-100"
      //       leave="ease-in duration-200"
      //       leaveFrom="opacity-100"
      //       leaveTo="opacity-0"
      //     >
      //       <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      //     </Transition.Child>

      //     <div className="fixed z-100 inset-0 overflow-y-auto">
      //       <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
      //         <Transition.Child
      //           as={Fragment}
      //           enter="ease-out duration-300"
      //           enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      //           enterTo="opacity-100 translate-y-0 sm:scale-100"
      //           leave="ease-in duration-200"
      //           leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      //           leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      //         >
      //           <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
      <div className="bg-white px-3 md:px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90">
        <div className="">
          {/* include name, email, phone */}
          <div className="flex justify-between items-center pt-16 pb-8">
            <div className="flex flex-col gap-3 pl-2">
              {/* <p className="text-lg text-dsblue-90">{order.customer?.name}</p> */}
              {/* short bar code */}

              <p className="text-sm text-dsblue-90 ">
                Order Date: {order.orderDate}
              </p>
              <Barcode
                textAlign={"left"}
                height={20}
                value={order.customer?.name}
              />
              {/* <p className="text-sm text-dsblue-90 pl-2">{order.customer?.email}</p> */}
              {/* <Barcode
              format={"EAN13"}
              textAlign={"left"}
              height={20}
              width={1}
              value={order.customer?.email}
            /> */}
              {/* <p className="text-sm text-dsblue-90 pl-2">{order.customer?.phone}</p> */}
              <Barcode
                textAlign={"left"}
                height={20}
                value={order.customer?.phone}
              />
              <p className="text-sm text-dsblue-90 ">Order# {order._id}</p>

              {/* <QRCodeSVG value={order_id} /> */}
              <QRNormal
                // value={order._id}
                value={
                  // order._id
                  //current url + order id
                  window.location.href
                }
                // className="pl-1.5"
                styles={{ svg: { width: "150px" } }}
                funcType="B"
                size={100}
                opacity={80}
                // posType="roundRect"
                lineColor="#798998"
                posColor="#203B54"
                direction="loop"
              />
              <p className=" underline font-bold text-xl ">
                Total Price: ${totalOrderPrice()}
              </p>
            </div>
          </div>

          {order.order.map((roll, index) => {
            index = index + 1;
            return (
              <div className=" border border-dsblue-100">
                <div className="carousel-item relative text-dsblue-90 py-4  w-full place-items-start  ">
                  <div className="flex  flex-col px-2 w-full">
                    <div className=" pb-3 flex justify-between items-center">
                      <p className="text-lg font-Gilroy-m">Roll {index}</p>
                      <p>${roll.Price.totalPrice}</p>
                    </div>

                    <div>
                      <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                        Develop detail:
                      </p>
                      <div className=" flex justify-between items-center gap-3 pt-3 ">
                        <div className="grid grid-flow-row  gap-3">
                          <div className="badge text-white">
                            {roll.info?.size} mm
                          </div>

                          <div className="badge text-white">
                            {roll.info?.chamical}
                          </div>

                          <div className="badge text-white">
                            {roll.info?.exposure} photos
                          </div>
                        </div>
                        <div>
                          <div className=" text-dsblue-90">
                            $ {roll.info?.price}
                          </div>
                        </div>
                      </div>
                    </div>
                    {roll.dev_addon?.push_pull || roll.dev_rush?.dev_rush ? (
                      <div className="pt-3">
                        <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                          Develop add on detail:
                        </p>
                        <div className=" flex justify-between items-center gap-3 pt-3 ">
                          <div className="grid grid-flow-row  gap-3">
                            {roll.dev_addon?.push_pull ? (
                              <div className="badge text-white">
                                {"$" +
                                  roll.dev_addon?.price +
                                  " " +
                                  roll.dev_addon?.pushOrPull}
                              </div>
                            ) : null}

                            {roll.dev_rush?.dev_rush ? (
                              <div className="badge text-white">
                                $ {roll.dev_rush?.price} Rush service
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <div className=" text-dsblue-90">
                              $ {roll.dev_addon.price + roll.dev_rush.price}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {roll.scan?.scan ? (
                      <div className="pt-3">
                        <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                          Scan detail:
                        </p>
                        <div className=" flex justify-between items-center gap-3 pt-3 ">
                          <div className="grid grid-flow-row  gap-3">
                            <div className="badge text-white">
                              {roll.scan?.scanRes}
                            </div>

                            {roll.scan_addon?.tiff ? (
                              <div className="badge text-white">
                                {roll.scan?.incloudTiff
                                  ? "Include Tiff file"
                                  : "$" +
                                    roll.scan_addon.price +
                                    " Add Tiff file"}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <div className=" text-dsblue-90">
                              $ {roll.scan.price + roll.scan_addon.price}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {roll.print?.prints ? (
                      <div className="pt-3">
                        <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                          Print detail:
                        </p>
                        <div className=" flex justify-between items-center gap-3 pt-3 ">
                          <div className="grid grid-flow-row  gap-3">
                            <div className="badge text-white">
                              {roll.print?.prtintSize}
                            </div>
                            <div className="badge text-white">
                              {roll.print?.print_set} Set
                            </div>

                            {/* {roll.print?.border !== null &&
                            roll.print?.border !== "" &&
                            roll.print?.border !== undefined ? (
                              <div className="badge text-white">Add border</div>
                            ) : null}
                            {roll.print?.print_finish !== "" &&
                            roll.print?.print_finish !== null &&
                            roll.print?.print_finish !== undefined &&
                            roll.print?.print_finish ? (
                              <div className="badge text-white">
                                {roll.print?.print_finish} Finish
                              </div>
                            ) : null} */}
                            {roll.print_border?.border !== null &&
                            roll.print_border?.border !== "" &&
                            roll.print_border?.border !== undefined &&
                            roll.print_border?.border ? (
                              <div className="badge text-white">Add border</div>
                            ) : null}
                            {roll.print_finish?.print_finish !== "" &&
                            roll.print_finish?.print_finish !== null &&
                            roll.print_finish?.print_finish !== undefined &&
                            roll.print_finish?.print_finish ? (
                              <div className="badge text-white">
                                {roll.print_finish?.print_finish} Finish
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <div className=" text-dsblue-90">
                              $ {roll.print.price}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      //           </Dialog.Panel>
      //         </Transition.Child>
      //       </div>
      //     </div>
      //   </Dialog>
      // </Transition.Root>
    );
  };

  const topInfo = () => {
    const getDate = (date) => {
      // 2023-08-07T16:23:08.710Z
      // need 2023-08-07

      let month = date.slice(5, 7);
      let day = date.slice(8, 10);
      let year = date.slice(0, 4);
      return year + "-" + month + "-" + day;
    };
    const countOrderTotalPrice = (order) => {
      let total = 0;
      order.forEach((item) => {
        total += item.Price.totalPrice;
      });
      return total;
    };

    const handleDropDownSelection = (order, status) => {
      //update order status
      //console.log("order", order);
      // alert user if they want to change order status
      if (
        window.confirm(
          "Are you sure you want to change the order status to " + status + "?"
        )
      ) {
        order_api.UpdateOrderStatusById(order, status).then(async (res) => {
          //console.log("res", res);
          //refresh order history
          setOrder({
            ...order,
            currentStatus: status,
          });
        });
      }
    };

    const dropdown = (order) => {
      return config.orderStatus
        .filter((sta) => sta !== order.currentStatus)
        .map((sta, index) => {
          var style = "";
          if (config.orderStatus[0] === sta) {
            style = "rounded-lg py-2 hover:bg-green-500 hover:text-white ";
          } else if (config.orderStatus[1] === sta) {
            style = "rounded-lg py-2 hover:bg-yellow-600 hover:text-white ";
          } else if (config.orderStatus[2] === sta) {
            style = "rounded-lg py-2 hover:bg-blue-500 hover:text-white ";
          } else if (config.orderStatus[3] === sta) {
            style = "rounded-lg py-2 hover:bg-purple-500 hover:text-white ";
          } else if (config.orderStatus[4] === sta) {
            style = "rounded-lg py-2 hover:bg-red-500 hover:text-white ";
          } else {
            style = "rounded-lg py-2 hover:bg-green-500 hover:text-white ";
          }
          return (
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? " text-violet-100" : "text-dsblue-100",
                    "block px-4 text-sm w-full text-center" + style
                  )}
                  onClick={(e) => handleDropDownSelection(order, sta)}
                >
                  {sta}
                </button>
              )}
            </Menu.Item>
          );
        });
    };

    const statusBar = (status) => {
      var style = "";
      if (config.orderStatus[0] === status) {
        style = "rounded-lg mb-4 py-4 bg-green-500 text-white ";
      } else if (config.orderStatus[1] === status) {
        style = "rounded-lg mb-4 py-4 bg-yellow-600 text-white ";
      } else if (config.orderStatus[2] === status) {
        style = "rounded-lg mb-4 py-4 bg-blue-500 text-white ";
      } else if (config.orderStatus[3] === status) {
        style = "rounded-lg mb-4 py-4 bg-purple-500 text-white ";
      } else if (config.orderStatus[4] === status) {
        style = "rounded-lg mb-4 py-4 bg-red-500 text-white ";
      } else {
        style = "rounded-lg mb-4 py-4 bg-green-500 text-white ";
      }
      return (
        <div className={style}>
          <p className=" text-center ">{status}</p>
        </div>
      );
    };

    return (
      <div>
        <Menu as="div" className="relative text-left w-full">
          <Menu.Button className="w-full">
            {/* <p className="border border-green-500 rounded-lg px-1 text-center text-green-500">
                    {order.currentStatus}
                  </p> */}

            <div className="w-full">{statusBar(order.currentStatus)}</div>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-left absolute z-20 left-0  w-full rounded-md shadow-lg bg-white  ring-opacity-5 focus:outline-none overflow-y-auto">
              <div className="py-1 ">{dropdown(order)}</div>
            </Menu.Items>
          </Transition>
        </Menu>
        {/* {statusBar(order.currentStatus)} */}
        <div className="flex flex-wrap gap-3 justify-start md:justify-between items-center">
          <div className="flex justify-start items-center gap-3">
            <div className=" rounded-lg bg-dsblue-90 text-white text-sm px-3 py-2">
              {getDate(order.orderDate)}
            </div>
            <div className=" rounded-lg bg-dsblue-90 text-white text-sm px-3 py-2">
              {order.order.length + " Roll"}
            </div>
          </div>
          <div className="flex justify-end items-center gap-3">
            <div className=" rounded-lg bg-dsblue-90 text-white text-sm px-3 py-2">
              {"$" + countOrderTotalPrice(order.order)}
            </div>
            <div>
              <UpdateOrderForm></UpdateOrderForm>
            </div>
            <div>
              <button
                className="bg-dsblue-90 text-white text-sm px-3 py-2 rounded-lg"
                onClick={() => {
                  // setOpen(true);
                  // setTimeout(() => {
                  //   handlePrint();
                  // }, 1000);
                  navigate("/toprint/" + order_id);
                }}
              >
                Print Order
              </button>
              {/* <PrintOrder ref={componentRef} /> */}
              {/* <div ref={componentRef}>
                <style>
                  {`
                    @media print {
                        .To-print {
                            display: block !important;
                            visibility: visible !important;
                        }
                    }
                    @media only screen and {
                      .To-print{
                        display: none;

                      }
                    }
                `}
                </style>

                <div class="To-print">
                  <PrintOrder />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const rollInfo = () => {
    const rollMaping = () => {
      return order.order.map((roll, index) => {
        index = index + 1;
        return (
          <div
            className="carousel-item relative text-dsblue-90 py-4  w-full place-items-start"
            id={"slide" + index}
          >
            <div className="flex  flex-col px-8 md:px-20 w-full">
              <div className=" pb-3 flex justify-between items-center">
                <p className="text-lg font-Gilroy-m">Roll {index}</p>
                <p>${roll.Price.totalPrice}</p>
              </div>

              <div>
                <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                  Develop detail:
                </p>
                <div className=" flex justify-between items-center gap-3 pt-3 ">
                  <div className="grid grid-flow-row md:flex gap-3">
                    <div className="badge text-white">{roll.info?.size} mm</div>

                    <div className="badge text-white">
                      {roll.info?.chamical}
                    </div>

                    <div className="badge text-white">
                      {roll.info?.exposure} photos
                    </div>
                  </div>
                  <div>
                    <div className=" text-dsblue-90">$ {roll.info?.price}</div>
                  </div>
                </div>
              </div>
              {roll.dev_addon?.push_pull || roll.dev_rush?.dev_rush ? (
                <div className="pt-3">
                  <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                    Develop add on detail:
                  </p>
                  <div className=" flex justify-between items-center gap-3 pt-3 ">
                    <div className="grid grid-flow-row md:flex  gap-3">
                      {roll.dev_addon?.push_pull ? (
                        <div className="badge text-white">
                          {"$" +
                            roll.dev_addon?.price +
                            " " +
                            roll.dev_addon?.pushOrPull}
                        </div>
                      ) : null}

                      {roll.dev_rush?.dev_rush ? (
                        <div className="badge text-white">
                          $ {roll.dev_rush?.price} Rush service
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <div className=" text-dsblue-90">
                        $ {roll.dev_addon.price + roll.dev_rush.price}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {roll.scan?.scan ? (
                <div className="pt-3">
                  <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                    Scan detail:
                  </p>
                  <div className=" flex justify-between items-center gap-3 pt-3 ">
                    <div className="grid grid-flow-row md:flex  gap-3">
                      <div className="badge text-white">
                        {roll.scan?.scanRes}
                      </div>

                      {roll.scan_addon?.tiff ? (
                        <div className="badge text-white">
                          {roll.scan?.incloudTiff
                            ? "Include Tiff file"
                            : "$" + roll.scan_addon.price + " Add Tiff file"}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <div className=" text-dsblue-90">
                        $ {roll.scan.price + roll.scan_addon.price}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {roll.print?.prints ? (
                <div className="pt-3">
                  <p className="px-3 py-2 rounded-lg bg-dsblue-20">
                    Print detail:
                  </p>
                  <div className=" flex justify-between items-center gap-3 pt-3 ">
                    <div className="grid grid-flow-row md:flex  gap-3">
                      <div className="badge text-white">
                        {roll.print?.prtintSize}
                      </div>
                      <div className="badge text-white">
                        {roll.print?.print_set} Set
                      </div>

                      {roll.print_border?.border !== null &&
                      roll.print_border?.border !== "" &&
                      roll.print_border?.border !== undefined &&
                      roll.print_border?.border ? (
                        <div className="badge text-white">Add border</div>
                      ) : null}
                      {roll.print_finish?.print_finish !== "" &&
                      roll.print_finish?.print_finish !== null &&
                      roll.print_finish?.print_finish !== undefined &&
                      roll.print_finish?.print_finish ? (
                        <div className="badge text-white">
                          {roll.print_finish?.print_finish} Finish
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <div className=" text-dsblue-90">
                        ${" "}
                        {roll.print.price +
                          roll.print_border.price +
                          roll.print_finish.price}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="absolute flex justify-between transform -translate-y-1/2 left-1 right-1 md:left-3 md:right-3 top-1/2 ">
              {/* <a href={"#slide" + (index - 1)} className="btn btn-xs btn-ghost">
                ❮
              </a>
              <a href={"#slide" + (index + 1)} className="btn btn-xs btn-ghost">
                ❯
              </a> */}
              {index < 2 ? (
                <a href="" className=""></a>
              ) : (
                <a
                  href={"#slide" + (index - 1)}
                  className="btn btn-xs md:btn-lg btn-ghost"
                >
                  ❮
                </a>
              )}
              {index === order.order.length ? (
                <a
                  href=""
                  // className="btn btn-xs btn-ghost"
                ></a>
              ) : (
                <a
                  href={"#slide" + (index + 1)}
                  className="btn btn-xs md:btn-lg btn-ghost"
                >
                  ❯
                </a>
              )}
            </div>
          </div>
        );
      });
    };
    return (
      <div className=" pt-4 mt-2">
        <div className="carousel w-full rounded-lg  bg-dsblue-10">
          {rollMaping()}
        </div>
        <div className=" flex justify-center">
          <div className="flex items-end justify-center w-full mt-4 py-2 gap-2 max-w-lg overflow-x-auto">
            {order.order.map((roll, index) => {
              index = index + 1;
              return (
                <a href={"#slide" + index} className="btn btn-xs">
                  {index}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const otherInfo = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 pt-4">
        <div className="flex flex-col gap-3">
          <div className="px-3 py-3 bg-dsblue-10 rounded-lg flex justify-between">
            <div>
              <p className="text-lg ">Customer Info:</p>
              <div className="flex flex-col">
                <p className="text-sm text-dsblue-90">Name:</p>
                <p className="text-sm text-dsblue-90 pb-3">
                  {order.customer?.name}
                </p>
                <p className="text-sm text-dsblue-90">Email:</p>
                <p className="text-sm text-dsblue-90  pb-3 break-words">
                  {order.customer?.email}
                </p>
                <p className="text-sm text-dsblue-90">Phone:</p>
                <p className="text-sm text-dsblue-90">
                  {order.customer?.phone}
                </p>
              </div>
            </div>

            <div className=" bg-dsblue-10 rounded-lg">
              <p className="text-lg ">Order QRCode:</p>
              <div className="flex flex-col">
                {/* <QRCodeSVG value={order_id} /> */}
                <QRNormal
                  // value={order._id}
                  value={
                    // order._id
                    //current url + order id
                    window.location.href
                  }
                  // value="123"
                  // className="pl-1.5"
                  styles={{ svg: { width: "160px" } }}
                  funcType="B"
                  size={100}
                  opacity={80}
                  // posType="roundRect"
                  lineColor="#798998"
                  posColor="#203B54"
                  direction="loop"
                  // ></QRLine>
                ></QRNormal>
              </div>
            </div>
          </div>
          <div className=" rounded-lg px-3 py-3 bg-dsblue-10">
            {/* use textarea to display order note from store  */}
            <p className="text-left  pb-1 font-Gilroy-m text-dsblue-90">
              Lab Note:
            </p>{" "}
            <div>
              <textarea
                value={currentNote}
                onChange={(e) => {
                  // e.preventDefault();
                  setCurrentNote(e.target.value);
                  //console.log("currentNote", currentNote);
                }}
                className="text-sm px-2 py-2 text-left text-dsblue-60 w-full h-48"
              ></textarea>
              <div className="flex justify-end">
                <button className=" rounded-lg border border-dsblue-100 text-dsblue-100 hover:bg-dsblue-100 hover:text-white">
                  <p
                    className="text-sm px-2 py-2 text-left  w-full "
                    onClick={(e) => {
                      e.preventDefault();
                      //console.log("currentNote", currentNote);
                      if (currentNote !== "") {
                        order_api
                          .AddNotesById(order, currentNote)
                          .then((res) => {
                            //console.log("res", res);
                            // add this note to order.shopNotes
                            setOrder({
                              ...order,
                              shopNotes: [...order.shopNotes, currentNote],
                            });
                            setCurrentNote("");
                          });
                      }
                    }}
                  >
                    Add Note
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className=" rounded-lg px-3 py-2 bg-dsblue-10 gap-4 md:divide-x divide-dsblue-80">
          <div className=" ">
            <p className="text-lg ">Previous notes:</p>
            <div className=" flex flex-col gap-3 overflow-y-auto max-h-[450px]">
              {order.shopNotes.map((note, index) => {
                return (
                  <div className="grid grid-cols-7 gap-3 content-center pb-1 font-Gilroy-m text-dsblue-90 border border-dsblue-100 px-3 py-2 rounded-lg">
                    <div className=" col-span-6">
                      {
                        // determine if this note is a ulr
                        // if it is, make it clickable
                        isUrl(note) ? (
                          <a
                            className="hover:text-dsblue-100 break-words"
                            href={note}
                            target="_blank"
                          >
                            {note}
                          </a>
                        ) : (
                          <p className="break-words">{note}</p>
                        )
                      }
                    </div>
                    <button
                      className=" flex justify-end items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm(
                            "Are you sure you want to delete this note?"
                          )
                        ) {
                          order_api
                            .DeleteNotesById(order_id, index)
                            .then((res) => {
                              //console.log("res", res);
                              // add this note to order.shopNotes
                              setOrder({
                                ...order,
                                shopNotes: [
                                  ...order.shopNotes.slice(0, index),
                                  ...order.shopNotes.slice(index + 1),
                                ],
                              });
                            });
                        }
                      }}
                    >
                      <div className="hover:text-white hover:bg-dsblue-100 rounded-lg p-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteOrder = () => {
    const handleDelete = () => {
      if (window.confirm("Are you sure you want to delete this order?")) {
        order_api.DeleteOrderById(order_id).then((res) => {
          //console.log("res", res);
          navigate("/orderHistory");
        });
      }
    };
    return (
      <button
        className={
          order.currentStatus !== config.orderStatus[0]
            ? "hidden"
            : "mt-8 py-4 bg-red-500 rounded-lg w-full text-white block"
        }
        //onclick to let user confirm to delete order
        //delete order from db
        //redirect to order history page
        onClick={(e) => {
          e.preventDefault();
          handleDelete();
          //   order_api.DeleteOrder(order_id).then((res) => {
          //     //console.log("res", res);
          //     window.location.href = "/customer/orderHistory";
          //   });
        }}
      >
        Delete Order
      </button>
    );
  };

  return (
    <div>
      <ShopLayout describtion="order">
        {isLoading ? (
          <div>
            <Loading></Loading>
          </div>
        ) : (
          <div>
            <div className=" overflow-auto ">
              <div>{topInfo()}</div>
              <div>{rollInfo()}</div>
              <div>{otherInfo()}</div>
              <div>{deleteOrder()}</div>
            </div>
          </div>
        )}
      </ShopLayout>
    </div>
  );
}
