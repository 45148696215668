class User {
  constructor(data) {
    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone;
    this.password = data.password;
    this.usertype = data.usertype;
  }
}
export default {
  User,
};
