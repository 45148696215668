import React, { useEffect, useState, Fragment, useRef } from "react";
import { Transition } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import roll135 from "../../../../asset/img/roll135.jpg"; //roll120.jpeg
import roll120 from "../../../../asset/img/roll120.jpeg";
export default function LearnOnExposure() {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const modal = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                <div className="bg-white px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90 flex flex-col gap-2 divide-y-1">
                  <p className="pt-2">
                    Exposure means how many photos you can take with your film.
                  </p>
                  <p className="pt-2">
                    You only need to select designed exposures for your film.
                  </p>
                  <p className="pt-2">
                    120/220 user only need to enter the amount of exposures you have
                    taken
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
  return (
    <div>
      <button
        className="text-dsblue-100 border-2 border-dsblue-100 hover:bg-dsblue-100 hover:text-white rounded-md px-1 py-1 text-xs"
        onClick={() => {
          setOpen(true);
        }}
      >
        Find out on Size
      </button>
      {modal}
    </div>
  );
}

// LearnOnExposure;
// LearnOnExposure;
