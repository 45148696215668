import React from "react";
import Menu from "../general/menu/menu";
import Topmenu from "../general/topmenu/topmenu";

export default function ShopLayout({ children, describtion }) {
  return (
    <div className=" max-h-screen w-screen flex flex-col lg:flex-row max-w-screen fixed h-full bg-dsblue-10">
      {/* <div className="flex flex-row h-full divide-x divide-gray-200"> */}

      <div className="hidden lg:block lg:w-64 bg-white  rounded-xl lg:ml-8 lg:mr-4 lg:mt-8 lg:mb-8 shadow-md ">
        {/* <Menubar describtion={describtion}></Menubar> */}
        <Menu describtion={describtion}></Menu>
      </div>
      <div className="block lg:hidden rounded-xl mt-2 mb-1 mx-2 shadow-md bg-white">
        <Topmenu describtion={describtion}></Topmenu>
      </div>

      <div
        className={
          "lg:w-full overflow-y-auto bg-white rounded-xl mx-2 my-2 lg:ml-4 lg:mr-8 lg:my-8  shadow-md "
        }
      >
        <div className="p-2 lg:p-8">{children}</div>
      </div>
    </div>
  );
}
