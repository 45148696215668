import React, { useEffect, useState, Fragment, useRef } from "react";
import ShopLayout from "../../../component/shop/shopLayout";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import item_api from "../../../api/item_api";
import globalStore from "../../../store/globalStore";
import { Menu, Transition, Disclosure } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";
import { usertype } from "../../../config";
import Tooltip from "@mui/material/Tooltip";
import shop_api from "../../../api/shop_api";
import Loading from "../../../component/general/loading/loading";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ManageServices() {
  const [value, setValue] = useState(0);
  const [developItems, setDevelopItems] = useState([]);
  const [scanItems, setScanItems] = useState([]);
  const [printItems, setPrintItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const cancelButtonRef = useRef(null);
  const [EditOpen, setEditOpen] = useState(false);
  const [EditScanOpen, setEditScanOpen] = useState(false);
  const [EditPrintOpen, setEditPrintOpen] = useState(false);
  const [CurrentDevItem, setCurrentDevItem] = useState({
    chamical: "",
    describtion: "",
    size: [],
    cuts: [],
    push_pull: [],
  });
  const [CurrentDevItemIndex, setCurrentDevItemIndex] = useState(0);
  // {"_id":{"$oid":"64626d2c0c5dcd6ef28ed8a9"},"name":"scan","filmSize":"135","scans":[{"size":"135","scanRes":"16 Base","price":{"$numberInt":"11"},"incloudTiff":false},{"size":"135","scanRes":"64 Base","price":{"$numberDouble":"21.5"},"incloudTiff":true}],"tiffPrice":{"$numberInt":"2"},"shopId":"644e7ebedc63924e5a3afbf6"}
  const [CurrentScanItem, setCurrentScanItem] = useState({
    name: "",
    filmSize: "",
    scans: [],
    tiffPrice: 0,
  });
  const [CurrentScanItemIndex, setCurrentScanItemIndex] = useState(0);
  // { "_id": { "$oid": "64ac464e57e5976a692b92da" }, "name": "print", "filmSize": "135", "exposures": [{ "numberOfEXP": { "$numberInt": "12" }, "printSizes": [{ "size": "4X6", "price": { "$numberDouble": "3.0" }, "extraSetPrice": { "$numberDouble": "3.0" } }, { "size": "5X7", "price": { "$numberDouble": "6.0" }, "extraSetPrice": { "$numberDouble": "4.0" } }] }, { "numberOfEXP": { "$numberInt": "24" }, "printSizes": [{ "size": "4X6", "price": { "$numberDouble": "5.0" }, "extraSetPrice": { "$numberDouble": "5.0" } }, { "size": "5X7", "price": { "$numberDouble": "9.0" }, "extraSetPrice": { "$numberDouble": "6.0" } }] }, { "numberOfEXP": { "$numberInt": "36" }, "printSizes": [{ "size": "4X6", "price": { "$numberDouble": "8.0" }, "extraSetPrice": { "$numberDouble": "8.0" } }, { "size": "5X7", "price": { "$numberDouble": "11.0" }, "extraSetPrice": { "$numberDouble": "9.0" } }] }, { "numberOfEXP": { "$numberInt": "27" }, "printSizes": [{ "size": "4X6", "price": { "$numberDouble": "6.0" }, "extraSetPrice": { "$numberDouble": "6.0" } }, { "size": "5X7", "price": { "$numberDouble": "10.0" }, "extraSetPrice": { "$numberDouble": "7.0" } }] }], "shopId": "644e7ebedc63924e5a3afbf6", "border": null, "printFinish": null }
  const [CurrentPrintItem, setCurrentPrintItem] = useState({
    name: "print",
    filmSize: "",
    exposures: [
      {
        numberOfEXP: 0,
        printSizes: [
          {
            size: "",
            price: 0,
            extraSetPrice: 0,
          },
        ],
      },
    ],
    border: [],
    printFinish: [],
  });
  const [CurrentPrintItemIndex, setCurrentPrintItemIndex] = useState(0);

  const [inputError, setInputError] = useState({
    chamical: "",
    describtion: "",
    devSizeName: "",
    devSizePrice: "",
    devCutSize: "",
    devCutPrice: "",
    scanFilmSize: "",
    scanFileSizeName: "",
    scanFileSizePrice: "",
    scanTiffPrice: "",

    printFilmSize: "",
    printFilmExposure: "",
    printSizeName: "",
    printSizePrice: "",
    printExtraSetPrice: "",
    printBorder: "",
    printBorderPrice: "",
    printFinish: "",
    printFinishPrice: "",
  });
  // const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userAccessToChange, setUserAccessToChange] = useState(
    usertype.labAdmin.includes(globalStore.getState().session.user.usertype)
  );
  const [userAccessToSearch, setUserAccessToSearch] = useState(
    usertype.customer.includes(globalStore.getState().session.user.usertype) ||
      globalStore.getState().session.user.usertype === "admin"
  );
  const [shop_id, setShop_id] = useState(
    (usertype.labAdmin.includes(globalStore.getState().session.user.usertype) ||
      usertype.lab.includes(globalStore.getState().session.user.usertype)) &&
      globalStore.getState().session.user.usertype !== "admin"
      ? globalStore.getState().session.user.lab_id
      : ""
  );
  const [labs, setLabs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentLab, setCurrentLab] = useState({});

  // useEffect(() => {}, []);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };
  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "chamical":
          if (value === "") {
            stateObj.chamical = "Please enter a chemical name";
          }
          break;
        case "describtion":
          if (value === "") {
            stateObj.describtion = "Please enter a describtion";
          }
          break;
        case "devSizeName":
          if (value === "") {
            stateObj.devSizeName = "Please enter a size name";
          }
          break;
        case "devSizePrice":
          if (value === "") {
            stateObj.devSizePrice = "Please enter a price";
          }
          break;
        case "devCutSize":
          if (value === "") {
            stateObj.devCutSize = "Please enter a cut size";
          }
          break;
        case "devCutPrice":
          if (value === "") {
            stateObj.devCutPrice = "Please enter a price";
          }
          break;
        case "scanFilmSize":
          if (value === "") {
            stateObj.scanFilmSize = "Please enter a film size";
          }
          break;
        case "scanFileSizeName":
          if (value === "") {
            stateObj.scanFileSizeName = "Please enter a size name";
          }
          break;
        case "scanFileSizePrice":
          if (value === "") {
            stateObj.scanFileSizePrice = "Please enter a price";
          }
          break;
        case "scanTiffPrice":
          if (value === "") {
            stateObj.scanTiffPrice = "Please enter a tiff price";
          }
          break;
        case "printFilmSize":
          if (value === "") {
            stateObj.printFilmSize = "Please enter a film size";
          }
          break;
        case "printFilmExposure":
          if (value === "") {
            stateObj.printFilmExposure = "Please enter a number of exposure";
          }
          break;
        case "printSizeName":
          if (value === "") {
            stateObj.printSizeName = "Please enter a size name";
          }
          break;
        case "printSizePrice":
          if (value === "") {
            stateObj.printSizePrice = "Please enter a price";
          }
          break;
        case "printExtraSetPrice":
          if (value === "") {
            stateObj.printExtraSetPrice = "Please enter a extra set price";
          }
          break;
        case "printBorder":
          if (value === "") {
            stateObj.printBorder = "Please enter a border";
          }
          break;
        case "printBorderPrice":
          if (value === "") {
            stateObj.printBorderPrice = "Please enter a price";
          }
          break;
        case "printFinish":
          if (value === "") {
            stateObj.printFinish = "Please enter a finish";
          }
          break;
        case "printFinishPrice":
          if (value === "") {
            stateObj.printFinishPrice = "Please enter a price";
          }
          break;
        default:
          break;
      }

      return stateObj;
    });
  };

  useEffect(() => {
    //base on value to fetch data

    var seachData = "";
    if (value === 0) {
      seachData = "develope";
    } else if (value === 1) {
      seachData = "scan";
    } else if (value === 2) {
      seachData = "print";
    }
    item_api
      .GetItems(shop_id, seachData)
      .then((res) => {
        //console.log(res);
        if (value === 0) {
          setDevelopItems(res);
        } else if (value === 1) {
          setScanItems(res);
        } else if (value === 2) {
          setPrintItems(res);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setIsLoading(false);
      });
  }, [value, shop_id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Error = (error) => {
    return (
      <div>
        {error !== "" ? (
          <div className="text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg">
            {error && <span className="">{error}</span>}
          </div>
        ) : null}
      </div>
    );
  };

  const DevelopeTabPanel = () => {
    // need a onBlur for the inputs when save button is clicked

    const devModal = (
      <Transition.Root show={EditOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-100 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                  <div className="bg-white px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90  text-left">
                    <div className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                      <span>
                        Chemical:
                        <input
                          type="text"
                          className=" text-dsblue-100 p-1"
                          value={CurrentDevItem.chamical}
                          onChange={(e) => {
                            e.preventDefault();
                            setCurrentDevItem({
                              ...CurrentDevItem,
                              chamical: e.target.value,
                            });
                          }}
                          onBlur={validateInput}
                          name="chamical"
                          id="chamical"
                        ></input>
                        {Error(inputError.chamical)}
                      </span>
                      <div className="flex justify-center items-center gap-3">
                        <button
                          className={
                            EditOpen
                              ? " rounded-lg bg-dsblue-20 text-white px-3 py-2 hover:bg-dsblue-100"
                              : "hidden"
                          }
                          onClick={(e) => {
                            // make alert to confirm
                            e.preventDefault();
                            var ableToSave = true;
                            //check if there is any error
                            Object.values(inputError).forEach((val) => {
                              if (val.length > 0) {
                                ableToSave = false;
                              }
                            });
                            // find all these inputs  and make sure they are not empty
                            // chamical: "",
                            // describtion: "",
                            // devSizeName: "",
                            // devSizePrice: "",
                            // devCutSize: "",
                            // devCutPrice: "",
                            var errorWindowText = "";
                            CurrentDevItem.size.map((size) => {
                              if (size.name === "" || size.price === "") {
                                ableToSave = false;
                                errorWindowText =
                                  "Please make sure all the size name and price are not empty";
                              }
                            });
                            CurrentDevItem.cuts.map((cut) => {
                              if (cut.cutSize === "" || cut.price === "") {
                                ableToSave = false;
                                errorWindowText =
                                  "Please make sure all the cut size and price are not empty";
                              }
                            });
                            CurrentDevItem.push_pull.map((push_pull) => {
                              if (push_pull.price === "") {
                                ableToSave = false;
                                errorWindowText =
                                  "Please make sure all the push/pull price are not empty";
                              }
                            });

                            if (ableToSave) {
                              if (
                                window.confirm(
                                  "Are you sure you want to save this item?"
                                )
                              ) {
                                setEditOpen(false);
                                var apiItems = developItems;
                                if (CurrentDevItemIndex >= 0) {
                                  const temp = developItems;
                                  temp[CurrentDevItemIndex] = CurrentDevItem;
                                  // setDevelopItems(temp);
                                  apiItems = temp;
                                } else {
                                  apiItems = apiItems.concat(CurrentDevItem);
                                }

                                item_api
                                  .UpdateItems(
                                    // globalStore.getState().session.user.lab_id,
                                    shop_id,
                                    "develope",
                                    apiItems
                                  )
                                  .then((res) => {
                                    setDevelopItems(res);
                                    //reload the page
                                    window.location.reload();
                                  });
                                //console.log("save----------------------");
                                setCurrentDevItem({
                                  chamical: "",
                                  describtion: "",
                                  size: [],
                                  cuts: [],
                                  push_pull: [],
                                });
                                setCurrentDevItemIndex(0);
                              }
                            } else {
                              alert(errorWindowText);
                            }
                          }}
                        >
                          <p>Save</p>
                        </button>
                        <button
                          className={
                            EditOpen
                              ? " rounded-lg bg-dsblue-20 text-white px-3 py-2 hover:bg-dsblue-100"
                              : "hidden"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            // item_api
                            //   .GetItems(
                            //     globalStore.getState().session.user.lab_id,
                            //     "develope"
                            //   )
                            //   .then((res) => {
                            //     setDevelopItems(res);
                            //   });

                            setCurrentDevItem({
                              chamical: "",
                              describtion: "",
                              size: [],
                              cuts: [],
                              push_pull: [],
                            });
                            setCurrentDevItemIndex(0);
                            setEditOpen(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="text-sm text-gray-500  pt-4">
                      <div className=" flex flex-col gap-3 justify-between items-centerw-full">
                        <div className=" flex justify-center items-center gap-1 rounded-xl shadow-md py-2 px-2 bg-white w-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                          <input
                            className="text-dsblue-100 p-1 w-full"
                            type="text"
                            //   autoFocus
                            //   value={CurrentDevItem.describtion}
                            value={CurrentDevItem.describtion}
                            onChange={(e) => {
                              e.preventDefault();
                              setCurrentDevItem({
                                ...CurrentDevItem,
                                describtion: e.target.value,
                              });
                            }}
                            placeholder="Describtion"
                            onBlur={validateInput}
                            name="describtion"
                            id="describtion"
                          ></input>
                        </div>

                        {Error(inputError.describtion)}
                        {/* loop size */}
                        <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                          <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                            Size options
                          </p>
                          <div className="grid grid-cols-1 gap-3 pt-3">
                            {CurrentDevItem.size.map((size, index) => (
                              <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                <div>
                                  <p className="text-dsblue-100">Size name:</p>
                                  <input
                                    type="text"
                                    className=" text-dsblue-100 p-1"
                                    value={size.name}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const temp = CurrentDevItem.size;
                                      temp[index].name = e.target.value;
                                      setCurrentDevItem({
                                        ...CurrentDevItem,
                                        size: temp,
                                      });
                                    }}
                                    onBlur={validateInput}
                                    name="devSizeName"
                                    id="devSizeName"
                                  ></input>
                                </div>
                                <div>
                                  <p className="text-dsblue-100">Price:</p>
                                  <input
                                    type="number"
                                    className=" text-dsblue-100  p-1"
                                    value={size.price}
                                    onChange={(e) => {
                                      //console.log(e.target.value);
                                      //find the index of the size then update the price
                                      const temp = CurrentDevItem.size;
                                      temp[index].price = e.target.value;
                                      setCurrentDevItem({
                                        ...CurrentDevItem,
                                        size: temp,
                                      });
                                    }}
                                    onBlur={validateInput}
                                    name="devSizePrice"
                                    id="devSizePrice"
                                  ></input>
                                </div>
                                <button
                                  className=" rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //console.log("delete size");
                                    const temp = CurrentDevItem.size;
                                    temp.splice(index, 1);
                                    setCurrentDevItem({
                                      ...CurrentDevItem,
                                      size: temp,
                                    });
                                  }}
                                >
                                  <p>Delete</p>
                                </button>
                              </div>
                            ))}
                            {Error(inputError.devSizeName)}
                            {Error(inputError.devSizePrice)}
                          </div>

                          {/* add new */}
                          <button
                            className=" rounded-lg bg-green-500 text-white px-3 py-2 mt-3"
                            onClick={() => {
                              //console.log("add new size");
                              //console.log(CurrentDevItem);
                              const temp = CurrentDevItem.size.concat({
                                name: "",
                                price: 0,
                              });
                              //console.log(temp);
                              setCurrentDevItem({
                                ...CurrentDevItem,
                                size: temp,
                              });
                            }}
                          >
                            <p>Add new size</p>
                          </button>
                        </div>
                        {/* ------- */}
                        {/* loop cut */}
                        <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                          <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                            Cut options
                          </p>

                          <div className="grid grid-cols-1  gap-3 pt-3">
                            {CurrentDevItem.cuts?.map((cut, index) => (
                              <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                <div>
                                  <p className="text-dsblue-100">Cut size:</p>

                                  <input
                                    type="text"
                                    className=" text-dsblue-100  p-1"
                                    value={
                                      cut.cutSize === 0 ? "No cut" : cut.cutSize
                                    }
                                    onChange={(e) => {
                                      //console.log(e.target.value);
                                      var temp = CurrentDevItem.cuts;
                                      temp[index].cutSize = e.target.value;
                                      setCurrentDevItem({
                                        ...CurrentDevItem,
                                        cuts: temp,
                                      });
                                    }}
                                    onBlur={validateInput}
                                    name="devCutSize"
                                    id="devCutSize"
                                  ></input>
                                </div>

                                <div>
                                  <p className="text-dsblue-100">Price:</p>
                                  <input
                                    type="text"
                                    className=" text-dsblue-100  p-1"
                                    value={cut.price}
                                    onChange={(e) => {
                                      //console.log(e.target.value);
                                      var temp = CurrentDevItem.cuts;
                                      temp[index].price = e.target.value;
                                      setCurrentDevItem({
                                        ...CurrentDevItem,
                                        cuts: temp,
                                      });
                                    }}
                                    onBlur={validateInput}
                                    name="devCutPrice"
                                    id="devCutPrice"
                                  ></input>
                                </div>

                                <button
                                  className=" rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //console.log("delete cut");
                                    const temp = CurrentDevItem.cuts;
                                    temp.splice(index, 1);
                                    setCurrentDevItem({
                                      ...CurrentDevItem,
                                      cuts: temp,
                                    });
                                  }}
                                >
                                  <p>Delete</p>
                                </button>
                              </div>
                            ))}
                            {Error(inputError.devCutSize)}
                            {Error(inputError.devCutPrice)}
                          </div>

                          {/* add new */}
                          <button
                            className=" rounded-lg bg-green-500 text-white px-3 py-2 mt-3"
                            onClick={(e) => {
                              e.preventDefault();
                              //console.log("add new cut");
                              const newCut = { cutSize: 0, price: 0 };
                              const updatedCuts =
                                CurrentDevItem.cuts.concat(newCut);
                              setCurrentDevItem({
                                ...CurrentDevItem,
                                cuts: updatedCuts,
                              });
                            }}
                          >
                            <p>Add new cut</p>
                          </button>
                        </div>
                        {/* ------- */}
                        {/* loop push pull */}
                        <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                          <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                            Push/Pull options
                          </p>

                          <div className="grid grid-cols-1  gap-3 pt-3">
                            {CurrentDevItem.push_pull?.map(
                              (push_pull, index) => (
                                <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                  <div>
                                    <p className="text-dsblue-100">
                                      {push_pull.name === "push"
                                        ? "Push"
                                        : "Pull"}
                                    </p>
                                  </div>
                                  <div className="flex justify-center items-center gap-3">
                                    <p className="text-dsblue-100">Price:</p>
                                    <input
                                      type="text"
                                      className=" text-dsblue-100  p-1"
                                      value={push_pull.price}
                                      onChange={(e) => {
                                        //console.log(e.target.value);
                                        setCurrentDevItem({
                                          ...CurrentDevItem,
                                          push_pull: {
                                            ...push_pull,
                                            price: e.target.value,
                                          },
                                        });
                                      }}
                                    ></input>
                                  </div>

                                  <button
                                    className=" rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      //console.log("delete push_pull");
                                      const temp = CurrentDevItem.push_pull;
                                      temp.splice(index, 1);
                                      setCurrentDevItem({
                                        ...CurrentDevItem,
                                        push_pull: temp,
                                      });
                                    }}
                                  >
                                    <p>Delete</p>
                                  </button>
                                </div>
                              )
                            )}
                          </div>

                          {/* add new */}
                          {
                            // if push_pull is length 2, then hide the button
                            CurrentDevItem.push_pull?.length === 2 ? null : (
                              <button
                                className=" rounded-lg bg-green-500 text-white px-3 py-2 mt-3"
                                onClick={(e) => {
                                  e.preventDefault();
                                  //console.log("add new push_pull");
                                  const temp = CurrentDevItem.push_pull;
                                  if (temp.length === 0) {
                                    temp.push({ name: "push", price: 0 });
                                  } else {
                                    temp.push({ name: "pull", price: 0 });
                                  }
                                  setCurrentDevItem({
                                    ...CurrentDevItem,
                                    push_pull: temp,
                                  });
                                }}
                              >
                                <p>Add new push/pull</p>
                              </button>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );

    return (
      <CustomTabPanel value={value} index={0}>
        <div>
          <div className="flex flex-row justify-between">
            <p className="text-dsblue-100 text-2xl font-bold">Develop Items</p>
            <button
              className={
                userAccessToChange
                  ? "flex justify-center items-center bg-dsblue-100 text-white rounded-md px-4 py-2"
                  : "hidden"
              }
              onClick={(e) => {
                e.preventDefault();
                //console.log("add new item");
                setEditOpen(true);
                setCurrentDevItem({
                  chamical: "",
                  describtion: "",
                  size: [],
                  cuts: [],
                  push_pull: [],
                });
                setCurrentDevItemIndex(-1);
              }}
            >
              Add Item
            </button>
          </div>
          <div className="w-full">
            {developItems.map((devitem, index) => (
              <div className="pb-3">
                <div className="rounded-xl shadow-md my-2 mx-2 lg:mx-0 w-full bg-dsblue-10 px-3 py-2">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                          {/* <div className="flex justify-between items-center py-3"> */}
                          {/* <p className=" text-lg font-Gilroy-m ">
                            Chemical: {item.chamical}
                          </p> */}
                          <span>Chemical: {devitem.chamical}</span>
                          <div className="flex justify-center items-center gap-3">
                            <button
                              className={
                                userAccessToChange && open
                                  ? " rounded-lg bg-dsblue-20 text-white px-3 py-2 hover:bg-dsblue-100"
                                  : "hidden"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                //console.log("edit");
                                setEditOpen(true);
                                setCurrentDevItem(devitem);
                                setCurrentDevItemIndex(index);
                              }}
                            >
                              <p>Edit</p>
                            </button>

                            <button
                              className={
                                userAccessToChange && open
                                  ? " rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                  : "hidden"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                //console.log("delete");
                                // make alert to confirm
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this item?"
                                  )
                                ) {
                                  item_api
                                    .DeleteItem(
                                      globalStore.getState().session.user
                                        .lab_id,
                                      "develope",
                                      devitem._id
                                    )
                                    .then((res) => {
                                      //console.log(res);
                                      setDevelopItems(res);
                                    })
                                    .catch((err) => {
                                      //console.log(err);
                                    });
                                  // setDevelopItems(
                                  //   developItems.filter(
                                  //     (item) => item._id !== devitem._id
                                  //   )
                                  // );
                                }
                              }}
                            >
                              <p>Delete Chemical</p>
                            </button>
                            <div>
                              <ChevronRightIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-dsblue-90`}
                              />
                            </div>
                          </div>
                          {/* </div> */}
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-sm text-gray-500  pt-4">
                          <div className=" flex flex-col gap-3 justify-between items-centerw-full">
                            <div className=" flex justify-center items-center gap-1 rounded-xl shadow-md py-2 px-2 bg-white w-full">
                              <input
                                type="text"
                                // disabled
                                className=" text-dsblue-100  p-1 w-full"
                                value={devitem.describtion}
                              ></input>
                            </div>
                            {/* loop size */}
                            <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                              <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                                Size options
                              </p>
                              <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 pt-3">
                                {devitem.size.map((size) => (
                                  <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                    <div>
                                      <p className="text-dsblue-100">
                                        Size name:
                                      </p>
                                      <input
                                        type="text"
                                        className=" text-dsblue-100 p-1"
                                        value={size.name}
                                        disabled
                                      ></input>
                                    </div>
                                    <div>
                                      <p className="text-dsblue-100">Price:</p>
                                      <input
                                        type="text"
                                        className=" text-dsblue-100  p-1"
                                        value={"$" + size.price}
                                        disabled
                                      ></input>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* loop cut */}
                            <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                              <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                                Cut options
                              </p>

                              <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 pt-3">
                                {devitem.cuts.map((cut) => (
                                  <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                    <div>
                                      <p className="text-dsblue-100">
                                        Cut size:
                                      </p>

                                      <input
                                        type="text"
                                        className=" text-dsblue-100  p-1"
                                        value={
                                          cut.cutSize === 0
                                            ? "No cut"
                                            : cut.cutSize
                                        }
                                        disabled
                                      ></input>
                                    </div>

                                    <div>
                                      <p className="text-dsblue-100">Price:</p>
                                      <input
                                        type="text"
                                        className=" text-dsblue-100  p-1"
                                        value={"$" + cut.price}
                                        disabled
                                      ></input>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* ------- */}
                            {/* loop push pull */}
                            <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                              <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                                Push/Pull options
                              </p>

                              <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 pt-3">
                                {
                                  // if push_pull is length !==0
                                  devitem.push_pull.length !== 0 ? (
                                    <div>
                                      {devitem.push_pull.map((push_pull) => (
                                        <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                          <div>
                                            <p className="text-dsblue-100">
                                              {push_pull.name === "push"
                                                ? "Push"
                                                : "Pull"}
                                            </p>
                                          </div>
                                          <div className="flex justify-center items-center gap-3">
                                            <p className="text-dsblue-100">
                                              Price:
                                            </p>
                                            <input
                                              type="text"
                                              className=" text-dsblue-100  p-1"
                                              value={"$" + push_pull.price}
                                              disabled
                                            ></input>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <p className="px-4 py-2 border border-dsblue-90 rounded-xl mt-3 col-span-2">
                                      No Push/Pull options
                                    </p>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            ))}
          </div>
        </div>
        {devModal}
      </CustomTabPanel>
    );
  };

  const ScanTabPanel = () => {
    const scanModal = (
      <Transition.Root show={EditScanOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-100 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                  <div className="bg-white px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90  text-left">
                    <div className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                      <span>
                        Scan Size:
                        <input
                          type="text"
                          className=" text-dsblue-100 p-1"
                          value={CurrentScanItem.filmSize}
                          onChange={(e) => {
                            e.preventDefault();
                            setCurrentScanItem({
                              ...CurrentScanItem,
                              filmSize: e.target.value,
                            });
                          }}
                          onBlur={validateInput}
                          name="scanFilmSize"
                          id="scanFilmSize"
                        ></input>
                      </span>

                      <div className="flex justify-center items-center gap-3">
                        <button
                          className={
                            EditScanOpen
                              ? " rounded-lg bg-dsblue-20 text-white px-3 py-2 hover:bg-dsblue-100"
                              : "hidden"
                          }
                          onClick={(e) => {
                            // make alert to confirm
                            e.preventDefault();
                            var ableToSave = true;
                            //check if there is any error
                            Object.values(inputError).forEach((val) => {
                              if (val.length > 0) {
                                ableToSave = false;
                              }
                            });
                            // find all these inputs  and make sure they are not empty
                            // scanFilmSize: "",
                            //     scanFileSizeName: "",
                            //     scanFileSizePrice: "",
                            //     scanTiffPrice: "",
                            var errorWindowText = "";
                            CurrentScanItem.scans.map((size) => {
                              if (size.scanRes === "" || size.price === "") {
                                ableToSave = false;
                                errorWindowText =
                                  "Please make sure all the size name and price are not empty";
                              }
                            });
                            if (CurrentScanItem.tiffPrice === "") {
                              ableToSave = false;
                              errorWindowText =
                                "Please make sure the tiff price is not empty";
                            }

                            if (ableToSave) {
                              if (
                                window.confirm(
                                  "Are you sure you want to save this item?"
                                )
                              ) {
                                setEditScanOpen(false);
                                var apiItems = scanItems;
                                if (CurrentScanItemIndex >= 0) {
                                  const temp = scanItems;
                                  temp[CurrentScanItemIndex] = CurrentScanItem;
                                  // setScanItems(temp);
                                } else {
                                  apiItems = apiItems.concat(CurrentScanItem);
                                }
                                //console.log("save----------------------");
                                item_api
                                  .UpdateItems(
                                    // globalStore.getState().session.user.lab_id,
                                    shop_id,
                                    "scan",
                                    apiItems
                                  )
                                  .then((res) => {
                                    setScanItems(res);
                                    window.location.reload();
                                  })
                                  .catch((err) => {
                                    //console.log(err);
                                  });
                                setCurrentScanItem({
                                  name: "",
                                  filmSize: "",
                                  scans: [],
                                  tiffPrice: 0,
                                });
                                setCurrentScanItemIndex(0);
                              }
                            } else {
                              alert(errorWindowText);
                            }
                          }}
                        >
                          <p>Save</p>
                        </button>
                        <button
                          className={
                            EditScanOpen
                              ? " rounded-lg bg-dsblue-20 text-white px-3 py-2 hover:bg-dsblue-100"
                              : "hidden"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            // item_api
                            //   .GetItems(
                            //     globalStore.getState().session.user.lab_id,
                            //     "develope"
                            //   )
                            //   .then((res) => {
                            //     setDevelopItems(res);
                            //   });

                            setCurrentScanItem({
                              name: "",
                              filmSize: "",
                              scans: [],
                              tiffPrice: 0,
                            });
                            setCurrentScanItemIndex(0);
                            setEditScanOpen(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      </div>

                      {/* </div> */}
                    </div>
                    {Error(inputError.scanFilmSize)}
                    <div className="text-sm text-gray-500  pt-4">
                      <div className=" flex flex-col gap-3 justify-between items-centerw-full">
                        {/* loop size */}
                        <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                          <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                            File size options
                          </p>
                          <div className="grid grid-cols-1  gap-3 pt-3">
                            {CurrentScanItem.scans.map((size, index) => (
                              <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                <div>
                                  <p className="text-dsblue-100">Size name:</p>
                                  <input
                                    type="text"
                                    className=" text-dsblue-100 p-1"
                                    value={size.scanRes}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const temp = CurrentScanItem.scans;
                                      temp[index].scanRes = e.target.value;
                                      setCurrentScanItem({
                                        ...CurrentScanItem,
                                        scans: temp,
                                      });
                                    }}
                                    onBlur={validateInput}
                                    name="scanFileSizeName"
                                    id="scanFileSizeName"
                                  ></input>
                                </div>
                                <div>
                                  <p className="text-dsblue-100">Price:</p>
                                  <input
                                    type="number"
                                    className=" text-dsblue-100  p-1"
                                    value={size.price}
                                    onChange={(e) => {
                                      //console.log(e.target.value);
                                      //find the index of the size then update the price
                                      const temp = CurrentScanItem.scans;
                                      temp[index].price = e.target.value;
                                      setCurrentScanItem({
                                        ...CurrentScanItem,
                                        scans: temp,
                                      });
                                    }}
                                    onBlur={validateInput}
                                    name="scanFileSizePrice"
                                    id="scanFileSizePrice"
                                  ></input>
                                </div>
                                <div className="flex justify-center items-center gap-3">
                                  <p className="text-dsblue-100">
                                    Include Tiff:
                                  </p>
                                  <Switch
                                    checked={size.incloudTiff}
                                    // disabled
                                    onChange={(e) => {
                                      //console.log(e);
                                      const temp = CurrentScanItem.scans;
                                      temp[index].incloudTiff = e;
                                      setCurrentScanItem({
                                        ...CurrentScanItem,
                                        scans: temp,
                                      });
                                    }}
                                    className={`${
                                      size.incloudTiff
                                        ? "bg-dsblue-100"
                                        : "bg-gray-200"
                                    } relative inline-flex items-center h-6 rounded-full w-11`}
                                  >
                                    <span className="sr-only">
                                      Enable notifications
                                    </span>
                                    <span
                                      className={`${
                                        size.incloudTiff
                                          ? "translate-x-6"
                                          : "translate-x-1"
                                      } inline-block w-4 h-4 transform bg-white rounded-full`}
                                    />
                                  </Switch>
                                </div>
                                <button
                                  className=" rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //console.log("delete size");
                                    const temp = CurrentScanItem.scans;
                                    temp.splice(index, 1);
                                    setCurrentScanItem({
                                      ...CurrentScanItem,
                                      scans: temp,
                                    });
                                  }}
                                >
                                  <p>Delete</p>
                                </button>
                              </div>
                            ))}
                            {Error(inputError.scanFileSizeName)}
                            {Error(inputError.scanFileSizePrice)}
                          </div>

                          {/* add new */}
                          <button
                            className=" rounded-lg bg-green-500 text-white px-3 py-2 mt-3"
                            onClick={() => {
                              //console.log("add new size");
                              //console.log(CurrentScanItem);
                              const temp = CurrentScanItem.scans.concat({
                                scanRes: "",
                                price: 0,
                              });
                              //console.log(temp);
                              setCurrentScanItem({
                                ...CurrentScanItem,
                                scans: temp,
                              });
                            }}
                          >
                            <p>Add new size</p>
                          </button>
                        </div>
                        {/* tiff option */}
                        <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                          <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                            Tiff option
                          </p>
                          <div className="grid grid-cols-1  gap-3 pt-3">
                            {
                              // if tiffPrice is 0
                              CurrentScanItem.tiffPrice !== 0 ? (
                                <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                  <p className="text-dsblue-100">
                                    Tiff option:
                                  </p>
                                  <div>
                                    <p className="text-dsblue-100">Price:</p>
                                    <input
                                      type="number"
                                      className=" text-dsblue-100  p-1"
                                      value={CurrentScanItem.tiffPrice}
                                      onChange={(e) => {
                                        //console.log(e.target.value);
                                        //find the index of the size then update the price
                                        var temp = CurrentScanItem.tiffPrice;
                                        temp = e.target.value;
                                        setCurrentScanItem({
                                          ...CurrentScanItem,
                                          tiffPrice: temp,
                                        });
                                      }}
                                      onBlur={validateInput}
                                      name="scanTiffPrice"
                                      id="scanTiffPrice"
                                    ></input>
                                  </div>

                                  {/* if no tiff option show a add tiff button else show delete option*/}
                                  {CurrentScanItem.tiffPrice === 0 ? null : (
                                    <button
                                      className=" rounded-lg bg-red-200 text-white px-3 py-2 mt-3 hover:bg-red-500"
                                      onClick={() => {
                                        //console.log("delete tiff option");
                                        setCurrentScanItem({
                                          ...CurrentScanItem,
                                          tiffPrice: 0,
                                        });
                                      }}
                                    >
                                      <p>Delete</p>
                                    </button>
                                  )}
                                </div>
                              ) : null
                            }

                            {Error(inputError.scanTiffPrice)}
                            {CurrentScanItem.tiffPrice === 0 ? (
                              <button
                                className=" rounded-lg bg-green-500 text-white px-3 py-2 mt-3"
                                onClick={() => {
                                  //console.log("add tiff option");
                                  setCurrentScanItem({
                                    ...CurrentScanItem,
                                    tiffPrice: 1,
                                  });
                                }}
                              >
                                <p>Add Tiff option</p>
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
    return (
      <CustomTabPanel value={value} index={1}>
        <div>
          <div className="flex flex-row justify-between">
            <p className="text-dsblue-100 text-2xl font-bold">Scan Items</p>
            <button
              className={
                userAccessToChange
                  ? "flex justify-center items-center bg-dsblue-100 text-white rounded-md px-4 py-2"
                  : "hidden"
              }
              onClick={(e) => {
                e.preventDefault();
                //console.log("add new item");
                setEditScanOpen(true);
                setCurrentScanItem({
                  name: "",
                  filmSize: "",
                  scans: [],
                  tiffPrice: 0,
                });
                setCurrentScanItemIndex(-1);
              }}
            >
              Add Item
            </button>
          </div>
          <div className="w-full">
            {scanItems.map((scanitem, index) => (
              <div className="pb-3">
                <div className="rounded-xl shadow-md my-2 mx-2 lg:mx-0 w-full bg-dsblue-10 px-3 py-2">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                          {/* <div className="flex justify-between items-center py-3"> */}
                          {/* <p className=" text-lg font-Gilroy-m ">
                            Chemical: {item.chamical}
                          </p> */}
                          <span>Scan Size: {scanitem.filmSize}</span>
                          <div className="flex justify-center items-center gap-3">
                            <button
                              className={
                                open && userAccessToChange
                                  ? " rounded-lg bg-dsblue-20 text-white px-3 py-2 hover:bg-dsblue-100"
                                  : "hidden"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                //console.log("edit");
                                setEditScanOpen(true);
                                setCurrentScanItem(scanitem);
                                setCurrentScanItemIndex(index);
                              }}
                            >
                              <p>Edit</p>
                            </button>

                            <button
                              className={
                                open && userAccessToChange
                                  ? " rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                  : "hidden"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                //console.log("delete");
                                // make alert to confirm
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this item?"
                                  )
                                ) {
                                  item_api
                                    .DeleteItem(
                                      globalStore.getState().session.user
                                        .lab_id,
                                      "scan",
                                      scanitem._id
                                    )
                                    .then((res) => {
                                      setScanItems(res);
                                    })
                                    .catch((err) => {
                                      //console.log(err);
                                    });
                                }
                              }}
                            >
                              <p>Delete Size</p>
                            </button>
                            <div
                              className={
                                open
                                  ? "flex justify-center items-center gap-3"
                                  : "hidden"
                              }
                            >
                              <div>
                                <ChevronRightIcon
                                  className={`${
                                    open ? "transform rotate-90" : ""
                                  } w-5 h-5 text-dsblue-90`}
                                />
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-sm text-gray-500  pt-4">
                          <div className=" flex flex-col gap-3 justify-between items-centerw-full">
                            {/* loop size */}
                            <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                              <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                                File size options
                              </p>
                              <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 pt-3">
                                {scanitem.scans.map((size) => (
                                  <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                    <div>
                                      <p className="text-dsblue-100">
                                        Size name:
                                      </p>
                                      <input
                                        type="text"
                                        className=" text-dsblue-100 p-1"
                                        value={size.scanRes}
                                        disabled
                                      ></input>
                                    </div>
                                    <div>
                                      <p className="text-dsblue-100">Price:</p>
                                      <input
                                        type="text"
                                        className=" text-dsblue-100  p-1"
                                        value={"$" + size.price}
                                        disabled
                                      ></input>
                                    </div>
                                    {/* show if it include tiff for free by switch toggle*/}
                                    {size.incloudTiff ? (
                                      <div className="flex justify-center items-center gap-3">
                                        <p className="text-dsblue-100">
                                          Include Tiff:
                                        </p>
                                        <Switch
                                          checked={size.incloudTiff}
                                          disabled
                                          // onChange={(e) => {
                                          //   //console.log(e.target.checked);
                                          //   const temp = scanitem.scans;
                                          //   temp[index].incloudTiff =
                                          //     e.target.checked;
                                          //   setScanItems({
                                          //     ...scanitem,
                                          //     scans: temp,
                                          //   });
                                          // }}
                                          className={`${
                                            size.incloudTiff
                                              ? "bg-dsblue-100"
                                              : "bg-gray-200"
                                          } relative inline-flex items-center h-6 rounded-full w-11`}
                                        >
                                          <span className="sr-only">
                                            Enable notifications
                                          </span>
                                          <span
                                            className={`${
                                              size.incloudTiff
                                                ? "translate-x-6"
                                                : "translate-x-1"
                                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                                          />
                                        </Switch>
                                      </div>
                                    ) : null}
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* tiff option */}

                            {/* // if tiffPrice is not 0 */}

                            <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                              <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                                Tiff option
                              </p>
                              {scanitem.tiffPrice !== 0 ? (
                                <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 pt-3">
                                  <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                    <p className="text-dsblue-100">
                                      Tiff option:
                                    </p>
                                    <div>
                                      <p className="text-dsblue-100">Price:</p>
                                      <input
                                        type="text"
                                        className=" text-dsblue-100 p-1"
                                        value={scanitem.tiffPrice}
                                        disabled
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* {devModal} */}
        {scanModal}
      </CustomTabPanel>
    );
  };

  const PrintTabPanel = () => {
    const printModal = (
      <Transition.Root show={EditPrintOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-100 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                  <div className="bg-white px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90  text-left">
                    <div className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                      <span>
                        Print Film Size:
                        <input
                          type="text"
                          className=" text-dsblue-100 p-1"
                          value={CurrentPrintItem.filmSize}
                          onChange={(e) => {
                            e.preventDefault();
                            setCurrentPrintItem({
                              ...CurrentPrintItem,
                              filmSize: e.target.value,
                            });
                          }}
                          onBlur={validateInput}
                          name="printFilmSize"
                          id="printFilmSize"
                        ></input>
                      </span>

                      <div className="flex justify-center items-center gap-3">
                        <button
                          className={
                            EditPrintOpen
                              ? " rounded-lg bg-dsblue-20 text-white px-3 py-2 hover:bg-dsblue-100"
                              : "hidden"
                          }
                          onClick={(e) => {
                            // make alert to confirm
                            e.preventDefault();
                            var ableToSave = true;

                            var errorWindowText = "";
                            //check if there is any error
                            Object.values(inputError).forEach((val) => {
                              if (val.length > 0) {
                                ableToSave = false;
                                errorWindowText =
                                  "Please make sure all the size name and price are not empty";
                              }
                            });
                            // find all these inputs  and make sure they are not empty    printFilmSize: "",
                            // printFilmExposure: "",
                            // printSizeName: "",
                            // printSizePrice: "",
                            // printExtraSetPrice: "",
                            // printBorder: "",
                            // printBorderPrice: "",
                            // printFinish: "",
                            // printFinishPrice: "",

                            CurrentPrintItem.exposures.map((exp) => {
                              if (exp.numberOfEXP === "") {
                                ableToSave = false;
                                errorWindowText =
                                  "Please make sure all the exposures are not empty";
                              }
                              exp.printSizes.map((size) => {
                                if (size.size === "" || size.price === "") {
                                  ableToSave = false;
                                  errorWindowText =
                                    "Please make sure all the size name and price are not empty";
                                }
                              });
                            });
                            CurrentPrintItem.border.map((exp) => {
                              if (exp.name === "" || exp.price === "") {
                                ableToSave = false;
                                errorWindowText =
                                  "Please make sure all the border name and price are not empty";
                              }
                            });
                            CurrentPrintItem.printFinish.map((exp) => {
                              if (exp.name === "" || exp.price === "") {
                                ableToSave = false;
                                errorWindowText =
                                  "Please make sure all the finish name and price are not empty";
                              }
                            });

                            if (ableToSave) {
                              if (
                                window.confirm(
                                  "Are you sure you want to save this item?"
                                )
                              ) {
                                setEditPrintOpen(false);
                                var apiItems = printItems;
                                if (CurrentPrintItemIndex >= 0) {
                                  const temp = printItems;
                                  temp[CurrentPrintItemIndex] =
                                    CurrentPrintItem;
                                  // setPrintItems(temp);
                                  apiItems = temp;
                                } else {
                                  apiItems = apiItems.concat(CurrentPrintItem);
                                }
                                //console.log("save----------------------");
                                item_api
                                  .UpdateItems(
                                    // globalStore.getState().session.user.lab_id,
                                    shop_id,
                                    "print",
                                    apiItems
                                  )
                                  .then((res) => {
                                    setPrintItems(res);
                                    window.location.reload();
                                  })
                                  .catch((err) => {
                                    //console.log(err);
                                  });
                                setCurrentPrintItem({
                                  name: "print",
                                  filmSize: "",
                                  exposures: [],
                                  border: null,
                                  printFinish: null,
                                });
                                setCurrentPrintItemIndex(0);
                              }
                            } else {
                              alert(errorWindowText);
                            }
                          }}
                        >
                          <p>Save</p>
                        </button>
                        <button
                          className={
                            EditPrintOpen
                              ? " rounded-lg bg-dsblue-20 text-white px-3 py-2 hover:bg-dsblue-100"
                              : "hidden"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            // item_api
                            //   .GetItems(
                            //     globalStore.getState().session.user.lab_id,
                            //     "develope"
                            //   )
                            //   .then((res) => {
                            //     setDevelopItems(res);
                            //   });
                            setEditPrintOpen(false);
                            setCurrentPrintItem({
                              name: "print",
                              filmSize: "",
                              exposures: [],
                              border: null,
                              printFinish: null,
                            });
                            setCurrentPrintItemIndex(0);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      </div>
                      {/* </div> */}
                    </div>
                    {Error(inputError.printFilmSize)}
                    <div className="text-sm text-gray-500  pt-4">
                      <div className=" flex flex-col gap-3 justify-between items-centerw-full">
                        {/* loop size */}
                        <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                          <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                            Exposures
                          </p>
                          <div className="grid grid-cols-1 gap-3 pt-3">
                            {CurrentPrintItem.exposures.map((exp, index) => (
                              <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                <div>
                                  <p className="text-dsblue-100">
                                    Number of exposures:
                                  </p>
                                  <input
                                    type="text"
                                    className=" text-dsblue-100 p-1  bg-dsblue-10"
                                    value={exp.numberOfEXP}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const temp = CurrentPrintItem.exposures;
                                      temp[index].numberOfEXP = e.target.value;
                                      setCurrentPrintItem({
                                        ...CurrentPrintItem,
                                        exposures: temp,
                                      });
                                    }}
                                    onBlur={validateInput}
                                    name="printFilmExposure"
                                    id="printFilmExposure"
                                  ></input>
                                  <p className="text-sm text-dsblue-60">
                                    0 will mean any exposures
                                  </p>
                                </div>
                                {exp.printSizes.map((size, printSizesIndex) => (
                                  <div className="flex flex-wrap justify-between items-center gap-3 border border-dsblue-90 rounded-xl px-4 my-2 py-2">
                                    <div>
                                      <p className="text-dsblue-100">
                                        Print size:
                                      </p>
                                      <input
                                        type="text"
                                        className=" text-dsblue-100 p-1  bg-dsblue-10"
                                        value={size.size}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          const temp =
                                            CurrentPrintItem.exposures;
                                          temp[index].printSizes[
                                            printSizesIndex
                                          ].size = e.target.value;
                                          setCurrentPrintItem({
                                            ...CurrentPrintItem,
                                            exposures: temp,
                                          });
                                        }}
                                        onBlur={validateInput}
                                        name="printSizeName"
                                        id="printSizeName"
                                      ></input>
                                    </div>
                                    <div>
                                      <p className="text-dsblue-100">Price:</p>
                                      <input
                                        type="number"
                                        className=" text-dsblue-100  p-1 bg-dsblue-10"
                                        value={size.price}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          const temp =
                                            CurrentPrintItem.exposures;
                                          temp[index].printSizes[
                                            printSizesIndex
                                          ].price = e.target.value;
                                          setCurrentPrintItem({
                                            ...CurrentPrintItem,
                                            exposures: temp,
                                          });
                                        }}
                                        onBlur={validateInput}
                                        name="printSizePrice"
                                        id="printSizePrice"
                                      ></input>
                                    </div>
                                    <div>
                                      <p className="text-dsblue-100">
                                        Extra set price:
                                      </p>
                                      <input
                                        type="number"
                                        className=" text-dsblue-100  p-1  bg-dsblue-10"
                                        value={size.extraSetPrice}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          const temp =
                                            CurrentPrintItem.exposures;
                                          temp[index].printSizes[
                                            printSizesIndex
                                          ].extraSetPrice = e.target.value;
                                          setCurrentPrintItem({
                                            ...CurrentPrintItem,
                                            exposures: temp,
                                          });
                                        }}
                                        onBlur={validateInput}
                                        name="printExtraSetPrice"
                                        id="printExtraSetPrice"
                                      ></input>
                                    </div>
                                    {/* 
                                    delete print size 
                                    */}
                                    <button
                                      className=" rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        //console.log("delete print size");
                                        const temp = CurrentPrintItem.exposures;
                                        temp[index].printSizes.splice(
                                          printSizesIndex,
                                          1
                                        );
                                        setCurrentPrintItem({
                                          ...CurrentPrintItem,
                                          exposures: temp,
                                        });
                                      }}
                                    >
                                      <p>Delete</p>
                                    </button>
                                  </div>
                                ))}

                                {/* //add new print size */}
                                <button
                                  className=" rounded-lg bg-green-500 text-white px-3 py-2 mt-3"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //console.log("add new print size");
                                    //console.log(CurrentPrintItem);
                                    const temp = CurrentPrintItem.exposures;
                                    temp[index].printSizes = temp[
                                      index
                                    ].printSizes.concat({
                                      size: "",
                                      price: 0,
                                      extraSetPrice: 0,
                                    });
                                    //console.log(temp);
                                    setCurrentPrintItem({
                                      ...CurrentPrintItem,
                                      exposures: temp,
                                    });
                                  }}
                                >
                                  <p>Add new print size</p>
                                </button>
                                <button
                                  className=" rounded-lg bg-red-200 text-white px-3 py-2 mt-3 hover:bg-red-500"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //console.log("delete exposure");
                                    const temp = CurrentPrintItem.exposures;
                                    temp.splice(index, 1);
                                    setCurrentPrintItem({
                                      ...CurrentPrintItem,
                                      exposures: temp,
                                    });
                                  }}
                                >
                                  <p>Delete</p>
                                </button>

                                <div>
                                  {Error(inputError.printFilmExposure)}
                                  {Error(inputError.printSizeName)}
                                  {Error(inputError.printSizePrice)}
                                  {Error(inputError.printExtraSetPrice)}
                                </div>
                              </div>
                            ))}
                          </div>
                          {/* add new */}
                          <button
                            className=" rounded-lg bg-green-500 text-white px-3 py-2 mt-3"
                            onClick={(e) => {
                              e.preventDefault();
                              //console.log("add new exposure");
                              //console.log(CurrentPrintItem);
                              const temp = CurrentPrintItem.exposures.concat({
                                numberOfEXP: 0,
                                printSizes: [
                                  {
                                    size: "",
                                    price: 0,
                                    extraSetPrice: 0,
                                  },
                                ],
                              });
                              //console.log(temp);
                              setCurrentPrintItem({
                                ...CurrentPrintItem,
                                exposures: temp,
                              });
                            }}
                          >
                            <p>Add new exposure</p>
                          </button>
                        </div>
                        {/* border option */}
                        <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                          <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                            Border options
                          </p>
                          <div className="grid grid-cols-1  gap-3 pt-3">
                            {CurrentPrintItem.border?.map((border, index) => (
                              <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                <div>
                                  <p className="text-dsblue-100">
                                    Border name:
                                  </p>
                                  <input
                                    type="text"
                                    className=" text-dsblue-100 p-1"
                                    value={border.name}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const temp = CurrentPrintItem.border;
                                      temp[index].name = e.target.value;
                                      setCurrentPrintItem({
                                        ...CurrentPrintItem,
                                        border: temp,
                                      });
                                    }}
                                    onBlur={validateInput}
                                    name="printBorder"
                                    id="printBorder"
                                  ></input>
                                </div>
                                <div>
                                  <p className="text-dsblue-100">Price:</p>
                                  <input
                                    type="number"
                                    className=" text-dsblue-100  p-1"
                                    value={border.price}
                                    // disabled
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const temp = CurrentPrintItem.border;
                                      temp[index].price = e.target.value;
                                      setCurrentPrintItem({
                                        ...CurrentPrintItem,
                                        border: temp,
                                      });
                                    }}
                                    onBlur={validateInput}
                                    name="printBorderPrice"
                                    id="printBorderPrice"
                                  ></input>
                                </div>
                                {/* delete border */}
                                <button
                                  className=" rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //console.log("delete border");
                                    const temp = CurrentPrintItem.border;
                                    temp.splice(index, 1);
                                    setCurrentPrintItem({
                                      ...CurrentPrintItem,
                                      border: temp,
                                    });
                                  }}
                                >
                                  <p>Delete</p>
                                </button>
                              </div>
                            ))}
                          </div>
                          {Error(inputError.printBorder)}
                          {Error(inputError.printBorderPrice)}
                          {/* add new */}
                          <button
                            className=" rounded-lg bg-green-500 text-white px-3 py-2 mt-3"
                            onClick={() => {
                              //console.log("add new border");
                              //console.log(CurrentPrintItem);
                              const temp = CurrentPrintItem.border.concat({
                                name: "",
                                price: 0,
                              });
                              //console.log(temp);
                              setCurrentPrintItem({
                                ...CurrentPrintItem,
                                border: temp,
                              });
                            }}
                          >
                            <p>Add new border</p>
                          </button>
                        </div>
                        {/* Print finish type  */}
                        <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                          <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                            Print finish options
                          </p>
                          <div className="grid grid-cols-1  gap-3 pt-3">
                            {CurrentPrintItem.printFinish?.map(
                              (finish, index) => (
                                <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                  <div>
                                    <p className="text-dsblue-100">
                                      Finish name:
                                    </p>
                                    <input
                                      type="text"
                                      className=" text-dsblue-100 p-1"
                                      value={finish.name}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        const temp =
                                          CurrentPrintItem.printFinish;
                                        temp[index].name = e.target.value;
                                        setCurrentPrintItem({
                                          ...CurrentPrintItem,
                                          printFinish: temp,
                                        });
                                      }}
                                      onBlur={validateInput}
                                      name="printFinish"
                                      id="printFinish"
                                    ></input>
                                  </div>
                                  <div>
                                    <p className="text-dsblue-100">Price:</p>
                                    <input
                                      type="number"
                                      className=" text-dsblue-100  p-1"
                                      value={finish.price}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        const temp =
                                          CurrentPrintItem.printFinish;
                                        temp[index].price = e.target.value;
                                        setCurrentPrintItem({
                                          ...CurrentPrintItem,
                                          printFinish: temp,
                                        });
                                      }}
                                      onBlur={validateInput}
                                      name="printFinishPrice"
                                      id="printFinishPrice"
                                    ></input>
                                  </div>
                                  {/* delete finish */}
                                  <button
                                    className=" rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      //console.log("delete finish");
                                      const temp = CurrentPrintItem.printFinish;
                                      temp.splice(index, 1);
                                      setCurrentPrintItem({
                                        ...CurrentPrintItem,
                                        printFinish: temp,
                                      });
                                    }}
                                  >
                                    <p>Delete</p>
                                  </button>
                                </div>
                              )
                            )}
                          </div>
                          {Error(inputError.printFinish)}
                          {Error(inputError.printFinishPrice)}
                          {/* add new */}
                          <button
                            className=" rounded-lg bg-green-500 text-white px-3 py-2 mt-3"
                            onClick={() => {
                              //console.log("add new finish");
                              //console.log(CurrentPrintItem);
                              const temp = CurrentPrintItem.printFinish.concat({
                                name: "",
                                price: 0,
                              });
                              //console.log(temp);
                              setCurrentPrintItem({
                                ...CurrentPrintItem,
                                printFinish: temp,
                              });
                            }}
                          >
                            <p>Add new finish</p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );

    return (
      <CustomTabPanel value={value} index={2}>
        <div>
          <div className="flex flex-row justify-between">
            <p className="text-dsblue-100 text-2xl font-bold">Print Items</p>
            <button
              className={
                userAccessToChange
                  ? "flex justify-center items-center bg-dsblue-100 text-white rounded-md px-4 py-2"
                  : "hidden"
              }
              onClick={(e) => {
                e.preventDefault();
                //console.log("add new item");
                setEditPrintOpen(true);
                setCurrentPrintItem({
                  name: "print",
                  filmSize: "",
                  exposures: [],
                  border: [],
                  printFinish: [],
                });
                setCurrentPrintItemIndex(-1);
              }}
            >
              Add Item
            </button>
          </div>
          <div className="w-full">
            {printItems.map((printitem, index) => (
              <div className="pb-3">
                <div className="rounded-xl shadow-md my-2 mx-2 lg:mx-0 w-full bg-dsblue-10 px-3 py-2">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                          {/* <div className="flex justify-between items-center py-3"> */}
                          {/* <p className=" text-lg font-Gilroy-m ">
                            Chemical: {item.chamical}
                          </p> */}
                          <span>Print Size: {printitem.filmSize}</span>
                          <div className="flex justify-center items-center gap-3">
                            <button
                              className={
                                open && userAccessToChange
                                  ? " rounded-lg bg-dsblue-20 text-white px-3 py-2 hover:bg-dsblue-100"
                                  : "hidden"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                //console.log("edit");
                                setEditPrintOpen(true);
                                setCurrentPrintItem(printitem);
                                setCurrentPrintItemIndex(index);
                              }}
                            >
                              <p>Edit</p>
                            </button>

                            <button
                              className={
                                open && userAccessToChange
                                  ? " rounded-lg bg-red-200 text-white px-3 py-2 hover:bg-red-500"
                                  : "hidden"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                //console.log("delete");
                                // make alert to confirm
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this item?"
                                  )
                                ) {
                                  item_api
                                    .DeleteItem(
                                      globalStore.getState().session.user
                                        .lab_id,
                                      "print",
                                      printitem._id
                                    )
                                    .then((res) => {
                                      setPrintItems(res);
                                    })
                                    .catch((err) => {
                                      //console.log(err);
                                    });
                                }
                              }}
                            >
                              <p>Delete Size</p>
                            </button>
                            <div
                              className
                              // open
                              //   ? "flex justify-center items-center gap-3"
                              //   : "hidden"
                            >
                              <div>
                                <ChevronRightIcon
                                  className={`${
                                    open ? "transform rotate-90" : ""
                                  } w-5 h-5 text-dsblue-90`}
                                />
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-sm text-gray-500  pt-4">
                          <div className=" flex flex-col gap-3 justify-between items-centerw-full">
                            {/* border option */}
                            <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                              <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                                Border options
                              </p>
                              {
                                //if border is not null
                                printitem.border.length > 0 ? (
                                  <div className="grid grid-cols-1  gap-3 pt-3">
                                    {printitem.border.map((border) => (
                                      <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                        <div>
                                          <p className="text-dsblue-100">
                                            Border name:
                                          </p>
                                          <input
                                            type="text"
                                            className=" text-dsblue-100 p-1"
                                            value={border.name}
                                            disabled
                                          ></input>
                                        </div>
                                        <div>
                                          <p className="text-dsblue-100">
                                            Price:
                                          </p>
                                          <input
                                            type="text"
                                            className=" text-dsblue-100  p-1"
                                            value={"$" + border.price}
                                            disabled
                                          ></input>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <p className="px-4 py-2 border border-dsblue-90 rounded-xl mt-3">
                                    No border options for this size
                                  </p>
                                )
                              }
                            </div>
                            {/* Print finish type  */}
                            <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                              <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                                Print finish options
                              </p>
                              {
                                //if print finish is not null
                                printitem.printFinish.length > 0 ? (
                                  <div className="grid grid-cols-1  gap-3 pt-3">
                                    {printitem.printFinish.map((finish) => (
                                      <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                        <div>
                                          <p className="text-dsblue-100">
                                            Finish name:
                                          </p>
                                          <input
                                            type="text"
                                            className=" text-dsblue-100 p-1"
                                            value={finish.name}
                                            disabled
                                          ></input>
                                        </div>
                                        <div>
                                          <p className="text-dsblue-100">
                                            Price:
                                          </p>
                                          <input
                                            type="text"
                                            className=" text-dsblue-100  p-1"
                                            value={"$" + finish.price}
                                            disabled
                                          ></input>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <p className="px-4 py-2 border border-dsblue-90 rounded-xl mt-3">
                                    No print finish options for this size
                                  </p>
                                )
                              }
                            </div>
                            {/* Exposure */}
                            <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                              <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                                Exposure options
                              </p>
                              <div className="grid grid-cols-1 gap-3 pt-3">
                                {printitem.exposures.map((exposure) => (
                                  <div className="rounded-xl shadow-md py-2 px-2 bg-white w-full">
                                    <p className="text-white rounded-lg px-3 py-2 bg-dsblue-100">
                                      Exposure:{" "}
                                      {exposure.numberOfEXP
                                        ? exposure.numberOfEXP
                                        : "Any number of exposures"}
                                    </p>
                                    <div className="grid grid-cols-1 gap-3 pt-3">
                                      {exposure.printSizes.map((size) => (
                                        <div className="flex flex-wrap justify-between items-center gap-3 px-4 my-2 py-2 border border-dsblue-90 rounded-xl ">
                                          <div>
                                            <p className="text-dsblue-100">
                                              Size name:
                                            </p>
                                            <input
                                              type="text"
                                              className=" text-dsblue-100 p-1"
                                              value={size.size}
                                              disabled
                                            ></input>
                                          </div>
                                          <div>
                                            <p className="text-dsblue-100">
                                              Price:
                                            </p>
                                            <input
                                              type="text"
                                              className=" text-dsblue-100  p-1"
                                              value={"$" + size.price}
                                              disabled
                                            ></input>
                                          </div>
                                          <div>
                                            <p className="text-dsblue-100">
                                              Extra set price:
                                            </p>
                                            <input
                                              type="text"
                                              className=" text-dsbblue-100  p-1"
                                              value={"$" + size.extraSetPrice}
                                              disabled
                                            ></input>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            ))}
          </div>
        </div>
        {printModal}
      </CustomTabPanel>
    );
  };

  const SearchSection = () => {
    const handleSearchOnChange = (e) => {
      setLabs([]);
      setCurrentLab({});
      setShop_id("");
      setSearchTerm(e.target.value);
    };
    const handleSearch = (e) => {
      setSearchTerm("");

      shop_api
        .FindShop(searchTerm)
        .then((res) => {
          setLabs(res);
        })
        .catch((err) => {
          //console.log(err);
        });
    };
    const listOfLabs = () => {
      // return ();
      return (
        <div className="flex flex-col gap-3">
          {labs.map((item) => {
            return (
              <button
                className="p-4 border border-dsblue-90 rounded-xl"
                onClick={() => {
                  setShop_id(item._id);
                  setCurrentLab(item);
                  setLabs([]);
                }}
              >
                <p className=" font-Gilroy-m py-2 text-lg">{item.shopName}</p>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-2">
                  <div>
                    <div className="">
                      <p className="text-left  pb-1">Address:</p>{" "}
                      <p className="text-sm py-2 text-left text-dsblue-60">
                        {item.shopAddress}
                      </p>
                    </div>
                    <div>
                      <p className="text-left pb-1">Phone:</p>
                      <div className="flex flex-col justify-start items-start text-dsblue-60">
                        {item.shopPhones?.map((phone) => {
                          return <p>{phone}</p>;
                        })}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col justify-start items-start text-dsblue-60 sm:pl-3">
                      {item.shopHours?.map((hour) => {
                        return (
                          <div>
                            {hour.date +
                              ": " +
                              hour.startTime +
                              "-" +
                              hour.endTime}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      );
    };
    return (
      <div className=" flex flex-col gap-3">
        <div>
          <p className="text-2xl font-Gilroy-m text-dsblue-100">
            Find a lab to see pricing.
          </p>
        </div>
        <div className="flex justify-start items-center gap-3">
          <Tooltip title="Lab name , phone, address">
            <input
              className="border border-dsblue-90 rounded-md px-2 py-1"
              type="text"
              value={searchTerm}
              placeholder="Search for lab"
              onChange={(e) => {
                handleSearchOnChange(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(e);
                }
              }}
            ></input>
          </Tooltip>
          <button
            className={
              searchTerm === ""
                ? "hidden"
                : "bg-dsblue-90 text-white px-3 py-1 rounded-md"
            }
            onClick={() => {
              handleSearch();
            }}
            disabled={searchTerm === ""}
          >
            Search
          </button>
        </div>
        {shop_id === "" || searchTerm !== "" ? <div>{listOfLabs()}</div> : null}
        {currentLab._id ? (
          <div>
            <div className="p-4 border border-dsblue-90 rounded-xl">
              <p className=" font-Gilroy-m py-2 text-lg">
                {currentLab.shopName}
              </p>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-2">
                <div>
                  <div className="">
                    <p className="text-left  pb-1">Address:</p>{" "}
                    <p className="text-sm py-2 text-left text-dsblue-60">
                      {currentLab.shopAddress}
                    </p>
                  </div>
                  <div>
                    <p className="text-left pb-1">Phone:</p>
                    <div className="flex flex-col justify-start items-start text-dsblue-60">
                      {currentLab.shopPhones?.map((phone) => {
                        return <p>{phone}</p>;
                      })}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-col justify-start items-start text-dsblue-60 sm:pl-3">
                    {currentLab.shopHours?.map((hour) => {
                      return (
                        <div>
                          {hour.date +
                            ": " +
                            hour.startTime +
                            "-" +
                            hour.endTime}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      <ShopLayout describtion="services">
        {" "}
        {isLoading ? (
          <div>
            <Loading></Loading>
          </div>
        ) : (
          <div>
            <div>
              {globalStore.getState().session.user.usertype ===
                usertype.labAdmin[0] ||
              usertype.customer.includes(
                globalStore.getState().session.user.usertype
              )
                ? SearchSection()
                : null}
            </div>
            {shop_id !== "" ? (
              <div>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Develop" {...a11yProps(0)} />
                      <Tab label="Scan" {...a11yProps(1)} />
                      <Tab label="Print" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  {isLoading ? (
                    <div
                      //center
                      className="flex justify-center items-center my-56"
                    >
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    </div>
                  ) : (
                    DevelopeTabPanel()
                  )}
                  {isLoading ? (
                    <div
                      //center
                      className="flex justify-center items-center my-56"
                    >
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    </div>
                  ) : (
                    ScanTabPanel()
                  )}

                  {isLoading ? (
                    <div
                      //center
                      className="flex justify-center items-center my-56"
                    >
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    </div>
                  ) : (
                    PrintTabPanel()
                  )}

                  {/* <DevelopeTabPanel value={value} index={0}></DevelopeTabPanel> */}
                </Box>
              </div>
            ) : null}
          </div>
        )}
      </ShopLayout>
    </div>
  );
}
