const config = {};

config.orderStatus = [
  "Waiting for drop off",
  "Developing",
  "Order finished",
  "Picked up",
  "Destroyed",
];

config.usertype = {
  customer: ["customer"],
  lab: ["labStaff"],
  labAdmin: ["admin", "labOwner"],
};

module.exports = config;
