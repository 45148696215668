import React, { useState, useEffect, useTransition } from "react";

import globalStore from "../../store/globalStore";
import { useNavigate } from "react-router-dom";
import { sessionService } from "redux-react-session";
import CustomerLayout from "../../component/costumer/customerLayout";
import user_api from "../../api/user_api";
import Barcode from "react-barcode";
import { QRLine, QRNormal } from "react-qrbtf";
// import { QRCodeSVG } from "qrcode.react";

export default function Profile() {
  const navigate = useNavigate();
  // //console.log(globalStore.getState().session);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  useEffect(() => {
    setUser({
      name: globalStore.getState().session.user.name,
      email: globalStore.getState().session.user.email,
      phone: globalStore.getState().session.user.phone,
      password: globalStore.getState().session.user.password,
    });
  }, []);

  const [inputError, setInputError] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const [Message, setMessage] = useState("");

  const handleChangePassword = async (e) => {
    e.preventDefault();
    await user_api
      .login(user.email, user.password)
      .then(async (response) => {
        // console.log(response);
        if (response.document !== null) {
          await user_api
            .forgetPassword(globalStore.getState().session.user.email)
            .then((forgetPassword_response) => {
              if (
                forgetPassword_response.message === "Email sent successfully"
              ) {
                setMessage(forgetPassword_response.message);
                setTimeout(() => {
                  setMessage("");
                }, 2000);
              } else {
                setMessage(forgetPassword_response.message);
                setTimeout(() => {
                  setMessage("");
                }, 2000);
              }
            })
            .catch((error) => {
              //console.log(error);
            });
        } else {
          setMessage("Wrong password");
          setTimeout(() => {
            setMessage("");
          }, 2000);
        }
      })
      .catch((error) => {
        //console.log(error);
        setMessage("Wrong password");
        setTimeout(() => {
          setMessage("");
        }, 2000);
      });
  };

  const handleChangeUserInfo = async (e) => {
    e.preventDefault();
    var request_body = {};
    request_body["user_id"] = globalStore.getState().session.user._id;
    if (
      user.name !== "" &&
      user.name !== globalStore.getState().session.user.name
    ) {
      request_body["name"] = user.name;
    }
    if (
      user.email !== "" &&
      user.email !== globalStore.getState().session.user.email
    ) {
      request_body["email"] = user.email;
    }
    if (
      user.phone !== "" &&
      user.phone !== globalStore.getState().session.user.phone
    ) {
      request_body["phone"] = user.phone;
    }
    user_api.update_user(request_body).then(async (response) => {
      // console.log(response);
      if (response.hasOwnProperty("message")) {
        setMessage(response.message);
        //console.log(response);
      } else {
        await sessionService.saveUser(response);
        setMessage("Your profile has been updated");
        setTimeout(() => {
          setMessage("");
        }, 5000);
      }
    });
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "name":
          if (!value) {
            stateObj[name] = "Please enter Name";
          }
          break;

        case "email":
          //check if value is email or not
          if (!value) {
            stateObj[name] = "Please enter Email";
          } else if (!value.includes("@" || ".") || value.includes(" ")) {
            stateObj[name] = "Please enter a valid Email";
          }
          break;
        case "phone":
          if (!value) {
            stateObj[name] = "Please enter Phone Number";
          } else if (value.length < 10) {
            stateObj[name] = "Please enter a valid Phone Number";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Current Password";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  const handleMessage = (event) => {
    setMessage("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleChangePassword(event);
    }
  };

  return (
    <div>
      <CustomerLayout describtion="profile" bgColor="bg-main">
        <div className="flex flex-wrap justify-center md:justify-between items-center">
          <Barcode
            value={globalStore.getState().session.user._id}
            //no text
            displayValue={false}
            // lineColor="#798998"
          ></Barcode>
          {/* <QRLine */}
          <QRNormal
            value={globalStore.getState().session.user._id}
            // value="123"
            className="pl-1.5"
            styles={{ svg: { width: "250px" } }}
            funcType="B"
            size={100}
            opacity={80}
            // posType="roundRect"
            lineColor="#798998"
            posColor="#203B54"
            direction="loop"
            // ></QRLine>
          ></QRNormal>

          {/* <QRCodeSVG value={order_id} /> */}
        </div>
        {Message ? (
          <div>
            {Message !== "Email already exists" &&
            Message !== "Wrong password" ? (
              <div
                id="toast-default"
                class="flex justify-between items-center w-full max-w-xs p-4  bg-green-10 rounded-lg shadow  absolute top-24 right-8 mr-4 mb-4 font-Gilroy-m text-green-100"
                role="alert"
              >
                <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8  rounded-lg ">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14 4C7.37273 4 2 9.37273 2 16C2 22.6273 7.37273 28 14 28C20.6273 28 26 22.6273 26 16C26 9.37273 20.6273 4 14 4ZM19.2015 13.9709C19.2972 13.8614 19.3701 13.7339 19.4159 13.5958C19.4617 13.4577 19.4794 13.3119 19.4679 13.1669C19.4565 13.0219 19.4162 12.8806 19.3494 12.7514C19.2825 12.6222 19.1905 12.5077 19.0788 12.4146C18.967 12.3214 18.8378 12.2516 18.6987 12.2092C18.5595 12.1667 18.4133 12.1525 18.2686 12.1674C18.1239 12.1824 17.9836 12.2261 17.8561 12.296C17.7285 12.3659 17.6162 12.4606 17.5258 12.5745L12.8349 18.2025L10.4076 15.7742C10.2019 15.5755 9.92632 15.4655 9.64029 15.468C9.35426 15.4705 9.08064 15.5852 8.87838 15.7875C8.67612 15.9897 8.56139 16.2633 8.5589 16.5494C8.55642 16.8354 8.66637 17.111 8.86509 17.3167L12.1378 20.5895C12.245 20.6966 12.3733 20.7802 12.5146 20.835C12.6559 20.8898 12.8071 20.9145 12.9585 20.9076C13.1099 20.9007 13.2582 20.8624 13.3939 20.795C13.5297 20.7277 13.6499 20.6328 13.7469 20.5164L19.2015 13.9709Z"
                      fill="#0E927A"
                    />
                  </svg>
                </div>
                <div class="ml-3 text-sm  font-Gilroy-l">{Message}.</div>
                <button
                  type="button"
                  class="rounded-lg  h-10 w-10 pl-6"
                  data-dismiss-target="#toast-default"
                  aria-label="Close"
                  onClick={handleMessage}
                >
                  <span class="sr-only">Close</span>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                      fill="#0E927A"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <div
                id="toast-default"
                class="flex justify-between items-center w-full max-w-xs p-4  bg-red-100 rounded-lg shadow  absolute top-24 right-4 mr-4 mb-4 font-Gilroy-m text-red-600"
                role="alert"
              >
                <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8  rounded-lg ">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.875 6.125C19 1.25 11 1.25 6.125 6.125C1.25 11 1.25 19 6.125 23.875C11 28.75 18.875 28.75 23.75 23.875C28.625 19 28.75 11 23.875 6.125ZM18.5 20.25L15 16.75L11.5 20.25L9.75 18.5L13.25 15L9.75 11.5L11.5 9.75L15 13.25L18.5 9.75L20.25 11.5L16.75 15L20.25 18.5L18.5 20.25Z"
                      fill="#FF6868"
                    />
                  </svg>
                </div>
                <div class="ml-3 text-sm  font-Gilroy-l">{Message}.</div>
                <button
                  type="button"
                  class="rounded-lg  h-10 w-10 pl-6"
                  data-dismiss-target="#toast-default"
                  aria-label="Close"
                  onClick={handleMessage}
                >
                  <span class="sr-only">Close</span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 6.00714L13.9929 5L10 8.99286L6.00714 5L5 6.00714L8.99286 10L5 13.9929L6.00714 15L10 11.0071L13.9929 15L15 13.9929L11.0071 10L15 6.00714Z"
                      fill="#FF6868"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
        ) : null}
        <p className="text-xl text-dsblue-100 px-2 py-4">
          {" "}
          Personal Informations
        </p>
        <div className="h-full grid grid-cols-1  gap-3 py-4 px-2">
          <div>
            <form className="flex flex-col">
              <div className="grid grid-cols-2  gap-6">
                <div className="md:col-span-1">
                  <label className="text-lg text-dsblue-80 font-Gilroy-m">
                    Name:
                  </label>
                  <input
                    className="w-full p-2 border-2 border-gray-300 rounded-lg text-dsblue-80 font-Gilroy-l-itl"
                    type="text"
                    name="name"
                    value={user.name}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                  <div className="text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg">
                    {inputError.name && (
                      <span className="">{inputError.name}</span>
                    )}
                  </div>
                </div>
                <div className="md:col-span-1">
                  <label className="text-lg text-dsblue-80 font-Gilroy-m">
                    Phone:
                  </label>
                  <input
                    className="w-full p-2 border-2 border-gray-300 rounded-lg text-dsblue-80 font-Gilroy-l-itl"
                    type="text"
                    name="displayName"
                    value={user.phone}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                  <div className="text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg">
                    {inputError.phone && (
                      <span className="">{inputError.phone}</span>
                    )}
                  </div>
                </div>
                <div className="col-span-2">
                  <label className="text-lg text-dsblue-80 font-Gilroy-m">
                    Email:
                  </label>
                  <input
                    className="w-full p-2 border-2 border-gray-300 rounded-lg text-dsblue-80 font-Gilroy-l-itl"
                    type="text"
                    name="email"
                    value={user.email}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                  <div className="text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg">
                    {inputError.email && (
                      <span className="">{inputError.email}</span>
                    )}
                  </div>
                  <div className="mt-6">
                    <button
                      className="bg-dsblue-100 px-5 py-3 rounded-lg text-white"
                      disabled={
                        // inputError.displayName !== "" ||
                        // inputError.email !== "" ||
                        // inputError.username !== ""
                        inputError.name !== "" ||
                        inputError.phone !== "" ||
                        inputError.email !== ""
                      }
                      onClick={handleChangeUserInfo}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-span-2 ">
                <p className="pt-12 pb-6 text-lg text-dsblue-80 font-Gilroy-m">
                  To change password please enter current password
                </p>
                <div className="grid  grid-cols-2 gap-6">
                  <div>
                    <label className="text-lg text-dsblue-80 font-Gilroy-m">
                      Current Password:
                    </label>
                    <input
                      className="w-full p-2 border-2 border-gray-300 rounded-lg text-dsblue-80 font-Gilroy-l-itl"
                      type="password"
                      name="password"
                      onChange={onInputChange}
                      onBlur={validateInput}
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                  </div>
                  <div>
                    <br />
                    <button
                      className="bg-dsblue-100 px-2 py-3 rounded-lg text-white"
                      onClick={handleChangePassword}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomerLayout>
    </div>
  );
}
