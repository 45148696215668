import React, { useEffect, useState, Fragment, useMemo } from "react";
import order_api from "../../../api/order_api";
import globalStore from "../../../store/globalStore";
import { useNavigate } from "react-router-dom";
import CustomerLayout from "../../../component/costumer/customerLayout";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import config from "../../../config";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
// import { Pagination } from "@nextui-org/react";

import { Menu, Transition, Disclosure } from "@headlessui/react";
import Loading from "../../../component/general/loading/loading";

export default function OrderHistoryPage() {
  const [orderHistory, setOrderHistory] = useState([]);
  const [sort, setSort] = useState("descending");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    //refresh order history

    if (isLoading) {
      order_api
        .OrderHistoryWithPagination(
          // globalStore.getState().user._id,
          globalStore.getState().session.user._id,
          sort,
          skip,
          limit
        )
        .then((res) => {
          // console.log("res", res);
          setOrderHistory(res);
          setIsLoading(false);
          // globalStore.dispatch({
          //   type: "SET_ORDER_HISTORY",
          //   payload: res,
          // });
        });
    }
  }, [orderHistory]);

  const handleEntries = (e, limit) => {
    e.preventDefault();
    setLimit(limit);
    setSkip(0);
    order_api

      .OrderHistoryWithPagination(
        // globalStore.getState().user._id,
        globalStore.getState().session.user._id,
        sort,
        0,
        limit
      )
      .then((res) => {
        // console.log("res", res);
        setOrderHistory(res);
      });
  };

  const ShowEntries = () => {
    return (
      <Menu as="div" className="relative  text-left w-full">
        <div className=" w-full">
          <div className=" ">
            <div className=" flex justify-center">
              <Menu.Button className="">
                <div className="border border-dsblue-100 rounded-lg">
                  <p className="text-dsblue-90 px-2 text-xs py-1 text-center rounded-lg ">
                    {limit}
                  </p>
                </div>
              </Menu.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left absolute z-20 mt-2 w-full rounded-md shadow-lg bg-white  ring-opacity-5 focus:outline-none overflow-y-auto justify-center">
            <div className="py-1 text-sm">
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={
                      "text-center cursor-pointer m-1 rounded-md " +
                      `${active && "bg-dsblue-80 text-white text-center"}`
                    }
                    onClick={(e) => {
                      handleEntries(e, 5);
                    }}
                  >
                    5
                  </p>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={
                      "text-center cursor-pointer m-1 rounded-md " +
                      `${active && "bg-dsblue-80 text-white text-center"}`
                    }
                    onClick={(e) => {
                      handleEntries(e, 10);
                    }}
                  >
                    10
                  </p>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={
                      "text-center cursor-pointer m-1 rounded-md " +
                      `${active && "bg-dsblue-80 text-white text-center"}`
                    }
                    onClick={(e) => {
                      handleEntries(e, 20);
                    }}
                  >
                    20
                  </p>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={
                      "text-center cursor-pointer m-1 rounded-md " +
                      `${active && "bg-dsblue-80 text-white text-center"}`
                    }
                    onClick={(e) => {
                      handleEntries(e, 50);
                    }}
                  >
                    50
                  </p>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const countOrderTotalPrice = (order) => {
    let total = 0;
    order.forEach((item) => {
      total += item.Price.totalPrice;
    });
    return total;
  };
  const getDate = (date) => {
    // 2023-08-07T16:23:08.710Z
    // need 08-07
    let month = date.slice(5, 7);
    let day = date.slice(8, 10);
    return month + "-" + day;
  };
  const statusBar = (status) => {
    var style = "";
    if (config.orderStatus[0] === status) {
      style =
        "border border-green-500 rounded-lg px-1 text-center text-green-500";
    } else if (config.orderStatus[1] === status) {
      style =
        "border border-yellow-800 rounded-lg px-1 text-center text-yellow-800 ";
    } else if (config.orderStatus[2] === status) {
      style =
        "border border-blue-500 rounded-lg px-1 text-center text-blue-500 ";
    } else if (config.orderStatus[3] === status) {
      style =
        "border border-purple-500 rounded-lg px-1 text-center text-purple-500  ";
    } else if (config.orderStatus[4] === status) {
      style =
        "border border-red-500 rounded-lg px-1 text-center text-red-500  ";
    } else {
      style =
        "border border-green-500 rounded-lg px-1 text-center text-green-500 ";
    }
    return (
      <div className={style}>
        <p className=" text-center ">{status}</p>
      </div>
    );
  };
  return (
    <div className="bg-white">
      <CustomerLayout describtion="order">
        {isLoading ? (
          <div>
            <Loading></Loading>
          </div>
        ) : (
          <div>
            {/* <div className="bg-white rounded-lg shadow-lg col-span-2 px-4 md:px-8 pt-2 md:pt-4 pb-4 md:pb-8"> */}
            <div className="bg-white rounded-lg shadow-lg col-span-2  p-4 md:p-8 ">
              <div className="flex justify-between items-center pb-3">
                <p className=" text-dsblue-90">Order History</p>

                <div className="flex justify-end">
                  <div className=" flex justify-center items-center gap-3">
                    <p>Show</p>
                    {ShowEntries()}
                    <p>entries</p>
                  </div>
                </div>
              </div>
              <table className="table-auto w-full">
                <thead className="bg-gray-50 rounded-lg pb-2 uppercase ">
                  <tr className="">
                    <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-left">
                      Date
                    </th>
                    <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-left ">
                      Status
                    </th>
                    <th className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm md:text-lg text-center">
                      <p className="">Rolls </p>
                    </th>
                    <th className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm md:text-lg text-center">
                      <p className="">Price</p>
                    </th>
                    {/* <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-center"></th> */}
                    <th className="text-dsblue-90 md:px-4 text-xs sm:text-sm md:text-lg text-center ">
                      <p className="hidden md:block">Lab</p>
                    </th>

                    <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {orderHistory.data?.map((order) => (
                    <tr className=" border-b ">
                      <td className="text-dsblue-60 px-1 md:px-4 text-xs sm:text-sm  py-2">
                        <p>{order.orderDate.split("-")[0]}</p>
                        <p>{getDate(order.orderDate)}</p>
                      </td>
                      <td className="text-dsblue-60 px-1 md:px-4 text-xs sm:text-sm  py-2 ">
                        <div className="">{statusBar(order.currentStatus)}</div>
                      </td>
                      {/* <td className="text-dsblue-60 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <p className=" text-center ">{order.order.length}</p>
              </td> */}
                      <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                        <p className=" text-center">{order.order.length}</p>
                      </td>{" "}
                      <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                        <p className=" text-center">
                          {"$" + countOrderTotalPrice(order.order)}
                        </p>
                      </td>{" "}
                      <td className="text-dsblue-60 md:px-4 text-xs sm:text-sm  py-2 text-center h-full">
                        <p className=" text-center hidden md:block">
                          {order.store.shopName}
                        </p>
                      </td>
                      <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                        <button
                          className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white"
                          onClick={() => {
                            navigate("/orderHistory/" + order._id);
                          }}
                        >
                          <p className=" text-center">View</p>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-end pt-6">
                {/* make a pagination with slide function  */}

                <Stack spacing={2}>
                  <Pagination
                    count={
                      orderHistory.total % limit === 0
                        ? orderHistory.total / limit
                        : Math.floor(orderHistory.total / limit) + 1
                    }
                    // variant="outlined"
                    // shape="rounded"
                    onChange={(e, page) => {
                      // console.log("page", page);
                      order_api
                        .OrderHistoryWithPagination(
                          // globalStore.getState().user._id,
                          globalStore.getState().session.user._id,
                          sort,
                          (page - 1) * limit,
                          limit
                        )
                        .then((res) => {
                          // console.log("res", res);
                          setOrderHistory(res);
                        });
                    }}
                  />
                </Stack>
              </div>
            </div>
          </div>
        )}
      </CustomerLayout>
    </div>
  );
}
