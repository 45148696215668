import React, { useEffect, useState, Fragment, useRef } from "react";
import item_api from "../../../api/item_api";
import CustomerLayout from "../../../component/costumer/customerLayout";
import shop_api from "../../../api/shop_api";
import config from "../../../config";
import order_api from "../../../api/order_api";
import { Listbox } from "@headlessui/react";
import { Transition } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import empty from "../../../asset/img/empty-cart.png";
import globalStore from "../../../store/globalStore";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function UpdateOrderForm() {
  const order_id = window.location.href.split("/").pop().split("#")[0];
  const [ChemicalType, setChemicalType] = useState("");
  const [PullPushSwitch, setPullPushSwitch] = useState(false);
  const [CurrentDevItem, setCurrentDevItem] = useState({
    _id: "",
    name: "",
    exposure: [],
    chamical: "",
    push_pull: [],
    size: [],
    cuts: [],
    shopName: "",
  });
  const [CurrentScanItem, setCurrentScanItem] = useState({
    _id: "",
    filmSize: "",
    scans: [],
    tiffPrice: 0,
    shopId: "",
  });
  const [CurrentPrintItem, setCurrentPrintItem] = useState({
    _id: "",
    filmSize: "",
    exposures: [
      {
        numberOfEXP: "",
        printSizes: [
          {
            size: "",
            price: 0,
            extraSetPrice: 0,
          },
        ],
      },
    ],
    shopId: "",
    border: null,
    printFinish: null,
  });
  const [printSizes, setPrintSizes] = useState([]);
  const [currentPrintSize, setCurrentPrintSize] = useState({});

  const [developeItems, setDevelopeItems] = useState([]);
  const [scanItems, setScanItems] = useState([]);
  const [allOrderItems, setAllOrderItems] = useState([]);

  const [PrintItems, setPrintItems] = useState([]);
  const [Item_order_selected, setItem_order_selected] = useState("order");
  const [SingleRollDetail, setSingleRollDetail] = useState({
    info: {
      size: "",
      exposure: 0,
      chamical: "",
      price: 0,
    },

    dev_addon: {
      push_pull: false,
      pushOrPull: "",
      price: 0,
    },

    dev_rush: {
      dev_rush: false,
      price: 0,
    },

    scan: {
      scan: false,
      scanRes: "",
      price: 0,
      incloudTiff: false,
    },

    scan_addon: {
      tiff: false,
      price: 0,
    },

    print: {
      prints: false,
      prtintSize: "",
      print_set: 0,
      price: 0,
    },
    print_border: {
      border: false,
      price: 0,
    },
    print_finish: {
      print_finish: "",
      price: 0,
    },
    cuts: {
      cuts: "in 4's",
      price: 0,
    },
    other: {
      destroy: false,
      note: "",
    },
    Price: {
      totalPrice: 0,
    },
  });
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [Message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const setMessageFunction = (message) => {
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };
  const [step, setStep] = useState(0);
  const [ScanStep, setScanStep] = useState(0);

  const [PrintStep, setPrintStep] = useState(0);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [shops, setShops] = useState([]);
  const [currentShop, setCurrentShop] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      order_api.OrderHistoryById(order_id).then((res) => {
        //console.log("res", res);
        // setOrder(res);
        //put order info into allOrderItems
        // let temp = [];
        // res.order.map((item) => {
        //   temp.push({
        //     ...item,
        //     shopName: item.shopId.name,
        //   });
        // });
        setAllOrderItems(res.order);
        setCurrentShop(res.store);
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (currentShop) {
      if (
        Item_order_selected === "develope" ||
        Item_order_selected === "scan" ||
        Item_order_selected === "print"
      ) {
        item_api
          .GetItems(
            // "644e7ebedc63924e5a3afbf6", //shopID
            currentShop._id, //shopID
            Item_order_selected
          )
          .then((res) => {
            //console.log(res);
            // setItem(res);
            // setDevelopeItems(res);
            if (Item_order_selected !== "order") {
              if (Item_order_selected === "develope") {
                setDevelopeItems(res);
              }
              if (Item_order_selected === "scan") {
                setScanItems(res);
              }
              if (Item_order_selected === "print") {
                setPrintItems(res);
              }
            }
          });
      }
    } else {
      //console.log("no shop");
    }
  }, [Item_order_selected]);
  useEffect(() => {
    //count price
    let price = 0;
    if (SingleRollDetail.info.size !== "") {
      price += SingleRollDetail.info.price;
    }
    if (SingleRollDetail.dev_addon.push_pull) {
      price += SingleRollDetail.dev_addon.price;
    }
    if (SingleRollDetail.dev_rush.dev_rush) {
      price += SingleRollDetail.dev_rush.price;
    }
    if (SingleRollDetail.scan.scan) {
      price += SingleRollDetail.scan.price;
    }
    if (SingleRollDetail.scan_addon.tiff) {
      price += SingleRollDetail.scan_addon.price;
    }
    if (SingleRollDetail.print.prints) {
      price += SingleRollDetail.print.price;
    }
    if (SingleRollDetail.print_border.border) {
      price += SingleRollDetail.print_border.price;
    }
    if (SingleRollDetail.print_finish.print_finish !== "") {
      price += SingleRollDetail.print_finish.price;
    }
    if (SingleRollDetail.cuts.cuts !== "") {
      price += SingleRollDetail.cuts.price;
    }
    setPrice(price);
  }, [SingleRollDetail]);
  const reset_single_roll_detail = async () => {
    setSingleRollDetail({
      info: {
        size: "",
        exposure: 0,
        chamical: "",
        price: 0,
      },

      dev_addon: {
        push_pull: false,
        pushOrPull: "",
        price: 0,
      },

      dev_rush: {
        dev_rush: false,
        price: 0,
      },

      scan: {
        scan: false,
        scanRes: "",
        price: 0,
        incloudTiff: false,
      },

      scan_addon: {
        tiff: false,
        price: 0,
      },

      print: {
        prints: false,
        prtintSize: "",
        print_set: 0,
        price: 0,
      },
      print_border: {
        border: false,
        price: 0,
      },
      print_finish: {
        print_finish: "",
        price: 0,
      },
      cuts: {
        cuts: "in 4's",
        price: 0,
      },
      other: {
        destroy: false,
        note: "",
      },
      Price: {
        totalPrice: 0,
      },
    });
  };
  const Dev = () => {
    const steps = ["ChemicalType", "size", "exposure", "dev_addon", "dev_rush"];

    // const [step, setStep] = useState(0);
    const _map = new Map();
    const ChemicalType_OnClick = async (s) => {
      if (step === 0) {
        setStep(step + 1);
      }
      setChemicalType(s.chamical);

      setCurrentDevItem(s);
      setPullPushSwitch(false);

      if (SingleRollDetail.info.chamical === "") {
        setSingleRollDetail({
          ...SingleRollDetail,
          info: {
            ...SingleRollDetail.info,
            chamical: s.chamical,
          },
        });
      } else {
        //console.log("reset");
        await reset_single_roll_detail();
        setStep(1);
      }
    };
    const Size_OnClick = (size, price) => {
      if (step === 1) {
        setStep(step + 1);
      }
      setSingleRollDetail({
        ...SingleRollDetail,
        info: {
          ...SingleRollDetail.info,
          size: size,
          price: price,
        },
      });
    };

    const handleSetExposure = (e) => {
      e.preventDefault();
      if (e.target.value >= 0) {
        setSingleRollDetail({
          ...SingleRollDetail,
          info: {
            ...SingleRollDetail.info,
            exposure: parseInt(e.target.value),
          },
        });
      }
      // else if (e.target.value > single_c.mark_total.total) {
      //   // handleMsg(t("Mark_cannot_be_higher_than_total_mark"), 3000);
      // }
    };

    _map.set("ChemicalType", {
      result: (
        <div className="pt-8">
          <p className="text-lg text-dsblue-100">Select Chemical Type</p>

          <p className="text-sm text-dsblue-60">
            {"You can find following word on the label of you film."}
          </p>
          <p className="text-sm text-dsblue-60">
            {"(Disposable camera usually use C-41 and Black&White)"}
          </p>

          <div className="grid grid-cols-2 gap-3 pt-3 ">
            {developeItems.map((s) => {
              return (
                <button
                  className={
                    SingleRollDetail.info.chamical === s.chamical
                      ? "flex justify-center border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                      : "flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
                  }
                  onClick={() => {
                    ChemicalType_OnClick(s);
                  }}
                >
                  <div className="flex flex-col">
                    <p
                      className={
                        SingleRollDetail.info.chamical === s.chamical
                          ? "text-lg text-white"
                          : "text-lg"
                      }
                    >
                      {s.chamical}
                    </p>
                    <p
                      className={
                        SingleRollDetail.info.chamical === s.chamical
                          ? "text-sm text-white"
                          : "text-sm"
                      }
                    >
                      {s.describtion}
                    </p>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      ),
    });
    _map.set("size", {
      result: (
        <div>
          <p className="text-lg text-dsblue-100">Select Roll Size</p>
          <a
            className="text-sm text-dsblue-60 underline"
            href="https://thedarkroom.com/film-formats/"
            target="_blank"
            rel="noreferrer"
          >
            {"Not sure what it means? (Disposable camera use 135)"}
          </a>
          <div className="grid grid-cols-2 gap-3 pt-3">
            {developeItems
              .filter(
                (s) => s.chamical === ChemicalType && s.name === "develope"
              )
              .map((s) => {
                return s.size.map((size_item) => {
                  return (
                    <button
                      className={
                        SingleRollDetail.info.size === size_item.name
                          ? "flex justify-between border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                          : "flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
                      }
                      onClick={() => {
                        Size_OnClick(size_item.name, size_item.price);
                      }}
                    >
                      <p>{size_item.name} Film</p>
                      <p> ${size_item.price}</p>
                    </button>
                  );
                });
              })}
          </div>
        </div>
      ),
    });
    // //console.log(CurrentDevItem);
    _map.set("exposure", {
      result: (
        <div>
          <div className="grid grid-cols-2 gap-3 pt-3 ">
            <div className="w-full">
              <p className="text-lg text-dsblue-100">Enter Exposure</p>
              <p className="text-sm text-dsblue-60">
                {
                  "How many photos dose the roll have? (Disposable camera 27. Normal:10 - 36)"
                }
              </p>
              {SingleRollDetail.info.size !== "135" ? (
                <input
                  className={
                    Message === "Please fill out exposures"
                      ? "border border-red-500 rounded-md  px-2 py-1 w-full bg-white"
                      : " border rounded-md px-2 py-1 w-full bg-white"
                  }
                  type="number"
                  name="exposure"
                  value={SingleRollDetail.info.exposure}
                  onChange={(e) => {
                    handleSetExposure(e);
                  }}
                ></input>
              ) : (
                <Listbox
                  // value={0}
                  onChange={(e) => {
                    setSingleRollDetail({
                      ...SingleRollDetail,
                      info: {
                        ...SingleRollDetail.info,
                        exposure: parseInt(e),
                      },
                    });
                  }}
                >
                  <Listbox.Button
                    className={
                      Message === "Please fill out exposures"
                        ? "border border-red-500 rounded-md  px-2 py-1 w-full bg-white"
                        : " border rounded-md px-2 py-1 w-full bg-white"
                    }
                  >
                    {SingleRollDetail.info.exposure === 0
                      ? "Select exposure"
                      : SingleRollDetail.info.exposure}
                  </Listbox.Button>
                  <Listbox.Options
                    className={
                      " border rounded-md px-2 py-1 w-full bg-white text-center"
                    }
                  >
                    <Listbox.Option value={12}>12</Listbox.Option>
                    <Listbox.Option value={24}>24</Listbox.Option>
                    <Listbox.Option value={27}>27</Listbox.Option>
                    <Listbox.Option value={36}>36</Listbox.Option>
                  </Listbox.Options>
                </Listbox>
              )}
            </div>
          </div>
          <div className="pt-6">
            <div className="grid grid-cols-2  px-4 py-2 rounded-lg text-left">
              <button
                onClick={() => {
                  setPullPushSwitch(!PullPushSwitch);
                }}
                className={
                  !CurrentDevItem.push_pull.length > 0 ? "hidden" : "block"
                }
              >
                <p className="hover:text-dsblue-100 text-dsblue-60  hover:scale-110">
                  + Push/Pull
                </p>
              </button>
              <button
                className={CurrentDevItem.dev_rush ? "block" : "hidden"}
                onClick={() => {
                  setSingleRollDetail({
                    ...SingleRollDetail,
                    dev_rush: {
                      ...SingleRollDetail.dev_rush,
                      dev_rush: !SingleRollDetail.dev_rush.dev_rush,
                      price: CurrentDevItem.dev_rush.price,
                    },
                  });
                }}
              >
                {/* <p className="hover:text-dsblue-100 text-dsblue-60 hover:-translate-y-1 hover:scale-110 text-lg"> */}
                {SingleRollDetail.dev_rush.dev_rush ? (
                  <p className="text-dsblue-100  hover:scale-110 ">
                    Added Rush Service $
                    {CurrentDevItem.dev_rush
                      ? CurrentDevItem.dev_rush.price
                      : 0}
                  </p>
                ) : (
                  <p className="hover:text-dsblue-100 text-dsblue-60  hover:scale-110 ">
                    + Rush Service $
                    {CurrentDevItem.dev_rush
                      ? CurrentDevItem.dev_rush.price
                      : 0}
                  </p>
                )}
                {/* </p> */}
              </button>
            </div>
          </div>
        </div>
      ),
    });

    _map.set("dev_addon", {
      result: (
        <div>
          <p>Push/Pull</p>
          <div className="grid grid-cols-2 gap-3 pt-3">
            <button
              className={
                SingleRollDetail.dev_addon.pushOrPull === "Push"
                  ? "flex justify-center border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                  : "flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
              }
              onClick={() => {
                setSingleRollDetail({
                  ...SingleRollDetail,
                  dev_addon: {
                    ...SingleRollDetail.dev_addon,
                    push_pull: true,
                    pushOrPull: "Push",
                    price:
                      CurrentDevItem.push_pull.length > 0
                        ? CurrentDevItem.push_pull[0].price
                        : 0,
                  },
                });
              }}
            >
              <p>
                Push $
                {CurrentDevItem.push_pull.length > 0
                  ? CurrentDevItem.push_pull[0].price
                  : 0}
              </p>
            </button>
            <button
              className={
                SingleRollDetail.dev_addon.pushOrPull === "Pull"
                  ? "flex justify-center border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                  : "flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
              }
              onClick={() => {
                setSingleRollDetail({
                  ...SingleRollDetail,
                  dev_addon: {
                    ...SingleRollDetail.dev_addon,
                    push_pull: true,
                    pushOrPull: "Pull",
                    price:
                      CurrentDevItem.push_pull.length > 0
                        ? CurrentDevItem.push_pull[1].price
                        : 0,
                  },
                });
              }}
            >
              <p>
                Pull $
                {CurrentDevItem.push_pull.length > 0
                  ? CurrentDevItem.push_pull[1].price
                  : 0}
              </p>
            </button>
          </div>
        </div>
      ),
    });

    return (
      <div className="flex flex-col gap-6">
        <div className="text-center text-lg ">Single Roll price: ${price}</div>
        {step >= 0 ? _map.get(steps[0]).result : null}
        {step >= 1 ? _map.get(steps[1]).result : null}
        {step >= 2 ? _map.get(steps[2]).result : null}
        {PullPushSwitch ? _map.get(steps[3]).result : null}
        {step >= 2 ? (
          <div className="grid grid-cols-2 gap-3 pt-3">
            <button
              className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
              onClick={() => {
                setStep(0);
                reset_single_roll_detail();
              }}
            >
              <p>Re-Select-All</p>
            </button>
            <button
              onClick={() => {
                if (SingleRollDetail.info.exposure > 0) {
                  setItem_order_selected("scan");
                } else {
                  setMessageFunction("Please fill out exposures");
                }
              }}
              className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
            >
              <p>Next Section</p>
            </button>
          </div>
        ) : null}
      </div>
    );
  };
  const Scan = () => {
    const steps = ["chooseScan", "chooseRes", "ScanAddon"];

    // //console.log("step", step);
    const handleChooseScan = (toScan) => {
      if (toScan) {
        setScanStep(1);
        setSingleRollDetail({
          ...SingleRollDetail,
          scan: {
            ...SingleRollDetail.scan,
            scan: true,
          },
        });
      } else {
        // setStep(2);
        setItem_order_selected("print");
        setSingleRollDetail({
          ...SingleRollDetail,
          scan: {
            ...SingleRollDetail.scan,
            scan: false,
            price: 0,
            scanRes: "",
            incloudTiff: false,
          },
          scan_addon: {
            ...SingleRollDetail.scan_addon,
            tiff: false,
            price: 0,
            // incloudTiff: false,
          },
        });
      }
    };

    const handleChooseRes = (scan, item) => {
      // setStep(2);
      setScanStep(2);
      //console.log("res", scan, item);
      setSingleRollDetail({
        ...SingleRollDetail,
        scan: {
          ...SingleRollDetail.scan,
          scanRes: scan.scanRes,
          price: scan.price,
          incloudTiff: scan.incloudTiff,
          // price: CurrentScanItem[res].price,
        },
        scan_addon: {
          ...SingleRollDetail.scan_addon,
          tiff: scan.incloudTiff,
          price: scan.incloudTiff ? 0 : SingleRollDetail.scan_addon.price,
        },
      });
      setCurrentScanItem(item);
    };

    const _map = new Map();
    _map.set("chooseScan", {
      result: (
        <div className="pt-8">
          <p>Do you want your photo in digital file?</p>
          <div className="grid grid-cols-2 gap-3 pt-3">
            <button
              onClick={() => {
                // setScanStep(1);
                handleChooseScan(true);
              }}
              // className="flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white "
              className={
                SingleRollDetail.scan.scan
                  ? "flex justify-between border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                  : "flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
              }
            >
              {"Digital Scans (Emailed)"}
            </button>
            <button
              onClick={() => {
                // setScanStep(1);
                handleChooseScan(false);
              }}
              className="flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white "
            >
              {"Do not scan"}
            </button>
          </div>
        </div>
      ),
    });
    _map.set("chooseRes", {
      result: (
        <div className="pt-8">
          <p>Select Resolution</p>
          <div className="grid grid-cols-2 gap-3 pt-3">
            {scanItems
              .filter((i) => {
                if (i.filmSize === SingleRollDetail.info.size) {
                  // //console.log("i", i);
                  return i;
                }
              })
              .map((item) => {
                return item.scans.map((scan, index) => {
                  // //console.log("scan", scan.incloudTiff);
                  return (
                    <button
                      onClick={() => {
                        handleChooseRes(scan, item);
                      }}
                      className={
                        SingleRollDetail.scan.scanRes === scan.scanRes
                          ? "flex w-full justify-between border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                          : "flex w-full justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
                      }
                    >
                      <p>{scan.scanRes}</p>
                      {scan.incloudTiff === true ? <p>Included Tiff</p> : null}
                      <p>${scan.price}</p>
                    </button>
                  );
                });
              })}
          </div>
        </div>
      ),
    });

    const handleChooseAddon = () => {
      setScanStep(3);
      if (!SingleRollDetail.scan.incloudTiff) {
        setSingleRollDetail({
          ...SingleRollDetail,
          scan_addon: {
            ...SingleRollDetail.scan_addon,
            tiff: SingleRollDetail.scan_addon.tiff ? false : true,
            price: SingleRollDetail.scan.incloudTiff
              ? 0
              : CurrentScanItem.tiffPrice,
          },
        });
      }
    };
    _map.set("ScanAddon", {
      result: (
        <div className="pt-8">
          <p>Select Add-Ons</p>
          <p className="text-sm text-dsblue-60 ">
            TIFF files offer lossless compression and support for high-quality
            images, making them ideal for film scanning due to their ability to
            preserve all the details and nuances of the original film.
          </p>
          <div className="flex items-center justify-between gap-3 pt-3">
            <button
              // className={CurrentDevItem.dev_rush ? "block" : "hidden"}
              className={
                SingleRollDetail.scan_addon.tiff
                  ? "flex w-1/2 justify-between border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                  : "flex w-1/2 justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
              }
              onClick={() => {
                handleChooseAddon();
              }}
              disabled={SingleRollDetail.scan.incloudTiff ? true : false}
            >
              {/* <p className="hover:text-dsblue-100 text-dsblue-60 hover:-translate-y-1 hover:scale-110 text-lg"> */}
              {SingleRollDetail.scan_addon.tiff ? (
                <div className=" flex justify-between items-start w-full">
                  <p>Added Tiff file</p>
                  <p>
                    $
                    {SingleRollDetail.scan.incloudTiff
                      ? 0
                      : CurrentScanItem.tiffPrice}
                  </p>
                </div>
              ) : (
                <div className=" flex justify-between items-start w-full">
                  <p>+ Tiff file </p>
                  <p
                    className={
                      SingleRollDetail.scan.incloudTiff ? " hidden" : " block"
                    }
                  >
                    ${CurrentScanItem.tiffPrice}
                  </p>
                </div>
              )}
            </button>
          </div>
        </div>
      ),
    });

    return (
      <div className="text-dsblue-80">
        <div className="text-center text-lg ">Single Roll price: ${price}</div>
        {ScanStep >= 0 ? _map.get(steps[0]).result : null}

        {ScanStep >= 1 ? _map.get(steps[1]).result : null}

        {ScanStep >= 2 ? _map.get(steps[2]).result : null}
        {ScanStep >= 2 ? (
          <div className="grid grid-cols-2 gap-3 pt-3">
            <button
              className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
              onClick={() => {
                //back to dev step
                setItem_order_selected("develope");
              }}
            >
              <p>Back</p>
            </button>
            <button
              onClick={() => {
                //move to print step
                setItem_order_selected("print");
              }}
              className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
            >
              <p>Next Section</p>
            </button>
          </div>
        ) : null}
      </div>
    );
  };
  const Print = () => {
    const steps = [
      "choosePrint",
      "choosePrintSize",
      "chooseBorder",
      "chooseFinish",
      "chooseSets",
    ];
    const _map = new Map();
    // const printSizes = [];
    const handleChoosePrint = (toPrint) => {
      if (toPrint) {
        setPrintStep(1);

        //console.log("toPrint", toPrint);
        for (let i = 0; i < PrintItems.length; i++) {
          if (PrintItems[i].filmSize === SingleRollDetail.info.size) {
            setCurrentPrintItem(PrintItems[i]);
            // printSizes.push(PrintItems[i].printSize);
            if (PrintItems[i].exposures.length === 1) {
              // printSizes.push(PrintItems[i].exposures[0]);
              setPrintSizes(PrintItems[i].exposures[0].printSizes);
            } else {
              for (let j = 0; j < PrintItems[i].exposures.length; j++) {
                if (
                  PrintItems[i].exposures[j].numberOfEXP ===
                  SingleRollDetail.info.exposure
                ) {
                  // printSizes.push(PrintItems[i].exposures[j].printSizes);
                  // //console.log(printSizes);
                  setPrintSizes(PrintItems[i].exposures[j].printSizes);
                }
              }
            }

            // setSingleRollDetail({
            //   ...SingleRollDetail,
            //   print: {
            //     ...SingleRollDetail.print,
            //     prints: !SingleRollDetail.print.prints,
            //   },
            // });
            if (PrintItems[i].printFinish.length > 0) {
              setSingleRollDetail({
                ...SingleRollDetail,
                print: {
                  ...SingleRollDetail.print,
                  prints: true,
                },
                print_finish: {
                  ...SingleRollDetail.print_finish,
                  print_finish: PrintItems[i].printFinish[0].name,
                  price: PrintItems[i].printFinish[0].price,
                },
              });
            } else {
              setSingleRollDetail({
                ...SingleRollDetail,
                print: {
                  ...SingleRollDetail.print,
                  prints: true,
                },
              });
            }
            break;
          }
        }
      } else {
        // setStep(2);
        setItem_order_selected("other");
        setSingleRollDetail({
          ...SingleRollDetail,
          print: {
            ...SingleRollDetail.print,
            prints: false,
            prtintSize: "",
            print_set: 0,
            price: 0,
          },
        });

        setSingleRollDetail({
          ...SingleRollDetail,
          Price: {
            ...SingleRollDetail.other,
            totalPrice: price,
          },
        });
      }
    };
    _map.set("choosePrint", {
      result: (
        <div className="pt-8">
          <p>Do you want your photo in physical prints?</p>
          <div className="grid grid-cols-2 gap-3 pt-3">
            <button
              onClick={() => {
                // setScanStep(1);
                handleChoosePrint(true);
              }}
              // className="flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white "
              className={
                SingleRollDetail.print.prints
                  ? "flex justify-between border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                  : "flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
              }
            >
              {"Physical Prints (need to pick up)"}
            </button>
            <button
              onClick={() => {
                // setScanStep(1);
                handleChoosePrint(false);
              }}
              className="flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white "
            >
              {"Do not print"}
            </button>
          </div>
        </div>
      ),
    });
    //console.log("SingleRollDetail", SingleRollDetail);
    const handleChoosePrintSize = (printSize) => {
      //console.log("printSize123", printSize);
      //console.log("CurrentPrintItem", CurrentPrintItem);

      // else if (CurrentPrintItem.printFinish.length > 0) {
      //   setPrintStep(3);
      // } else {
      //   setPrintStep(4);
      // }
      setCurrentPrintSize(printSize);
      setSingleRollDetail({
        ...SingleRollDetail,
        print: {
          ...SingleRollDetail.print,
          prints: true,
          prtintSize: printSize.size,
          print_set: 1,
          price: printSize.price,
        },
      });
      // if (
      //   CurrentPrintItem.border.length > 0 &&
      //   CurrentPrintItem.printFinish.length > 0
      // ) {
      //   setPrintStep(4);
      // } else if (
      //   !CurrentPrintItem.border.length > 0 &&
      //   CurrentPrintItem.printFinish.length > 0
      // ) {
      //   setPrintStep(2);
      // } else if (
      //   CurrentPrintItem.border.length > 0 &&
      //   !CurrentPrintItem.printFinish.length > 0
      // ) {
      //   setPrintStep(3);
      // } else if (
      //   !CurrentPrintItem.border.length > 0 &&
      //   !CurrentPrintItem.printFinish.length > 0
      // ) {
      setPrintStep(5);
      // }
    };
    _map.set("choosePrintSize", {
      result: (
        <div className="pt-8">
          <div>
            <p>{"Select Print Size (default 1 set of photos)"}</p>
            <div className="grid grid-cols-2 gap-3 pt-3">
              {printSizes.map((item) => {
                // //console.log("scan", scan.incloudTiff);
                return (
                  <button
                    onClick={() => {
                      handleChoosePrintSize(item);
                    }}
                    className={
                      SingleRollDetail.print.prtintSize === item.size
                        ? "flex w-full justify-between border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                        : "flex w-full justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
                    }
                  >
                    <p>{item.size}</p>
                    <p>${item.price}</p>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      ),
    });
    //console.log("CurrentPrintItem", CurrentPrintItem);
    _map.set("chooseBorder", {
      result: (
        <div>
          {CurrentPrintItem.border?.length > 0 ? (
            <div className="pt-8">
              <p className="pb-3">{"Select Border Option"}</p>
              <div className="grid grid-cols-2 gap-3 pt-3">
                <button
                  onClick={() => {
                    // setScanStep(1);
                    setSingleRollDetail({
                      ...SingleRollDetail,
                      print_border: {
                        ...SingleRollDetail.print_border,
                        border: true,
                        price: CurrentPrintItem.border[0].price,
                      },
                    });
                    if (CurrentPrintItem.printFinish.length > 0) {
                      setPrintStep(3);
                    } else {
                      setPrintStep(5);
                    }
                  }}
                  // className="flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white "
                  className={
                    SingleRollDetail.print_border.border
                      ? "flex justify-between border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                      : "flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
                  }
                >
                  <p>Border</p>
                  <p>$ {CurrentPrintItem.border[0]?.price}</p>
                </button>
                <button
                  onClick={() => {
                    // setScanStep(1);
                    setSingleRollDetail({
                      ...SingleRollDetail,
                      print_border: {
                        ...SingleRollDetail.print_border,
                        border: false,
                        price: 0,
                      },
                    });
                    if (CurrentPrintItem.printFinish.length > 0) {
                      setPrintStep(3);
                    } else {
                      setPrintStep(5);
                    }
                  }}
                  className={
                    SingleRollDetail.print_border.border
                      ? "flex justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white "
                      : "flex justify-between border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                  }
                >
                  {"No Border"}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      ),
    });
    _map.set("chooseFinish", {
      result: (
        <div>
          {CurrentPrintItem.printFinish?.length > 0 ? (
            <div className="pt-8">
              <p className="pb-3">{"Select Finish Option"}</p>
              <div className="grid grid-cols-2 gap-3 pt-3">
                {CurrentPrintItem.printFinish.map((item) => {
                  // //console.log("scan", scan.incloudTiff);
                  return (
                    <button
                      onClick={() => {
                        setSingleRollDetail({
                          ...SingleRollDetail,
                          print_finish: {
                            ...SingleRollDetail.print_finish,
                            print_finish: item.name,
                            price: item.price,
                          },
                        });
                        setPrintStep(5);
                      }}
                      className={
                        SingleRollDetail.print_finish.print_finish === item.name
                          ? "flex w-full justify-between border px-4 py-2 rounded-lg text-center bg-dsblue-100 text-white"
                          : "flex w-full justify-between border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
                      }
                    >
                      <p>{item.name}</p>
                      <p>${item.price}</p>
                    </button>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      ),
    });

    const handleChooseSets = (e) => {
      e.preventDefault();
      if (e.target.value === 1) {
        setSingleRollDetail({
          ...SingleRollDetail,
          print: {
            ...SingleRollDetail.print,
            print_set: e.target.value,
            price: e.target.value * currentPrintSize.price,
          },
        });
      } else if (e.target.value > 1) {
        setSingleRollDetail({
          ...SingleRollDetail,
          print: {
            ...SingleRollDetail.print,
            print_set: e.target.value,
            price:
              currentPrintSize.price +
              (e.target.value - 1) * currentPrintSize.extraSetPrice,
          },
        });
      } else {
        if (e.target.value < 1) {
          setMessageFunction("Set number cannot below 1");
        }
        setSingleRollDetail({
          ...SingleRollDetail,
          print: {
            ...SingleRollDetail.print,
            print_set: 1,
            price: 1 * currentPrintSize.price,
          },
        });
      }
    };

    _map.set("chooseSets", {
      result: (
        <div className="pt-8">
          <p className="pb-3">
            {"Enter number of sets for your print (Ignore if you only want 1 set, additional sets are $" +
              currentPrintSize.extraSetPrice +
              " each)"}
          </p>{" "}
          <input
            className={
              Message === "Please fill out exposures"
                ? "border border-red-500 rounded-md  px-2 py-1 w-1/2 bg-white"
                : " border rounded-md px-2 py-1 w-1/2 bg-white"
            }
            type="number"
            value={SingleRollDetail.print.print_set}
            onChange={(e) => {
              e.preventDefault();
              handleChooseSets(e);
            }}
          ></input>
          {/* <div
            className={
              CurrentPrintItem.printFinish === null &&
              CurrentPrintItem.border === null
                ? " hidden"
                : "pt-8 pb-3"
            }
          >
            <p>Print add on:</p>
            {CurrentPrintItem.printFinish === null ? null : <div></div>}
            {CurrentPrintItem.border === null ? null : <div></div>}
          </div> */}
        </div>
      ),
    });

    return (
      <div className="text-dsblue-80">
        <div className="text-center text-lg ">Single Roll price: ${price}</div>
        {PrintStep >= 0 ? _map.get(steps[0]).result : null}
        {PrintStep >= 1 ? _map.get(steps[1]).result : null}
        {PrintStep >= 2 ? _map.get(steps[2]).result : null}
        {PrintStep >= 3 ? _map.get(steps[3]).result : null}
        {PrintStep >= 4 ? _map.get(steps[4]).result : null}
        {PrintStep >= 4 ? (
          <div className="grid grid-cols-2 gap-3 pt-8">
            <button
              className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
              onClick={() => {
                //back to dev step
                setItem_order_selected("scan");
              }}
            >
              <p>Back</p>
            </button>
            <button
              onClick={() => {
                //move to print step
                setItem_order_selected("other");
                //console.log("price", price);
                setSingleRollDetail({
                  ...SingleRollDetail,
                  Price: {
                    ...SingleRollDetail.other,
                    totalPrice: price,
                  },
                });
              }}
              className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
            >
              <p>Next Section</p>
            </button>
          </div>
        ) : null}
      </div>
    );
  };
  const Other = () => {
    const NextStep = async () => {
      //set total price
      // var rollPrice = 0;
      // if (SingleRollDetail.info.size !== "") {
      //   rollPrice += SingleRollDetail.info.price;
      // }
      // if (SingleRollDetail.dev_addon.push_pull) {
      //   rollPrice += SingleRollDetail.dev_addon.price;
      // }
      // if (SingleRollDetail.dev_rush.dev_rush) {
      //   rollPrice += SingleRollDetail.dev_rush.price;
      // }
      // if (SingleRollDetail.scan.scan) {
      //   rollPrice += SingleRollDetail.scan.price;
      // }
      // if (SingleRollDetail.scan_addon.tiff) {
      //   rollPrice += SingleRollDetail.scan_addon.price;
      // }
      // if (SingleRollDetail.print.prints) {
      //   rollPrice += SingleRollDetail.print.price;
      // }
      // if (SingleRollDetail.cuts.cuts !== "") {
      //   rollPrice += SingleRollDetail.cuts.price;
      // }
      // //console.log(rollPrice);

      //clear price
      setPrice(0);
      // save singgle roll detail to all order items
      //console.log("single roll detail", SingleRollDetail);
      setAllOrderItems([...allOrderItems, SingleRollDetail]);

      //move to order step
      setItem_order_selected("order");
      setPrintStep(0);
      setScanStep(0);
      setStep(0);
      //reset single roll detail
      await reset_single_roll_detail();
    };

    return (
      <div className="text-dsblue-80">
        <div className="text-center text-lg ">Single Roll price: ${price}</div>
        <div className="flex flex-col gap-3 pt-8">
          {SingleRollDetail.other.destroy === false ? (
            <div>
              <p className="pb-3">
                If you select it, you raw file (The negative) will be DESTROY.
              </p>

              <div className="w-full flex justify-between">
                <div className="flex justify-start items-center">
                  <p>We are going to:</p>
                  <p className="text-green-500 pl-3">Keep your film</p>
                </div>
                <button
                  className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
                  onClick={() => {
                    setSingleRollDetail({
                      ...SingleRollDetail,
                      other: {
                        ...SingleRollDetail.other,
                        destroy: true,
                      },
                    });
                  }}
                >
                  <p>To Destroy negative</p>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p className="pb-3">
                We suggest you to keep your negative for future use.
              </p>
              <div className="w-full flex justify-between">
                <div className="flex justify-start items-center">
                  <p>We are going to:</p>
                  <p className="text-red-500 pl-3">Destroy your film</p>
                </div>
                <button
                  className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
                  onClick={() => {
                    setSingleRollDetail({
                      ...SingleRollDetail,
                      other: {
                        ...SingleRollDetail.other,
                        destroy: false,
                      },
                    });
                  }}
                >
                  <p>To KEEP negative</p>
                </button>
              </div>
            </div>
          )}
          <p className="pt-8">Notes for lab:</p>
          <textarea
            className="border px-4 py-2 rounded-lg h-56"
            onChange={(e) => {
              e.preventDefault();
              setSingleRollDetail({
                ...SingleRollDetail,
                other: {
                  ...SingleRollDetail.other,
                  notes: e.target.value,
                },
              });
            }}
          ></textarea>
        </div>

        <div className="grid grid-cols-2 gap-3 pt-8">
          <button
            className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
            onClick={() => {
              //back to dev step
              setItem_order_selected("print");
            }}
          >
            <p>Back</p>
          </button>
          <button
            onClick={async () => {
              NextStep();
            }}
            className="flex justify-center border px-4 py-2 rounded-lg text-center hover:bg-dsblue-40 hover:text-white active:bg-dsblue-100 active:text-white"
          >
            <p>Next Section</p>
          </button>
        </div>
      </div>
    );
  };
  const Order = () => {
    const orderMaping = () => {
      return allOrderItems.map((item, index) => {
        return (
          <tr>
            <td className="text-left px-3 py-3">
              <div>
                <p>
                  {item.info.chamical === "Black And White"
                    ? "BW"
                    : item.info.chamical}
                </p>
                <p className=" text-xs">{item.info.size} mm</p>
              </div>
            </td>
            {/* <td className="text-left px-3 py-3 hidden md:block">
              <p>{item.info.size} </p>
            </td> */}
            <td
              className={
                item.scan.scan
                  ? "text-center px-3 py-3 text-green-500"
                  : "text-center px-3 py-3 text-red-500"
              }
            >
              <p className="hidden md:block">{item.scan.scan ? "Yes" : "No"}</p>

              <p className="block md:hidden ">
                <div className="flex justify-center  ">
                  {item.scan.scan ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      //   class="w-4 h-4"
                      className="text-green-500 w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      //   class="w-4 h-4"
                      className="text-red-500  w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )}
                </div>
              </p>
            </td>
            <td
              className={
                item.print.prints
                  ? "text-center px-3 py-3 text-green-500"
                  : "text-center px-3 py-3 text-red-500"
              }
            >
              {/* <p>{item.print.prints ? "Yes" : "No"}</p> */}

              <p className="hidden md:block">
                {item.print.prints ? "Yes" : "No"}
              </p>

              <p className="block md:hidden ">
                <div className="flex justify-center ">
                  {item.print.prints ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      //   class="w-4 h-4"
                      className="text-green-500 w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      //   class="w-4 h-4"
                      className="text-red-500  w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )}
                </div>
              </p>
            </td>
            <td className="text-left px-3 py-3 text-red-200">
              <p>${item.Price.totalPrice}</p>
            </td>
            <td className="text-left px-3 py-3 ">
              <div className="flex justify-end items-center">
                <button
                  className="text-right hover:text-red-200 "
                  onClick={() => {
                    //delete item
                    let temp = [...allOrderItems];
                    temp.splice(index, 1);
                    setAllOrderItems(temp);
                  }}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>{" "}
                <button
                  className="text-right hover:text-red-200 "
                  onClick={() => {
                    //duplicate item
                    let temp = [...allOrderItems];
                    temp.push(item);
                    setAllOrderItems(temp);
                  }}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                    />
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        );
      });
    };
    const addNewRoll = () => {
      if (currentShop) {
        setItem_order_selected("develope");
      } else {
        // setOpen(true);
        setMessageFunction("Please select a store !");
      }
    };
    const submitOrder = () => {
      const orderBody = {
        order: allOrderItems,
        customer: globalStore.getState().session.user,
        user_id: globalStore.getState().session.user._id,

        //order date and time
        orderDate: new Date(),
        store: currentShop,
        shop_id: currentShop._id,
        currentStatus: config.orderStatus[0],
        shopNotes: [],
      };
      if (currentShop) {
        // setItem_order_selected("develope");
        if (allOrderItems.length > 0) {
          //console.log("orderBody", orderBody);

          //   dispatch({
          //     // ADD_ONE_ORDER_HISTORY
          //     type: "ADD_ONE_ORDER_HISTORY",
          //     payload: orderBody,
          //   });
          //   order_api
          //     .SubmitOrder(orderBody)
          //     .then((res) => {
          //       //console.log("res", res);
          //     })
          //     .catch((err) => {
          //       //console.log("err", err);
          //     });

          //   navigate("/dashboard");

          order_api.UpdateOrderById(order_id, allOrderItems).then((res) => {
            setMessageFunction("Order updated!");
            setTimeout(() => {
              setOpen(false);
              // reload page
              window.location.reload();
            }, 1000);
          });
          setAllOrderItems([]);
        } else {
          setMessageFunction(
            "Please add at least one roll! or delete this order!"
          );
        }
      } else {
        // setOpen(true);
        setMessageFunction("Please select a store !");
      }
    };
    return (
      <div>
        <div className="flex justify-between items-center">
          <div className="flex gap-3 py-8">
            <button
              className="bg-gray-100 text-dsblue-80 px-3 py-2 rounded-lg hover:text-white hover:bg-dsblue-90"
              onClick={addNewRoll}
            >
              + Add new roll
            </button>
          </div>
          <div className="flex justify-end items-center gap-3">
            <button
              className="px-3 py-2 rounded-xl border border-dsblue-90 hover:bg-dsblue-90 hover:text-white"
              onClick={() => {
                //submit order
                submitOrder();
              }}
            >
              Submit order
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex justify-end items-center gap-3">
            <p className="text-dsblue-80">Total Cost:</p>
            <p className="text-green-500"> ${totalPrice}</p>
          </div>
          <div className="flex justify-end items-center gap-3">
            <p className="text-dsblue-80">Total Rolls:</p>
            <p className="text-green-500"> {allOrderItems.length}</p>
          </div>
        </div>
        {allOrderItems.length > 0 ? (
          <table className="w-full overflow-y-auto">
            <thead>
              <tr className="bg-gray-50 text-dsblue-90  ">
                {/* <th className="text-left px-3 py-3 hidden md:block">
                  Chemical
                </th> */}

                {/* mixed th */}
                <th className="text-left px-3 py-3 block ">Roll</th>
                {/* mixed th */}

                {/* <th className="text-left px-3 py-3 hidden md:block">Size</th> */}
                <th className="text-center px-3 py-3  ">Scan</th>
                <th className="text-center px-3 py-3  ">Print</th>
                <th className="text-left px-3 py-3 ">Price</th>
                <th className="text-right px-3 py-3 ">Edit</th>
              </tr>
            </thead>
            <tbody>{orderMaping()}</tbody>
          </table>
        ) : (
          <div className="flex flex-col justify-center">
            <img src={empty} alt="empty" className="h-1/2 mx-auto" />
            <p className="text-center text-xl text-dsblue-90 font-Gilroy-m">
              No roll added yet
            </p>
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      <button
        onClick={() => {
          //open edit modal
          setOpen(true);
        }}
        className="flex justify-center items-center gap-2 text-sm px-3 py-2 rounded-lg text-white bg-dsblue-90 hover:border-dsblue-100 hover:text-dsblue-100 hover:bg-dsblue-20 border border-dsblue-90"
      >
        <p>Edit</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
          />
        </svg>
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-100 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                  <div className="bg-white px-3 md:px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90">
                    <Transition
                      show={Message !== ""}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div className=" absolute z-20 right-16 top-12 bg-dsblue-100 px-4 py-2 rounded-xl text-white">
                        {Message}
                      </div>
                    </Transition>
                    <div>
                      {Item_order_selected === "order"
                        ? Order()
                        : Item_order_selected === "develope"
                        ? Dev()
                        : Item_order_selected === "scan"
                        ? Scan()
                        : Item_order_selected === "print"
                        ? Print()
                        : Other()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
