import { CircularProgress } from "@mui/material";
import React from "react";
import film from "../../../asset/img/photography.png";

export default function Loading() {
  return (
    <div class="flex mt-16">
      <div class="m-auto ">
        <div className="flex justify-center items-center gap-10">
          <CircularProgress></CircularProgress>
          <img className="w-24 animate-bounce" src={film}></img>
          <CircularProgress></CircularProgress>
        </div>
      </div>
    </div>
  );
}
