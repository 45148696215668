import React, { useEffect, useState } from "react";
import access_denied from "../../asset/img/access_denied.png";
import { useNavigate } from "react-router-dom";
export default function NoAccess(props) {
  const [isLoading, setIsLoading] = useState(true);
  const nav = useNavigate();
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
        nav("/dashboard");
      }, 1000);
    }
  }, []);
  const accessDeny = () => {
    return (
      <div className="h-screen  bg-dsblue-10 flex justify-start items-center">
        <div className="flex flex-col justify-center items-center bg-white shadow mx-auto gap-3 py-16 px-32 rounded-xl ">
          <p className="text-3xl font-Gilroy-m text-red-400">403</p>
          <p className="text-3xl font-Gilroy-m text-red-400"> Access Denied</p>
          <p className="text-xl font-Gilroy-l-itl text-red-400">
            {" "}
            Opps! You don's have access to this page
          </p>
          <img
            src={access_denied}
            alt="access_denied"
            className="w-1/2 mx-auto pt-16"
          ></img>
        </div>
      </div>
    );
  };

  const pageNotFound = () => {
    return (
      <div className="h-screen  bg-dsblue-10 flex justify-start items-center">
        <div className="flex flex-col justify-center items-center bg-white shadow mx-auto gap-3 py-16 px-32 rounded-xl ">
          <p className="text-3xl font-Gilroy-m text-red-400">404</p>
          <p className="text-3xl font-Gilroy-m text-red-400"> Page Not Found</p>
          <p className="text-xl font-Gilroy-l-itl text-red-400">
            {" "}
            Opps! The page you are looking for does not exist.
          </p>
          <img
            src={access_denied}
            alt="access_denied"
            className="w-1/2 mx-auto pt-16"
          ></img>
        </div>
      </div>
    );
  };
  return (
    <div>{props.name === "accessDeny" ? accessDeny() : pageNotFound()}</div>
  );
}
