import React, { useState, useEffect, Fragment, useRef } from "react";
import accessBG from "../../asset/img/accessBG.png";
import accessBG_VERTICAL from "../../asset/img/accessBG_vertical.png";
import LoginPhoto from "../../asset/img/login.jpg";
import { Dialog, Transition } from "@headlessui/react";
import styles from "./StylingForUseraccess";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";
import user_api from "../../api/user_api";
import { useNavigate, useLocation } from "react-router-dom";
import globalStore from "../../store/globalStore";

// import accessBG from "../../asset";
const mapDispatchToProps = (dispatch) => {
  return {
    handleIsLoginFalse: () => dispatch({ type: "SET_IS_lOGIN_FALSE" }),
    handleIsLoginTrue: () => dispatch({ type: "SET_IS_lOGIN_TRUE" }),
  };
};
const mapStateToProps = (state) => {
  return {
    isLogin: state.main.isLogin,
    userDetail: state.session.user,
  };
};
function Login() {
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [inputError, setInputError] = useState({
    email: "",
    password: "",
  });
  // const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loginLoading, setLoginLoading] = useState(0);

  useEffect(() => {
    //console.log("Login page");
    //if user is already logged in then redirect to home page
    if (globalStore.getState().session.authenticated === true) {
      navigate("/dashboard");
    }
  }, []);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "username":
          if (!value) {
            stateObj[name] = "Please enter Username.";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          }
          break;
      }

      return stateObj;
    });
  };

  const handleErrorMessage = (event) => {
    setErrorMessage("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoginLoading(1);

    await user_api
      .login(user.email, user.password)
      .then((response) => {
        setLoginLoading(-1);
        setTimeout(() => {
          setLoginLoading(0);
        }, 2000);
        //console.log("response", response);
        if (response !== null) {
          const insertTime = () => {
            const newResponse = { ...response };
            newResponse["login_time"] = new Date().getTime();
            return newResponse;
          };
          sessionService
            .saveSession(insertTime())
            .then(async () => {
              await sessionService.saveUser(insertTime());
              if (window.location.href.includes("?next=")) {
                const next = window.location.href.split("?next=")[1];
                navigate(next);
              } else {
                navigate("/dashboard");
              }
            })

            .catch((err) => console.error("error?", err));
        } else {
          setErrorMessage("Invalid email or password");
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  var message_style = (
    <div class="pt-2 h-12 flex max-w-sm alert alert-error">
      <div className="justify-start">
        <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
          {errorMessage + " "}
        </p>
      </div>
      <button onClick={handleErrorMessage} className="flex-none pt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="stroke-current flex-shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>
  );

  const modal = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-100 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-sm lg:max-w-3xl w-full">
                <div className="bg-white px-10 pt-5 pb-4 sm:pb-4 text-dsblue-90">
                  <div className="grid sm:grid-cols-1 md:grid-cols-2 w-full h-full gap-3">
                    <div className="flex justify-center items-center">
                      <img src={LoginPhoto}></img>
                    </div>
                    <div className="py-8">
                      <div>
                        <p
                          className={
                            "text-left sm:pb-1 md:pb-4 lg:pb-8 font-Gilroy-b text-dsblue-100 " +
                            styles.title_size_dynamic
                          }
                        >
                          Sign into your account
                          <p className={styles.Atag_size_dynamic}>
                            {errorMessage !== "" && message_style}
                          </p>
                        </p>
                      </div>
                      <form className="flex flex-col" onSubmit={handleSubmit}>
                        <div>
                          <div className="flex flex-col pt-4">
                            <label for="email" className={styles.input_lable}>
                              Email
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              onChange={onInputChange}
                              onBlur={validateInput}
                              className={styles.input_box}
                              placeholder="Enter your email"
                            />
                          </div>
                          <div className={styles.input_error}>
                            {inputError.email && (
                              <span className="">{inputError.email}</span>
                            )}
                          </div>
                        </div>

                        <div>
                          <div className="flex flex-col pt-2">
                            <label
                              htmlFor="password"
                              className={styles.input_lable}
                            >
                              Password
                            </label>
                            <input
                              className={styles.input_box}
                              type="password"
                              name="password"
                              id="password"
                              onChange={onInputChange}
                              onBlur={validateInput}
                              placeholder="Password"
                            />
                          </div>
                          <div className={styles.input_error}>
                            {inputError.password && (
                              <span className="">{inputError.password}</span>
                            )}
                          </div>
                          <p className="text-right">
                            <a
                              href="/forgotpassword"
                              className={
                                "mt-4 text-right text-violet-90 w-auto" +
                                styles.Atag_size_dynamic
                              }
                            >
                              Forgot Password?
                            </a>
                          </p>
                        </div>
                        <div className="flex justify-end py-6">
                          <button
                            className={" flex justify-center items-center"}
                            type="submit"
                            disabled={loginLoading === 1}
                          >
                            <div>
                              {loginLoading === 1 ? (
                                <div
                                  role="status"
                                  className="flex justify-center items-center"
                                >
                                  <svg
                                    aria-hidden="true"
                                    class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-dsblue-100"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                </div>
                              ) : loginLoading === -1 && errorMessage === "" ? (
                                <div className="animate-shake animate-once text-green-500">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M4.5 12.75l6 6 9-13.5"
                                    />
                                  </svg>
                                </div>
                              ) : loginLoading === -1 && errorMessage !== "" ? (
                                <div className="animate-shake animate-once text-red-500">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </div>
                              ) : (
                                <p className={styles.btn_size_dynamic}>Login</p>
                              )}
                            </div>
                          </button>
                        </div>
                      </form>
                      <div className="text-center pt-4 pb-4 text-dsblue-90">
                        <p className={styles.Atag_size_dynamic}>
                          Don't have an account?{" "}
                          <a href="/signup" className="underline font-semibold">
                            Register here.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  return (
    <div className="bg-white  w-full h-screen ">
      <img
        alt="accessBG"
        src={accessBG}
        className="h-screen w-full object-contain hidden md:block"
      ></img>
      <img
        alt="accessBG_VERTICAL"
        src={accessBG_VERTICAL}
        className="h-screen w-full object-contain block md:hidden "
      ></img>
      {modal}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
