import axios from "axios";
const cors = require("cors");
cors({ origin: true });
const apiURL = window.location.origin.includes("3000")
  ? window.location.origin.replace("3000", "3001")
  : "https://photo-dev-app.onrender.com";
// const apiURL =
//   "https://us-east-2.aws.data.mongodb-api.com/app/data-rowzy/endpoint/data/v1/action/";

async function SubmitOrder(OrderObject) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/SubmitOrder/`, {
        order: OrderObject.order,
        customer: OrderObject.customer,
        user_id: OrderObject.user_id,
        shop_id: OrderObject.shop_id,
        orderDate: OrderObject.orderDate,
        store: OrderObject.store,
        currentStatus: OrderObject.currentStatus,
        shopNotes: OrderObject.shopNotes,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function OrderHistoryWithPagination(user_id, sort, skip, limit) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/OrderHistoryWithPagination/`, {
        user_id: user_id,
        sort: sort,
        skip: skip,
        limit: limit,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function OrderHistoryWithPaginationForShop(
  shop_id,
  sort,
  skip,
  limit,
  statuss,
  fromDate,
  toDate,
  search
) {
  return new Promise((resolve, reject) => {
    const filters = {};
    if (statuss.length > 0) {
      filters.statuss = statuss;
    }
    if (fromDate !== "") {
      filters.from = fromDate;
    }
    if (toDate !== "") {
      filters.to = toDate;
    }
    if (search !== "") {
      filters.search = search;
    }

    axios
      .post(`${apiURL}/orderExpress/OrderHistoryWithPaginationForShop/`, {
        shop_id: shop_id,
        sort: sort,
        skip: skip,
        limit: limit,
        filters: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function OrderHistoryById(order_id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/OrderHistoryById/`, {
        order_id: order_id,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function DeleteOrderById(order_id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/DeleteOrderById/`, {
        order_id: order_id,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// UpdateOrderById;
async function UpdateOrderById(order_id, orderArray) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/UpdateOrderById/`, {
        order_id: order_id,
        order: orderArray,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//UpdateOrderStatusById
async function UpdateOrderStatusById(order, currentStatus) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/UpdateOrderStatusById/`, {
        order: order,
        currentStatus: currentStatus,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// /AddNotesById
async function AddNotesById(order, shopNotes) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/AddNotesById/`, {
        order: order,
        shopNotes: shopNotes, //string
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// DeleteNotesById

async function DeleteNotesById(order_id, index) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/DeleteNotesById/`, {
        order_id: order_id,
        index: index,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// CustomerHaveAccessToOrder;
async function CustomerHaveAccessToOrder(order_id, customer_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/CustomerHaveAccessToOrder/`, {
        params: {
          order_id: order_id,
          customer_id: customer_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

// ShopHaveAccessToOrder;
async function ShopHaveAccessToOrder(order_id, shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/ShopHaveAccessToOrder/`, {
        params: {
          order_id: order_id,
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

async function RevenueBarChartData(
  lab_id,
  currentDataType,
  dateRange,
  chemicals
) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/RevenueBarChartData/`, {
        shop_id: lab_id,
        currentDataType: currentDataType,
        dateRange: dateRange,
        chemicals: chemicals,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

// YTDRevenue;
async function YTDRevenue(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/YTDRevenue/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

// YTDNumberOfOrders;
async function YTDNumberOfOrders(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/YTDNumberOfOrders/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

// YTDNumberOfRolls;
async function YTDNumberOfRolls(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/YTDNumberOfRolls/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

//YTDRevenueOfDevelop
async function YTDRevenueOfDevelop(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/YTDRevenueOfDevelop/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });

    // .catch((err) => {
    //   reject(err);
    // });
  });
}

// YTDRevenueOfScan;
async function YTDRevenueOfScan(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/YTDRevenueOfScan/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });

    // .catch((err) => {
    //   reject(err);
    // });
  });
}

//YTDRevenueOfPrint
async function YTDRevenueOfPrint(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/YTDRevenueOfPrint/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });

    // .catch((err) => {
    //   reject(err);
    // });
  });
}

//MTDRevenue
async function MTDRevenue(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/MTDRevenue/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

// MTDNumberOfOrders;
async function MTDNumberOfOrders(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/MTDNumberOfOrders/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

// MTDNumberOfRolls;
async function MTDNumberOfRolls(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/MTDNumberOfRolls/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

//MTDRevenueOfDevelop
async function MTDRevenueOfDevelop(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/MTDRevenueOfDevelop/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });

    // .catch((err) => {
    //   reject(err);
    // });
  });
}

// MTDRevenueOfScan;
async function MTDRevenueOfScan(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/MTDRevenueOfScan/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });

    // .catch((err) => {
    //   reject(err);
    // });
  });
}

//MTDRevenueOfPrint
async function MTDRevenueOfPrint(shop_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/orderExpress/MTDRevenueOfPrint/`, {
        params: {
          shop_id: shop_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });

    // .catch((err) => {
    //   reject(err);
    // });
  });
}

// NumberOfRollsCategoryByChemical;
async function NumberOfRollsCategoryByChemical(shop_id, dateRange) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/orderExpress/NumberOfRollsCategoryByChemical/`, {
        shop_id: shop_id,
        dateRange: dateRange,
      })
      .then((res) => {
        console.log(res.data.data);
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
    // .catch((err) => {
    //   reject(err);
    // });
  });
}

export default {
  SubmitOrder: SubmitOrder,
  OrderHistoryWithPagination: OrderHistoryWithPagination,
  OrderHistoryWithPaginationForShop: OrderHistoryWithPaginationForShop,
  OrderHistoryById: OrderHistoryById,
  DeleteOrderById: DeleteOrderById,
  UpdateOrderById: UpdateOrderById,
  UpdateOrderStatusById: UpdateOrderStatusById,
  AddNotesById: AddNotesById,
  DeleteNotesById: DeleteNotesById,
  CustomerHaveAccessToOrder: CustomerHaveAccessToOrder,
  ShopHaveAccessToOrder: ShopHaveAccessToOrder,
  RevenueBarChartData: RevenueBarChartData,
  YTDRevenue: YTDRevenue,
  YTDNumberOfOrders: YTDNumberOfOrders,
  YTDNumberOfRolls: YTDNumberOfRolls,
  YTDRevenueOfDevelop: YTDRevenueOfDevelop,
  YTDRevenueOfScan: YTDRevenueOfScan,
  YTDRevenueOfPrint: YTDRevenueOfPrint,
  MTDRevenue: MTDRevenue,
  MTDNumberOfOrders: MTDNumberOfOrders,
  MTDNumberOfRolls: MTDNumberOfRolls,
  MTDRevenueOfDevelop: MTDRevenueOfDevelop,
  MTDRevenueOfScan: MTDRevenueOfScan,
  MTDRevenueOfPrint: MTDRevenueOfPrint,
  NumberOfRollsCategoryByChemical: NumberOfRollsCategoryByChemical,
};
