import React, { useEffect, useState, Fragment, useRef } from "react";
import order_api from "../../../api/order_api";
import globalStore from "../../../store/globalStore";
import { useNavigate } from "react-router-dom";
import CustomerLayout from "../../../component/costumer/customerLayout";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import config from "../../../config";
import { Dialog } from "@headlessui/react";
// import { Pagination } from "@nextui-org/react";

import { Menu, Transition, Disclosure } from "@headlessui/react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import EmptyBox from "../../../asset/img/empty-box.png";

import ReactToPrint from "react-to-print";
import Loading from "../../../component/general/loading/loading";
import QrReader from "react-qr-reader";


export default function ShopOrderHistoryPage() {
  const [orderHistory, setOrderHistory] = useState([]);
  const [sort, setSort] = useState("descending");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [dateFilter, setDateFilter] = useState({
    from: "",
    to: "",
  });
  const [statusFilter, setStatusFilter] = useState([]);
  const [labFilter, setLabFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");
  const [startScan, setStartScan] = useState(false);
  const [onCameraScan1, setOnCameraScan1] = useState(false);
  const [onCameraScan2, setOnCameraScan2] = useState(false);
  const cancelButtonRef = useRef(null);
  const [openScan, setOpenScan] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [order_id, setOrder_id] = useState("");
  const [InvalidOrder, setInvalidOrder] = useState(false);

  useEffect(() => {
    //refresh order history

    if (isLoading) {
      order_api
        .OrderHistoryWithPaginationForShop(
          // globalStore.getState().user._id,
          globalStore.getState().session.user.lab_id,
          sort,
          skip,
          limit,
          statusFilter,
          dateFilter.from,
          dateFilter.to,
          // todayFrom,
          // todayTo,
          userFilter
        )
        .then((res) => {
          //console.log("res", res);
          setOrderHistory(res);
          setIsLoading(false);
          // globalStore.dispatch({
          //   type: "SET_ORDER_HISTORY",
          //   payload: res,
          // });
        });
    }
  }, []);
  const handleScan = async (scanData) => {
    setLoadingScan(true);
    //console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      //console.log(`loaded >>>`, scanData);
      // setCurrentScanInput(scanData);
      // userFilter(scanData);
      setUserFilter(scanData);
      setOpenScan(false);
      setLoadingScan(false);
      if (onCameraScan1) {
        setOnCameraScan1(false);
      } else if (onCameraScan2) {
        setOnCameraScan2(false);
      }
      // setPrecScan(scanData);
    }
  };
  const handleScan2 = async (scanData) => {
    setLoadingScan(true);
    //console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      //console.log(`loaded >>>`, scanData);
      var id = scanData.split("/")[4];
      if (id.includes("#")) {
        id = id.split("#")[0];
      }
      order_api
        .ShopHaveAccessToOrder(id, globalStore.getState().session.user.lab_id)
        .then((res) => {
          //console.log("res", res);
          if (res.status) {
            navigate(`/orderHistory/${id}`);
          } else {
            setInvalidOrder(true);
            setTimeout(() => {
              setInvalidOrder(false);
            }, 3000);
          }
        });
      setOpenScan(false);
      setLoadingScan(false);
      if (onCameraScan1) {
        setOnCameraScan1(false);
      } else if (onCameraScan2) {
        setOnCameraScan2(false);
      }
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleEntries = (e, limit) => {
    e.preventDefault();
    setLimit(limit);
    setSkip(0);
    order_api

      .OrderHistoryWithPaginationForShop(
        // globalStore.getState().user._id,

        globalStore.getState().session.user.lab_id,
        sort,
        0,
        limit,
        statusFilter,
        dateFilter.from,
        dateFilter.to,
        userFilter
      )
      .then((res) => {
        //console.log("res", res);
        setOrderHistory(res);
      });
  };

  const ShowEntries = () => {
    return (
      <Menu as="div" className="relative  text-left w-full">
        <div className=" w-full">
          <div className=" ">
            <div className=" flex justify-center">
              <Menu.Button className="">
                <div className="border border-dsblue-100 rounded-lg">
                  <p className="text-dsblue-90 px-2 text-xs py-1 text-center rounded-lg ">
                    {limit}
                  </p>
                </div>
              </Menu.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left absolute z-20 mt-2 w-full rounded-md shadow-lg bg-white  ring-opacity-5 focus:outline-none overflow-y-auto justify-center">
            <div className="py-1 text-sm">
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={
                      "text-center cursor-pointer m-1 rounded-md " +
                      `${active && "bg-dsblue-80 text-white text-center"}`
                    }
                    onClick={(e) => {
                      handleEntries(e, 5);
                    }}
                  >
                    5
                  </p>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={
                      "text-center cursor-pointer m-1 rounded-md " +
                      `${active && "bg-dsblue-80 text-white text-center"}`
                    }
                    onClick={(e) => {
                      handleEntries(e, 10);
                    }}
                  >
                    10
                  </p>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={
                      "text-center cursor-pointer m-1 rounded-md " +
                      `${active && "bg-dsblue-80 text-white text-center"}`
                    }
                    onClick={(e) => {
                      handleEntries(e, 20);
                    }}
                  >
                    20
                  </p>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={
                      "text-center cursor-pointer m-1 rounded-md " +
                      `${active && "bg-dsblue-80 text-white text-center"}`
                    }
                    onClick={(e) => {
                      handleEntries(e, 50);
                    }}
                  >
                    50
                  </p>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const countOrderTotalPrice = (order) => {
    let total = 0;
    order.forEach((item) => {
      total += item.Price.totalPrice;
    });
    return total;
  };
  const getDate = (date) => {
    // 2023-08-07T16:23:08.710Z
    // need 08-07
    let month = date.slice(5, 7);
    let day = date.slice(8, 10);
    return month + "-" + day;
  };

  const handleDropDownSelection = (order, status) => {
    //update order status
    //console.log("order", order);

    order_api.UpdateOrderStatusById(order._id, status).then(async (res) => {
      //console.log("res", res);
      //refresh order history
      await order_api
        .OrderHistoryWithPaginationForShop(
          // globalStore.getState().user._id,
          globalStore.getState().session.user.lab_id,
          sort,
          skip,
          limit,
          statusFilter,
          dateFilter.from,
          dateFilter.to,
          userFilter
        )
        .then((res) => {
          //console.log("res", res);
          setOrderHistory(res);
        });
    });
  };

  const dropdown = (order) => {
    return config.orderStatus.map((sta, index) => {
      return (
        <Menu.Item>
          {({ active }) => (
            <a
              className={classNames(
                active ? " text-violet-100" : "text-dsblue-100",
                "block px-4 py-2 text-sm"
              )}
              onClick={(e) => handleDropDownSelection(order, sta)}
            >
              {sta}
            </a>
          )}
        </Menu.Item>
      );
    });
  };

  const changeStatusView = (order) => {
    const statusBar = (status) => {
      var style = "";
      if (config.orderStatus[0] === status) {
        style =
          "border border-green-500 rounded-lg px-1 text-center text-green-500";
      } else if (config.orderStatus[1] === status) {
        style =
          "border border-yellow-800 rounded-lg px-1 text-center text-yellow-800 ";
      } else if (config.orderStatus[2] === status) {
        style =
          "border border-blue-500 rounded-lg px-1 text-center text-blue-500 ";
      } else if (config.orderStatus[3] === status) {
        style =
          "border border-purple-500 rounded-lg px-1 text-center text-purple-500  ";
      } else if (config.orderStatus[4] === status) {
        style =
          "border border-red-500 rounded-lg px-1 text-center text-red-500  ";
      } else {
        style =
          "border border-green-500 rounded-lg px-1 text-center text-green-500 ";
      }
      return (
        <div className={style}>
          <p className=" text-left ">{status}</p>
        </div>
      );
    };
    return (
      <Menu as="div" className="relative  text-left w-full mt-2">
        <div className=" w-full">
          <div className=" ">
            <div className=" flex justify-start">
              <Menu.Button className="">
                <div className="">
                  {/* <p className="border border-green-500 rounded-lg px-1 text-center text-green-500">
                    {order.currentStatus}
                  </p> */}

                  <div className="">{statusBar(order.currentStatus)}</div>
                </div>
              </Menu.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left absolute z-20 left-0 mt-2 w-full rounded-md shadow-lg bg-white  ring-opacity-5 focus:outline-none overflow-y-auto">
            <div className="py-1 ">{dropdown(order)}</div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const handleClearFilter = (filterToClear) => {
    var paramsForOrderHistory = {
      sort: sort,
      skip: skip,
      limit: limit,
      statuss: statusFilter,
      fromDate: dateFilter.from,
      toDate: dateFilter.to,
      search: userFilter,
    };
    //reset skip and limit
    setSkip(0);
    setLimit(10);
    if (filterToClear === "date") {
      setDateFilter({
        from: "",
        to: "",
      });
      paramsForOrderHistory.fromDate = "";
      paramsForOrderHistory.toDate = "";
    } else if (filterToClear === "status") {
      setStatusFilter([]);
      paramsForOrderHistory.statuss = [];
    } else if (filterToClear === "lab") {
      setLabFilter("");
    } else if (filterToClear === "user") {
      setUserFilter("");
      paramsForOrderHistory.search = "";
    }
    order_api
      .OrderHistoryWithPaginationForShop(
        // globalStore.getState().user._id,
        globalStore.getState().session.user.lab_id,
        sort,
        skip,
        limit,
        paramsForOrderHistory.statuss,
        paramsForOrderHistory.fromDate,
        paramsForOrderHistory.toDate,
        paramsForOrderHistory.search
      )
      .then((res) => {
        //console.log("res", res);
        setOrderHistory(res);
      });
  };

  const handleApplyFilter = () => {
    order_api
      .OrderHistoryWithPaginationForShop(
        // globalStore.getState().user._id,
        globalStore.getState().session.user.lab_id,
        sort,
        skip,
        limit,
        statusFilter,
        dateFilter.from,
        dateFilter.to,
        userFilter
      )
      .then((res) => {
        //console.log("res", res);
        setOrderHistory(res);
      });
  };
  const onCameraScan = () => {
    return (
      <div>
        {onCameraScan1 ? (
          <div>
            <Transition.Root show={openScan} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-30"
                initialFocus={cancelButtonRef}
                onClose={setOpenScan}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-100 inset-0 overflow-y-auto">
                  <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                        <div className="bg-white px-3 md:px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90 flex justify-center">
                          <QrReader
                            facingMode={"environment"}
                            // delay={1000}
                            onError={handleError}
                            onScan={handleScan}
                            // chooseDeviceId={()=>selected}
                            style={{ width: "300px" }}
                          />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        ) : onCameraScan2 ? (
          <div>
            <Transition.Root show={openScan} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-30"
                initialFocus={cancelButtonRef}
                onClose={setOpenScan}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-100 inset-0 overflow-y-auto">
                  <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0 ">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl w-full">
                        <div className="bg-white px-3 md:px-10 pt-6 pb-4 sm:pb-4 text-dsblue-90 flex justify-center">
                          <QrReader
                            facingMode={"environment"}
                            // delay={1000}
                            onError={handleError}
                            onScan={handleScan2}
                            // chooseDeviceId={()=>selected}
                            style={{ width: "300px" }}
                          />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="bg-white">
      <CustomerLayout describtion="order">
        {isLoading ? (
          <div>
            <Loading></Loading>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-lg col-span-2  p-4 md:p-8">
            <div className=" divide-y-small  ">
              <div className=" ">
                {/* filter section */}
                {/* date filter */}
                <div className=" px-4 py-2 mb-4 text-sm font-medium text-left text-dsblue-90  rounded-lg bg-gray-100 ">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                          <span>Date filter</span>
                          <ChevronRightIcon
                            className={`${
                              open ? "transform rotate-90" : ""
                            } w-5 h-5 text-dsblue-90`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4  pb-4 text-sm text-gray-500 ">
                          <div className="flex justify-between items-center gap-3 pt-4">
                            <p className="text-dsblue-90">Order Date</p>
                            <div className="flex justify-end gap-3">
                              <button
                                onClick={() => {
                                  handleClearFilter("date");
                                }}
                                className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white border border-dsblue-80"
                              >
                                Clear
                              </button>

                              <button
                                onClick={() => {
                                  handleApplyFilter();
                                }}
                                className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white  border border-dsblue-80"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                          <div className="flex justify-between items-center gap-3 py-2">
                            <div className="flex justify-between items-center gap-3">
                              <p className="text-dsblue-90">From</p>
                              <input
                                value={dateFilter.from}
                                onChange={(e) => {
                                  setDateFilter({
                                    ...dateFilter,
                                    from: e.target.value,
                                  });
                                }}
                                type="date"
                                className="border border-dsblue-90 rounded-lg px-2 py-1"
                              ></input>
                            </div>
                            <div className="flex justify-between items-center gap-3">
                              <p className="text-dsblue-90">To</p>
                              <input
                                value={dateFilter.to}
                                onChange={(e) => {
                                  setDateFilter({
                                    ...dateFilter,
                                    to: e.target.value,
                                  });
                                }}
                                //prevent user from choosing a date before the from date
                                min={dateFilter.from}
                                type="date"
                                className="border border-dsblue-90 rounded-lg px-2 py-1"
                              ></input>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
                {/* status filter */}
                <div>
                  <div className=" px-4 py-2 mb-4 text-sm font-medium text-left text-dsblue-90  rounded-lg bg-gray-100 ">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                            <span>Status filter</span>
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } w-5 h-5 text-dsblue-90`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4  pb-4 text-sm text-gray-500 ">
                            {/* filter by order's status(map option in dropdown) */}
                            <div className="flex justify-between items-center gap-3 pt-4">
                              <p className="text-dsblue-90">Order Status</p>
                              <div className="flex justify-end gap-3">
                                <button
                                  onClick={() => {
                                    handleClearFilter("status");
                                  }}
                                  className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white  border border-dsblue-80"
                                >
                                  Clear
                                </button>

                                <button
                                  onClick={() => {
                                    handleApplyFilter();
                                  }}
                                  className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white  border border-dsblue-80"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                            <div className="flex justify-between items-center gap-3 py-2">
                              {config.orderStatus.map((status) => {
                                return (
                                  <div className="flex justify-between items-center gap-3">
                                    <p className="text-dsblue-90">{status}</p>
                                    <input
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setStatusFilter([
                                            ...statusFilter,
                                            status,
                                          ]);
                                        } else {
                                          setStatusFilter(
                                            statusFilter.filter(
                                              (item) => item !== status
                                            )
                                          );
                                        }
                                      }}
                                      type="checkbox"
                                      className="border border-dsblue-90 rounded-lg px-2 py-1"
                                    ></input>
                                  </div>
                                );
                              })}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
                {/* lab filter */}
                <div></div>
                {/* user filter */}
                <div>
                  <div className=" px-4 py-2 mb-4 text-sm font-medium text-left text-dsblue-90  rounded-lg bg-gray-100 ">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                            <span>User filter</span>
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } w-5 h-5 text-dsblue-90`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500 ">
                            {/* filter by order's status(map option in dropdown) */}

                            <div className="flex justify-between items-center gap-3 py-2">
                              <div className="flex justify-between items-center gap-3 w-full">
                                <div className="flex justify-start items-center gap-3">
                                  <p className="text-dsblue-90">User</p>
                                  <input
                                    onChange={(e) => {
                                      setUserFilter(e.target.value);
                                    }}
                                    value={userFilter}
                                    type="text"
                                    className="border border-dsblue-90 rounded-lg px-2 py-1"
                                  ></input>
                                </div>
                                <div className="flex justify-start items-center gap-4">
                                  <button
                                    className="flex justify-start items-center gap-3 py-2 px-3 rounded-xl border border-dsblue-100 hover:bg-dsblue-100 hover:text-white"
                                    onClick={() => {
                                      setOpenScan(!openScan);
                                      setOnCameraScan1(!onCameraScan1);
                                      setOnCameraScan2(false);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                                      />
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                                      />
                                    </svg>
                                    <p>
                                      {onCameraScan1
                                        ? "Stop camera scan"
                                        : "Use camera scan"}
                                    </p>
                                  </button>
                                </div>
                                {onCameraScan()}

                                <div className="flex justify-end gap-3">
                                  <button
                                    onClick={() => {
                                      handleClearFilter("user");
                                    }}
                                    className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white  border border-dsblue-80"
                                  >
                                    Clear
                                  </button>

                                  <button
                                    onClick={() => {
                                      handleApplyFilter();
                                    }}
                                    className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white  border border-dsblue-80"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
                {/* searchby order */}
                <div>
                  <div className=" px-4 py-2 mb-4 text-sm font-medium text-left text-dsblue-90  rounded-lg bg-gray-100 ">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-dsblue-90 bg-white rounded-lg ">
                            <span>Search by Order</span>
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } w-5 h-5 text-dsblue-90`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500 ">
                            {/* filter by order's status(map option in dropdown) */}
                            {InvalidOrder ? (
                              <div className="flex justify-center items-center gap-3 py-2">
                                <p className="text-red-500">Invalid order </p>
                              </div>
                            ) : null}
                            <div className="flex justify-between items-center gap-3 py-2">
                              <div className="flex justify-between items-center gap-3 w-full">
                                <div className="flex justify-start items-center gap-3">
                                  <p className="text-dsblue-90">
                                    Enter or scan order QRCode
                                  </p>
                                  <input
                                    onChange={(e) => {
                                      setOrder_id(e.target.value);
                                    }}
                                    value={order_id}
                                    type="text"
                                    className="border border-dsblue-90 rounded-lg px-2 py-1"
                                  ></input>
                                </div>
                                <div className="flex justify-start items-center gap-4">
                                  <button
                                    className="flex justify-start items-center gap-3 py-2 px-3 rounded-xl border border-dsblue-100 hover:bg-dsblue-100 hover:text-white"
                                    onClick={() => {
                                      setOpenScan(!openScan);
                                      setOnCameraScan2(!onCameraScan2);
                                      setOnCameraScan1(false);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                                      />
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                                      />
                                    </svg>
                                    <p>
                                      {onCameraScan2
                                        ? "Stop camera scan"
                                        : "Use camera scan"}
                                    </p>
                                  </button>
                                </div>
                                {onCameraScan()}

                                <div className="flex justify-end gap-3">
                                  <button
                                    onClick={() => {
                                      setOrder_id("");
                                    }}
                                    className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white  border border-dsblue-80"
                                  >
                                    Clear
                                  </button>

                                  <button
                                    onClick={() => {
                                      order_api
                                        .ShopHaveAccessToOrder(
                                          order_id,
                                          globalStore.getState().session.user
                                            .lab_id
                                        )
                                        .then((res) => {
                                          //console.log("res", res);
                                          if (res.status) {
                                            navigate(
                                              `/orderHistory/${order_id}`
                                            );
                                          } else {
                                            setInvalidOrder(true);
                                            setTimeout(() => {
                                              setInvalidOrder(false);
                                            }, 3000);
                                          }
                                        });
                                    }}
                                    className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white  border border-dsblue-80"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center pb-3 pt-8">
                <p className=" text-dsblue-90">Order History</p>

                <div className="flex justify-end">
                  <div className=" flex justify-center items-center gap-3">
                    <p>Show</p>
                    {ShowEntries()}
                    <p>entries</p>
                  </div>
                </div>

                <Stack spacing={2}>
                  <Pagination
                    count={
                      orderHistory.total % limit === 0
                        ? orderHistory.total / limit
                        : Math.floor(orderHistory.total / limit) + 1
                    }
                    // variant="outlined"
                    // shape="rounded"
                    onChange={(e, page) => {
                      //console.log("page", page);
                      order_api
                        .OrderHistoryWithPaginationForShop(
                          // globalStore.getState().user._id,
                          globalStore.getState().session.user.lab_id,
                          sort,
                          (page - 1) * limit,
                          limit,
                          statusFilter,
                          dateFilter.from,
                          dateFilter.to,
                          userFilter
                        )
                        .then((res) => {
                          //console.log("res", res);
                          setOrderHistory(res);
                          setSkip((page - 1) * limit);
                        });
                    }}
                  />
                </Stack>
              </div>
            </div>

            <table className="table-auto w-full overflow-y-auto">
              <thead className="bg-gray-50 rounded-lg pb-2 uppercase ">
                <tr className="">
                  <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-left">
                    Date
                  </th>
                  <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-left ">
                    Status
                  </th>
                  <th className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm md:text-lg text-center">
                    <p className="">Rolls </p>
                  </th>
                  <th className="text-dsblue-90 px-2 md:px-4 text-xs sm:text-sm md:text-lg text-center">
                    <p className="">Price</p>
                  </th>
                  {/* <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-center"></th> */}
                  <th className="text-dsblue-90 md:px-4 text-xs sm:text-sm md:text-lg text-center ">
                    <p className="hidden md:block">Lab</p>
                  </th>

                  <th className="text-dsblue-90 px-1 md:px-4 text-xs sm:text-sm md:text-lg text-center"></th>
                </tr>
              </thead>
              <tbody>
                {orderHistory.data?.map((order) => (
                  <tr className=" border-b ">
                    <td className="text-dsblue-60 px-1 md:px-4 text-xs sm:text-sm  py-2">
                      <p>{order.orderDate.split("-")[0]}</p>
                      <p>{getDate(order.orderDate)}</p>
                    </td>
                    <td className="text-dsblue-60 px-1 md:px-4 text-xs sm:text-sm  py-2 ">
                      {changeStatusView(order)}
                    </td>
                    {/* <td className="text-dsblue-60 md:px-4 text-xs sm:text-sm  py-2 text-center">
                <p className=" text-center ">{order.order.length}</p>
              </td> */}
                    <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                      <p className=" text-center">{order.order.length}</p>
                    </td>{" "}
                    <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                      <p className=" text-center">
                        {"$" + countOrderTotalPrice(order.order)}
                      </p>
                    </td>{" "}
                    <td className="text-dsblue-60 md:px-4 text-xs sm:text-sm  py-2 text-center h-full">
                      <p className=" text-center hidden md:block">
                        {order.store.shopName}
                      </p>
                    </td>
                    <td className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center">
                      <button
                        className="text-dsblue-60 px-2 md:px-4 text-xs sm:text-sm  py-2 text-center rounded-lg hover:bg-dsblue-100 hover:text-white"
                        onClick={() => {
                          navigate("/orderHistory/" + order._id);
                        }}
                      >
                        <p className=" text-center">View</p>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className=" pt-16 pb-8">
              {orderHistory.data?.length === 0 && (
                <div className="flex flex-col justify-center items-center gap-3">
                  <img className="w-10 h-10" src={EmptyBox}></img>
                  <p className="text-dsblue-90">No order history for today</p>
                </div>
              )}
            </div>
            {/* <div className="flex justify-end pt-6">
          </div> */}
          </div>
        )}
      </CustomerLayout>
    </div>
  );
}
